import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createEntityValidator, generateCodeForSlice, SLICE_CODE_FOR } from '../helpers';
import { notEmptyArray, nullValidator, textLengthValidator } from '../../validators/common';
import api from 'components/api';
import { CustomError } from 'views/helpers';

const emailNotificationValidation = createEntityValidator({
  header: [nullValidator(), textLengthValidator({ maxLength: 255 })],
  welcomeMessage: [nullValidator()],
  mainText: [nullValidator()],
  recipientIds: [notEmptyArray()],
  notificationType: [nullValidator()]
});

export const {
  rootName,
  generatedInitialState,
  generatedExtraReducers,
  emailNotificationsSelector,
  loadEmailNotifications,
  createEmailNotification,
  editEmailNotification,
  deleteEmailNotification
} = generateCodeForSlice(
  {
    entityName: 'EmailNotification',
    entityValidation: emailNotificationValidation
  },
  [SLICE_CODE_FOR.LOAD, SLICE_CODE_FOR.CREATE, SLICE_CODE_FOR.EDIT, SLICE_CODE_FOR.DELETE]
);

const emailSendDataValidation = createEntityValidator({
  subject: [nullValidator()],
  fromName: [nullValidator()],
  fromEmail: [nullValidator()]
});
export const sendEmailNotification = createAsyncThunk(
  `${rootName}/send`,
  async ({ emailSendData, orgId }, { rejectWithValue }) => {
    try {
      await emailSendDataValidation(emailSendData);

      const response = await api.post(`/org/${orgId}/send_email_notification/${emailSendData.id}`, {
        subject: emailSendData.subject,
        fromName: emailSendData.fromName,
        fromEmail: emailSendData.fromEmail
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(new CustomError(error));
    }
  }
);

export const emailNotificationsSlice = createSlice({
  name: rootName,
  initialState: { ...generatedInitialState, emailSendError: null },
  reducers: {
    reloadMarketplaceEmailNotificationError: state => {
      state.createError = null;
      state.editError = null;
    }
  },
  extraReducers: {
    ...generatedExtraReducers,
    [sendEmailNotification.pending]: state => {
      state.emailSendError = null;
    },
    [sendEmailNotification.fulfilled]: (state, { payload }) => {
      if (!state.items) return;

      const itemIndex = state.items.findIndex(val => val.id === payload.id);
      if (itemIndex === -1) return;
      state.items.splice(itemIndex, 1, payload);
    },
    [sendEmailNotification.rejected]: (state, { payload }) => {
      state.emailSendError = payload.reduxPayload;
    }
  }
});

export const { reloadMarketplaceEmailNotificationError } = emailNotificationsSlice.actions;

export default emailNotificationsSlice.reducer;
