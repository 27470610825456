import React from 'react';
import styled from 'styled-components';

const TBodyWrapper = styled.tbody`
  ${props =>
    !props.withoutHeaderBorder
      ? `
  > tr:first-child {
    > th,
    > td {
      border-top: 2px solid #cecece;
    }
  }`
      : ''}
`;

export default ({ children, ...rest }) => <TBodyWrapper {...rest}>{children}</TBodyWrapper>;
