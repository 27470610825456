import { applyDealFulfilled, unapplyDealFulfilled } from './helpers';
import { deleteItemFulfilled, editItemFulfilled, generateSlice, SLICE_CODE_FOR } from '../helpers';
import { deleteMarketplaceDeal, editMarketplaceDeal } from './userDealsSlice';
import { applyDeal, unapplyDeal } from './common';
import { publishDeal } from './unapprovedDealsSlice';

export const { publishedDealsSlice, loadPublishedDeals } = generateSlice(
  { entityName: 'PublishedDeal', baseUrl: '/marketplace/deals' },
  [SLICE_CODE_FOR.LOAD]
)({
  extraReducers: {
    // EDIT
    [editMarketplaceDeal.fulfilled]: editItemFulfilled,
    // DELETE DEAL
    [deleteMarketplaceDeal.fulfilled]: deleteItemFulfilled,
    // PUBLISH DEAL
    [publishDeal.fulfilled]: (state, { payload }) => {
      if (!state.items) {
        state.items = [payload];
      }
      state.items.push(payload);
    },
    // APPLY DEAL
    [applyDeal.fulfilled]: applyDealFulfilled,
    // UNAPPLY DEAL
    [unapplyDeal.fulfilled]: unapplyDealFulfilled
  }
});

export default publishedDealsSlice.reducer;
