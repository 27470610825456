export const companyFinancialsConstants = {
  COMPANY_FINANCIALS_CREATE_LOADING: 'COMPANY_FINANCIALS_CREATE_LOADING',
  COMPANY_FINANCIALS_CREATE_SUCCESS: 'COMPANY_FINANCIALS_CREATE_SUCCESS',
  COMPANY_FINANCIALS_CREATE_FAILURE: 'COMPANY_FINANCIALS_CREATE_FAILURE',
  COMPANY_FINANCIALS_ERROR_RELOAD: 'COMPANY_FINANCIALS_ERROR_RELOAD',

  COMPANY_FINANCIALS_LIST_LOADING: 'COMPANY_FINANCIALS_LIST_LOADING',
  COMPANY_FINANCIALS_LIST_SUCCESS: 'COMPANY_FINANCIALS_LIST_SUCCESS',
  COMPANY_FINANCIALS_LIST_FAILURE: 'COMPANY_FINANCIALS_LIST_FAILURE',

  COMPANY_FINANCIALS_EDIT_LOADING: 'COMPANY_FINANCIALS_EDIT_LOADING',
  COMPANY_FINANCIALS_EDIT_SUCCESS: 'COMPANY_FINANCIALS_EDIT_SUCCESS',
  COMPANY_FINANCIALS_EDIT_FAILURE: 'COMPANY_FINANCIALS_EDIT_FAILURE',

  COMPANY_FINANCIALS_DELETE_LOADING: 'COMPANY_FINANCIALS_DELETE_LOADING',
  COMPANY_FINANCIALS_DELETE_SUCCESS: 'COMPANY_FINANCIALS_DELETE_SUCCESS',
  COMPANY_FINANCIALS_DELETE_FAILURE: 'COMPANY_FINANCIALS_DELETE_FAILURE'
};
