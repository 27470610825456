export const uboConstants = {
  UBO_CREATE_LOADING: 'UBO_CREATE_LOADING',
  UBO_CREATE_SUCCESS: 'UBO_CREATE_SUCCESS',
  UBO_CREATE_FAILURE: 'UBO_CREATE_FAILURE',
  UBO_ERROR_RELOAD: 'UBO_ERROR_RELOAD',

  UBO_LIST_LOADING: 'UBO_LIST_LOADING',
  UBO_LIST_SUCCESS: 'UBO_LIST_SUCCESS',
  UBO_LIST_FAILURE: 'UBO_LIST_FAILURE',

  UBO_EDIT_LOADING: 'UBO_EDIT_LOADING',
  UBO_EDIT_SUCCESS: 'UBO_EDIT_SUCCESS',
  UBO_EDIT_FAILURE: 'UBO_EDIT_FAILURE',

  UBO_DELETE_LOADING: 'UBO_DELETE_LOADING',
  UBO_DELETE_SUCCESS: 'UBO_DELETE_SUCCESS',
  UBO_DELETE_FAILURE: 'UBO_DELETE_FAILURE',

  UBO_CREATE_FORM_PASSPORT_ADD: 'UBO_CREATE_FORM_PASSPORT_ADD',
  UBO_CREATE_FORM_PASSPORT_EDIT: 'UBO_CREATE_FORM_PASSPORT_EDIT',
  UBO_CREATE_FORM_PASSPORT_DELETE: 'UBO_CREATE_FORM_PASSPORT_DELETE',

  UBO_CREATE_FORM_UTILITY_BILL_ADD: 'UBO_CREATE_FORM_UTILITY_BILL_ADD',
  UBO_CREATE_FORM_UTILITY_BILL_EDIT: 'UBO_CREATE_FORM_UTILITY_BILL_EDIT',
  UBO_CREATE_FORM_UTILITY_BILL_DELETE: 'UBO_CREATE_FORM_UTILITY_BILL_DELETE',

  UBO_EDIT_FORM_PASSPORT_ADD: 'UBO_EDIT_FORM_PASSPORT_ADD',
  UBO_EDIT_FORM_PASSPORT_EDIT: 'UBO_EDIT_FORM_PASSPORT_EDIT',
  UBO_EDIT_FORM_PASSPORT_DELETE: 'UBO_EDIT_FORM_PASSPORT_DELETE',
  UBO_EDIT_FORM_RESET_PASSPORTS: 'UBO_EDIT_FORM_RESET_PASSPORTS',

  UBO_EDIT_FORM_UTILITY_BILL_ADD: 'UBO_EDIT_FORM_UTILITY_BILL_ADD',
  UBO_EDIT_FORM_UTILITY_BILL_EDIT: 'UBO_EDIT_FORM_UTILITY_BILL_EDIT',
  UBO_EDIT_FORM_UTILITY_BILL_DELETE: 'UBO_EDIT_FORM_UTILITY_BILL_DELETE',
  UBO_EDIT_FORM_RESET_UTILITY_BILLS: 'UBO_EDIT_FORM_RESET_UTILITY_BILLS',

  RESET_UBO_MAIN_RECIPIENT: 'RESET_UBO_MAIN_RECIPIENT'
};
