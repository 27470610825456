import { List } from 'immutable';
import {
  passportConstants,
  sourceOfWealthProofConstants,
  uboConstants,
  utilityBillConstants
} from '../constants';
import { history } from '../history';
import * as uboService from 'services/ubo.service';
import * as investorOnboardingItemActions from './investor_onboarding_item.actions';
import {
  nullValidator,
  emailValidator,
  validate,
  phoneNumberValidator
} from '../validators/common';
import { passportCreate } from './passport.actions';
import { utilityBillCreate } from './utility_bill.actions';
import {
  formPassportAdd,
  formUtilityBillAdd,
  redirectAfterPassportOrUtilityBillEdit
} from './helpers';
import { checkPreviousRecipient } from '../features/kyc/circulationContactsSlice';
import { getMessageFromError } from 'views/Admin/helpers';
import i18n from '../i18n';
import { createTaxResident } from '../features/kyc/taxResidentsSlice';

const uboValidation = async (firstName, lastName, title, email, phone, taxResidents) => {
  const validateObject = {
    firstName,
    lastName,
    title,
    email,
    taxResidents
  };

  const validateConfig = {
    firstName: [nullValidator()],
    lastName: [nullValidator()],
    title: [nullValidator()],
    email: [nullValidator(), emailValidator()],
    taxResidents: [
      async value => {
        if (value && value.length < 1) {
          throw i18n.t('Should be at least one', { object: i18n.t('TaxResident.TaxResident') });
        }

        return value;
      }
    ]
  };

  if (phone) {
    validateObject.phone = phone;
    validateConfig.phone = [phoneNumberValidator()];
  }

  await validate(validateObject, validateConfig);
};

export const createPassports = async (passports, orgId, deleteActionType, dispatch) => {
  const createdPassports = [];
  const passportsCreateRequests = passports.map(passport =>
    dispatch(
      passportCreate(
        orgId,
        passport.passportNumber,
        passport.country,
        passport.issuedOn,
        passport.validUntil,
        passport.fileId,
        passport.isPrimary,
        response => createdPassports.push(response)
      )
    )
  );
  await Promise.all(passportsCreateRequests);
  passports.forEach(p =>
    dispatch({
      type: deleteActionType,
      payload: p.id
    })
  );
  return createdPassports;
};

export const createUtilityBills = async (utility_bills, orgId, deleteActionType, dispatch) => {
  const createdUtilityBills = [];
  const utilityBillsCreateRequests = utility_bills.map(utilityBill =>
    dispatch(
      utilityBillCreate(
        orgId,
        utilityBill.date,
        utilityBill.residenceAddress,
        utilityBill.type,
        utilityBill.fileId,
        utilityBill.isPrimary,
        response => createdUtilityBills.push(response)
      )
    )
  );
  await Promise.all(utilityBillsCreateRequests);
  utility_bills.forEach(ub =>
    dispatch({
      type: deleteActionType,
      payload: ub.id
    })
  );
  return createdUtilityBills;
};

export function uboCreate(
  orgId,
  investorId,
  firstName,
  lastName,
  title,
  email,
  additionalEmail,
  phone,
  sendNotices,
  mainRecipient,
  cvFileId,
  passports,
  utility_bills,
  source_of_wealth_proofs,
  taxResidents,
  cb
) {
  return async dispatch => {
    try {
      await uboValidation(firstName, lastName, title, email, phone, taxResidents);

      const createdPassports = await createPassports(
        passports,
        orgId,
        uboConstants.UBO_CREATE_FORM_PASSPORT_DELETE,
        dispatch
      );
      const createdUtilityBills = await createUtilityBills(
        utility_bills,
        orgId,
        uboConstants.UBO_CREATE_FORM_UTILITY_BILL_DELETE,
        dispatch
      );

      dispatch({ type: uboConstants.UBO_CREATE_LOADING });

      const response = await uboService.create(
        orgId,
        investorId,
        firstName,
        lastName,
        title,
        email,
        additionalEmail,
        phone,
        sendNotices,
        mainRecipient,
        cvFileId,
        createdPassports,
        createdUtilityBills,
        source_of_wealth_proofs,
        taxResidents
      );

      dispatch({ type: uboConstants.UBO_CREATE_SUCCESS, response });
      dispatch(checkPreviousRecipient(response.previousRecipient));
      dispatch(investorOnboardingItemActions.listInvestorOnboardingItems(orgId));

      if (cb) {
        cb();
      }
    } catch (error) {
      dispatch({ type: uboConstants.UBO_CREATE_FAILURE, error });
    }
  };
}

export function listUbos(orgId) {
  return async dispatch => {
    try {
      dispatch({ type: uboConstants.UBO_LIST_LOADING });

      const response = await uboService.listUbos(orgId);

      dispatch({ type: uboConstants.UBO_LIST_SUCCESS, response });
    } catch (error) {
      dispatch({ type: uboConstants.UBO_LIST_FAILURE, error });
    }
  };
}

export function uboEdit(
  orgId,
  uboId,
  investorId,
  firstName,
  lastName,
  title,
  email,
  additionalEmail,
  phone,
  sendNotices,
  mainRecipient,
  cvFileId,
  passportsOld,
  passportsNew,
  utilityBillsOld,
  utilityBillsNew,
  source_of_wealth_proofs,
  taxResidents
) {
  return async dispatch => {
    try {
      await uboValidation(firstName, lastName, title, email, phone, taxResidents);

      const createdPassports = await createPassports(
        passportsNew,
        orgId,
        uboConstants.UBO_EDIT_FORM_PASSPORT_DELETE,
        dispatch
      );
      const passports = List(createdPassports).concat(passportsOld);

      const createdUtilityBills = await createUtilityBills(
        utilityBillsNew,
        orgId,
        uboConstants.UBO_EDIT_FORM_UTILITY_BILL_DELETE,
        dispatch
      );
      const utilityBills = List(createdUtilityBills).concat(utilityBillsOld);

      dispatch({ type: uboConstants.UBO_EDIT_LOADING });

      const response = await uboService.edit(
        orgId,
        uboId,
        investorId,
        firstName,
        lastName,
        title,
        email,
        additionalEmail,
        phone,
        sendNotices,
        mainRecipient,
        cvFileId,
        passports,
        utilityBills,
        source_of_wealth_proofs,
        taxResidents
      );

      dispatch({ type: uboConstants.UBO_EDIT_SUCCESS, response });
      dispatch(checkPreviousRecipient(response.previousRecipient));
      dispatch(investorOnboardingItemActions.listInvestorOnboardingItems(orgId));
      history.push(`/kyc/${investorId}`);
    } catch (error) {
      dispatch({ type: uboConstants.UBO_EDIT_FAILURE, error });
      throw getMessageFromError(error);
    }
  };
}

export function uboDelete(orgId, uboId) {
  return async dispatch => {
    try {
      dispatch({ type: uboConstants.UBO_DELETE_LOADING });

      await uboService.deleteUbo(orgId, uboId);

      dispatch({ type: uboConstants.UBO_DELETE_SUCCESS, uboId });
      dispatch(investorOnboardingItemActions.listInvestorOnboardingItems(orgId));
    } catch (error) {
      dispatch({ type: uboConstants.UBO_DELETE_FAILURE, error });
      throw getMessageFromError(error);
    }
  };
}

export function reloadUboError() {
  return async dispatch => {
    dispatch({ type: uboConstants.UBO_ERROR_RELOAD });
  };
}

export function uboCreateFormPassportAddAction(
  orgId,
  passportNumber,
  country,
  issuedOn,
  validUntil,
  fileId,
  isPrimary,
  onSuccess,
  uboId
) {
  return async dispatch => {
    await formPassportAdd(
      orgId,
      passportNumber,
      country,
      issuedOn,
      validUntil,
      fileId,
      isPrimary,
      uboId,
      onSuccess,
      dispatch,
      uboConstants.UBO_CREATE_FORM_PASSPORT_ADD
    );
  };
}

export function uboCreateFormPassportEditAction(passport) {
  return async dispatch => {
    dispatch({
      type: uboConstants.UBO_CREATE_FORM_PASSPORT_EDIT,
      payload: passport
    });
    redirectAfterPassportOrUtilityBillEdit(passport.uboId, passport.directorId);
  };
}

export function uboCreateFormPassportDeleteAction(passportId) {
  return async dispatch => {
    dispatch({
      type: uboConstants.UBO_CREATE_FORM_PASSPORT_DELETE,
      payload: passportId
    });
  };
}

export function uboCreateFormUtilityBillAddAction(
  orgId,
  date,
  residenceAddress,
  type,
  fileId,
  isPrimary,
  onSuccess,
  uboId
) {
  return async dispatch => {
    await formUtilityBillAdd(
      orgId,
      date,
      residenceAddress,
      type,
      fileId,
      isPrimary,
      onSuccess,
      uboId,
      dispatch,
      uboConstants.UBO_CREATE_FORM_UTILITY_BILL_ADD
    );
  };
}

export function uboCreateFormUtilityBillEditAction(utilityBill) {
  return async dispatch => {
    dispatch({
      type: uboConstants.UBO_CREATE_FORM_UTILITY_BILL_EDIT,
      payload: utilityBill
    });
    redirectAfterPassportOrUtilityBillEdit(utilityBill.uboId, utilityBill.directorId);
  };
}

export function uboCreateFormUtilityBillDeleteAction(utilityBillId) {
  return async dispatch => {
    dispatch({
      type: uboConstants.UBO_CREATE_FORM_UTILITY_BILL_DELETE,
      payload: utilityBillId
    });
  };
}

export function uboEditFormPassportAddAction(
  orgId,
  passportNumber,
  country,
  issuedOn,
  validUntil,
  fileId,
  isPrimary,
  onSuccess,
  uboId
) {
  return async dispatch => {
    await formPassportAdd(
      orgId,
      passportNumber,
      country,
      issuedOn,
      validUntil,
      fileId,
      isPrimary,
      uboId,
      onSuccess,
      dispatch,
      uboConstants.UBO_EDIT_FORM_PASSPORT_ADD
    );
  };
}

export function uboEditFormPassportEditAction(passport) {
  return async dispatch => {
    dispatch({
      type: uboConstants.UBO_EDIT_FORM_PASSPORT_EDIT,
      payload: passport
    });
    redirectAfterPassportOrUtilityBillEdit(passport.uboId, passport.directorId);
  };
}

export function uboEditFormPassportDeleteAction(passportId) {
  return async dispatch => {
    dispatch({
      type: uboConstants.UBO_EDIT_FORM_PASSPORT_DELETE,
      payload: passportId
    });
  };
}

export function uboEditFormResetPassports() {
  return async dispatch => {
    dispatch({ type: uboConstants.UBO_EDIT_FORM_RESET_PASSPORTS });
  };
}

export function uboEditFormUtilityBillAddAction(
  orgId,
  date,
  residenceAddress,
  type,
  fileId,
  isPrimary,
  onSuccess,
  uboId
) {
  return async dispatch => {
    await formUtilityBillAdd(
      orgId,
      date,
      residenceAddress,
      type,
      fileId,
      isPrimary,
      onSuccess,
      uboId,
      dispatch,
      uboConstants.UBO_EDIT_FORM_UTILITY_BILL_ADD
    );
  };
}

export function uboEditFormUtilityBillEditAction(passport) {
  return async dispatch => {
    dispatch({
      type: uboConstants.UBO_EDIT_FORM_UTILITY_BILL_EDIT,
      payload: passport
    });
    redirectAfterPassportOrUtilityBillEdit(passport.uboId, passport.directorId);
  };
}

export function uboEditFormUtilityBillDeleteAction(passportId) {
  return async dispatch => {
    dispatch({
      type: uboConstants.UBO_EDIT_FORM_UTILITY_BILL_DELETE,
      payload: passportId
    });
  };
}

export function uboEditFormResetUtilityBills() {
  return async dispatch => {
    dispatch({ type: uboConstants.UBO_EDIT_FORM_RESET_UTILITY_BILLS });
  };
}

export function copyUbo({ orgId, itemId, investorId }) {
  return async dispatch => {
    try {
      const response = await uboService.copyUbo(orgId, itemId, investorId);

      const { utility_bills, passports, sourceOfWealthProofs, taxResidents } = response;
      for (const utilityBill of utility_bills) {
        dispatch({ type: utilityBillConstants.UTILITY_BILL_CREATE_SUCCESS, response: utilityBill });
      }
      for (const passport of passports) {
        dispatch({ type: passportConstants.PASSPORT_CREATE_SUCCESS, response: passport });
      }
      for (const sourceOfWealthProof of sourceOfWealthProofs) {
        dispatch({
          type: sourceOfWealthProofConstants.SOURCE_OF_WEALTH_PROOF_CREATE_SUCCESS,
          response: sourceOfWealthProof
        });
      }
      for (const taxResident of taxResidents) {
        dispatch(createTaxResident.fulfilled(taxResident));
      }

      dispatch({
        type: uboConstants.UBO_CREATE_SUCCESS,
        response: {
          ...response,
          utility_bills: response.utility_bills.map(c => c.id),
          passports: response.passports.map(c => c.id),
          source_of_wealth_proofs: response.sourceOfWealthProofs.map(c => c.id),
          tax_residents: response.taxResidents.map(c => c.id)
        }
      });
    } catch (error) {
      throw getMessageFromError(error);
    }
  };
}
