export const directorConstants = {
  DIRECTOR_CREATE_LOADING: 'DIRECTOR_CREATE_LOADING',
  DIRECTOR_CREATE_SUCCESS: 'DIRECTOR_CREATE_SUCCESS',
  DIRECTOR_CREATE_FAILURE: 'DIRECTOR_CREATE_FAILURE',
  DIRECTOR_ERROR_RELOAD: 'DIRECTOR_ERROR_RELOAD',

  DIRECTOR_LIST_LOADING: 'DIRECTOR_LIST_LOADING',
  DIRECTOR_LIST_SUCCESS: 'DIRECTOR_LIST_SUCCESS',
  DIRECTOR_LIST_FAILURE: 'DIRECTOR_LIST_FAILURE',

  DIRECTOR_EDIT_LOADING: 'DIRECTOR_EDIT_LOADING',
  DIRECTOR_EDIT_SUCCESS: 'DIRECTOR_EDIT_SUCCESS',
  DIRECTOR_EDIT_FAILURE: 'DIRECTOR_EDIT_FAILURE',

  DIRECTOR_DELETE_LOADING: 'DIRECTOR_DELETE_LOADING',
  DIRECTOR_DELETE_SUCCESS: 'DIRECTOR_DELETE_SUCCESS',
  DIRECTOR_DELETE_FAILURE: 'DIRECTOR_DELETE_FAILURE',

  DIRECTOR_CREATE_FORM_PASSPORT_ADD: 'DIRECTOR_CREATE_FORM_PASSPORT_ADD',
  DIRECTOR_CREATE_FORM_PASSPORT_EDIT: 'DIRECTOR_CREATE_FORM_PASSPORT_EDIT',
  DIRECTOR_CREATE_FORM_PASSPORT_DELETE: 'DIRECTOR_CREATE_FORM_PASSPORT_DELETE',

  DIRECTOR_CREATE_FORM_UTILITY_BILL_ADD: 'DIRECTOR_CREATE_FORM_UTILITY_BILL_ADD',
  DIRECTOR_CREATE_FORM_UTILITY_BILL_EDIT: 'DIRECTOR_CREATE_FORM_UTILITY_BILL_EDIT',
  DIRECTOR_CREATE_FORM_UTILITY_BILL_DELETE: 'DIRECTOR_CREATE_FORM_UTILITY_BILL_DELETE',

  DIRECTOR_EDIT_FORM_PASSPORT_ADD: 'DIRECTOR_EDIT_FORM_PASSPORT_ADD',
  DIRECTOR_EDIT_FORM_PASSPORT_EDIT: 'DIRECTOR_EDIT_FORM_PASSPORT_EDIT',
  DIRECTOR_EDIT_FORM_PASSPORT_DELETE: 'DIRECTOR_EDIT_FORM_PASSPORT_DELETE',
  DIRECTOR_EDIT_FORM_RESET_PASSPORTS: 'DIRECTOR_EDIT_FORM_RESET_PASSPORTS',

  DIRECTOR_EDIT_FORM_UTILITY_BILL_ADD: 'DIRECTOR_EDIT_FORM_UTILITY_BILL_ADD',
  DIRECTOR_EDIT_FORM_UTILITY_BILL_EDIT: 'DIRECTOR_EDIT_FORM_UTILITY_BILL_EDIT',
  DIRECTOR_EDIT_FORM_UTILITY_BILL_DELETE: 'DIRECTOR_EDIT_FORM_UTILITY_BILL_DELETE',
  DIRECTOR_EDIT_FORM_RESET_UTILITY_BILLS: 'DIRECTOR_EDIT_FORM_RESET_UTILITY_BILLS',

  RESET_DIRECTOR_MAIN_RECIPIENT: 'RESET_DIRECTOR_MAIN_RECIPIENT'
};
