export const investorOnboardingItemConstants = {
  VOID_ENVELOPE_LOADING_TRUE: 'VOID_ENVELOPE_LOADING_TRUE',
  VOID_ENVELOPE_LOADING_FALSE: 'VOID_ENVELOPE_LOADING_FALSE',

  SIGN_ITEM_LOADING_TRUE: 'SIGN_ITEM_LOADING_TRUE',
  SIGN_ITEM_LOADING_FALSE: 'SIGN_ITEM_LOADING_FALSE',

  INVESTOR_ONBOARDING_ITEM_LIST_LOADING: 'INVESTOR_ONBOARDING_ITEM_LIST_LOADING',
  INVESTOR_ONBOARDING_ITEM_LIST_SUCCESS: 'INVESTOR_ONBOARDING_ITEM_LIST_SUCCESS',
  INVESTOR_ONBOARDING_ITEM_LIST_FAILURE: 'INVESTOR_ONBOARDING_ITEM_LIST_FAILURE',

  INVESTOR_ONBOARDING_ITEM_SIGN_LOADING: 'INVESTOR_ONBOARDING_ITEM_SIGN_LOADING',
  INVESTOR_ONBOARDING_ITEM_SIGN_SUCCESS: 'INVESTOR_ONBOARDING_ITEM_SIGN_SUCCESS',
  INVESTOR_ONBOARDING_ITEM_SIGN_FAILURE: 'INVESTOR_ONBOARDING_ITEM_SIGN_FAILURE'
};
