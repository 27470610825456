import { docusignConstants } from '../constants';

export function docusign(state = {}, action) {
  switch (action.type) {
    case docusignConstants.DOCUSIGN_SIGN_COMMITMENT_PACK_LOADING:
      return {
        ...state,
        loading: true
      };
    case docusignConstants.DOCUSIGN_SIGN_COMMITMENT_PACK_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case docusignConstants.DOCUSIGN_SIGN_COMMITMENT_PACK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return state;
  }
}
