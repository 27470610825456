import { List } from 'immutable';
import { leadCustomFieldConstants } from '../constants/lead_custom_field.constants';

const INITIAL_STATE = {
  items: null,
  listLoading: false,
  listError: null,

  createLoading: false,
  createError: null,

  editLoading: false,
  editError: null,

  deleteLoading: false,
  deleteError: null
};

export function lead_custom_fields(state = INITIAL_STATE, action) {
  switch (action.type) {
    case leadCustomFieldConstants.LEAD_CUSTOM_FIELD_LIST_LOADING:
      return {
        ...state,
        listLoading: true,
        items: null
      };

    case leadCustomFieldConstants.LEAD_CUSTOM_FIELD_LIST_SUCCESS:
      return {
        ...state,
        listLoading: false,
        listError: null,
        items: List(action.response)
      };

    case leadCustomFieldConstants.LEAD_CUSTOM_FIELD_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        listError: action.error,
        items: null
      };

    case leadCustomFieldConstants.LEAD_CUSTOM_FIELD_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };

    case leadCustomFieldConstants.LEAD_CUSTOM_FIELD_CREATE_SUCCESS:
      return {
        ...state,
        items: state.items.push(action.response),
        createLoading: false,
        createError: null
      };

    case leadCustomFieldConstants.LEAD_CUSTOM_FIELD_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };

    case leadCustomFieldConstants.LEAD_CUSTOM_FIELD_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };

    case leadCustomFieldConstants.LEAD_CUSTOM_FIELD_EDIT_SUCCESS: {
      const itemsWithoutChangedItem = state.items.filter(i => i.id !== action.response.id);
      return {
        ...state,
        items: itemsWithoutChangedItem.push(action.response),
        editLoading: false,
        editError: null
      };
    }

    case leadCustomFieldConstants.LEAD_CUSTOM_FIELD_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case leadCustomFieldConstants.LEAD_CUSTOM_FIELD_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };

    case leadCustomFieldConstants.LEAD_CUSTOM_FIELD_DELETE_SUCCESS:
      return {
        ...state,
        items: state.items.filter(
          f => f.id !== action.customFieldIdentifier && f.apiName !== action.customFieldIdentifier
        ),
        deleteLoading: false,
        deleteError: null
      };

    case leadCustomFieldConstants.LEAD_CUSTOM_FIELD_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };

    case leadCustomFieldConstants.LEAD_CUSTOM_FIELD_ERROR_RELOAD:
      return {
        ...state,
        createError: null,
        editError: null,
        deleteError: null
      };

    default:
      return state;
  }
}
