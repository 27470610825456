import React, { Fragment, useState } from 'react';
import { Tooltip } from 'reactstrap';
import { Trans as T } from 'react-i18next';

const withTooltip = WrappedInput => ({ tooltipText, customTooltipText, ...props }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const toggle = () => setIsTooltipOpen(!isTooltipOpen);

  return (
    <Fragment>
      <WrappedInput {...props}>{React.Children.map(props.children, child => child)}</WrappedInput>
      {(tooltipText || customTooltipText) && (
        <Tooltip
          flip={false}
          placement="right"
          isOpen={isTooltipOpen}
          target={props.id}
          toggle={toggle}
        >
          {customTooltipText ? customTooltipText : <T i18nKey={tooltipText} />}
        </Tooltip>
      )}
    </Fragment>
  );
};

export default withTooltip;
