import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './locales/en/translation.json';
import translationFR from './locales/fr/translation.json';
import translationRU from './locales/ru/translation.json';
import translationZH from './locales/zh/translation.json';
import translationES from './locales/es/translation.json';

const resources = {
  en: {
    translation: translationEN
  },
  ru: {
    translation: translationRU
  },
  fr: {
    translation: translationFR
  },
  zh: {
    translation: translationZH
  },
  es: {
    translation: translationES
  }
};

const detectOptions = {
  order: ['querystring', 'localStorage'],
  lookupLocalStorage: 'lang',
  lookupQuerystring: 'lang',
  caches: ['localStorage'],
  checkWhitelist: true
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: detectOptions,
    resources,
    fallbackLng: 'en',
    whitelist: ['en', 'ru', 'fr', 'zh', 'es'],
    debug: false,
    interpolation: {
      escapeValue: false
    },
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'li', 'a', 'u', 'ol', 'ul', 'p', 'b']
    }
  });

export default i18n;
