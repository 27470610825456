import { combineReducers } from 'redux';
import unapprovedDealsReducer from './unapprovedDealsSlice';
import publishedDealsReducer from './publishedDealsSlice';
import userDealsReducer from './userDealsSlice';
import lastPublishedDealsReducer from './lastPublishedDealsSlice';

export const marketplaceReducer = combineReducers({
  userDeals: userDealsReducer,
  unapprovedDeals: unapprovedDealsReducer,
  publishedDeals: publishedDealsReducer,
  lastPublishedDeals: lastPublishedDealsReducer
});
