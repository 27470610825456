import { List } from 'immutable';
import { bankAccountConstants } from '../constants';

export function bank_accounts(state = {}, action) {
  switch (action.type) {
    case bankAccountConstants.BANK_ACCOUNT_LIST_LOADING:
      return {
        ...state,
        items: null,
        listLoading: true
      };
    case bankAccountConstants.BANK_ACCOUNT_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case bankAccountConstants.BANK_ACCOUNT_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: null,
        listError: action.error
      };
    case bankAccountConstants.BANK_ACCOUNT_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case bankAccountConstants.BANK_ACCOUNT_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      return {
        ...state,
        items: items.push(action.response),
        createLoading: false,
        createError: null
      };
    }

    case bankAccountConstants.BANK_ACCOUNT_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };

    case bankAccountConstants.BANK_ACCOUNT_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case bankAccountConstants.BANK_ACCOUNT_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const bankAccountIndex = state.items.findIndex(val => val.id == action.response.id);

      if (bankAccountIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(bankAccountIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case bankAccountConstants.BANK_ACCOUNT_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case bankAccountConstants.BANK_ACCOUNT_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case bankAccountConstants.BANK_ACCOUNT_DELETE_SUCCESS: {
      const { bankAccountId } = action;

      if (!state.items) {
        return state;
      }

      const bankAccountIndex = state.items.findIndex(val => val.id == bankAccountId);

      if (bankAccountIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(bankAccountIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case bankAccountConstants.BANK_ACCOUNT_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };
    case bankAccountConstants.BANK_ACCOUNT_ERROR_RELOAD:
      return {
        ...state,
        createError: null,
        editError: null
      };

    default:
      return state;
  }
}
