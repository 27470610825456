import { List } from 'immutable';
import { history } from '../../history';

export const historyGoBack = event => {
  event.preventDefault();
  history.goBack();
};

export const isPassportsCurrentFlagValid = (passports, t) => {
  const passportsList = List(passports);
  if (passportsList.size) {
    if (passportsList.filter(p => p.isPrimary).size === 0) {
      window.alert(t('YouNeedSetPrimaryFlagPassport'));
      return false;
    }
    if (passportsList.filter(p => p.isPrimary).size > 1) {
      window.alert(t('YouCannotHaveSeveralPrimaryFlagPassport'));
      return false;
    }
  }
  return true;
};

export const isUtilityBillsCurrentFlagValid = (utilityBills, t) => {
  const utilityBillsList = List(utilityBills);
  if (utilityBillsList.size) {
    if (utilityBillsList.filter(p => p.isPrimary).size === 0) {
      window.alert(t('YouNeedSetPrimaryFlagUtilityBill'));
      return false;
    }
    if (utilityBillsList.filter(p => p.isPrimary).size > 1) {
      window.alert(t('YouCannotHaveSeveralPrimaryFlagUtilityBill'));
      return false;
    }
  }
  return true;
};

export const getUniqueIdentifier = () => new Date().valueOf().toString();
