import { INVESTMENT_WIZARD_CONSTANTS } from '../constants';

const INITIAL_INVESTMENT = {
  companyId: '',
  fundId: '',
  investment_type: 'Equity',
  description: '',
  isConverted: false,
  conversionDate: '',
  interestRate: '',
  conversionFactor: ''
};
const DEFAULT_CHOOSEN_STATE = {
  id: '',
  fundName: '',
  investment_type: INVESTMENT_WIZARD_CONSTANTS.EQUITY
};

export const investmentWizardState = {
  step: 1,
  wizardInvId: '',
  newInvestment: INITIAL_INVESTMENT,
  chosenInvestment: DEFAULT_CHOOSEN_STATE,
  stepTwoActiveTab: INVESTMENT_WIZARD_CONSTANTS.SHAREHOLDERS,
  stepFiveActiveTab: INVESTMENT_WIZARD_CONSTANTS.TRANSACTIONS,
  isCreationFlow: null
};

export const investment_wizard = (state = {}, { type, payload }) => {
  switch (type) {
    case INVESTMENT_WIZARD_CONSTANTS.REGISTER:
      return {
        ...state,
        [payload.companyId]: {
          ...investmentWizardState
        }
      };
    case INVESTMENT_WIZARD_CONSTANTS.RESET:
      return {
        ...state,
        [payload.companyId]: {
          ...investmentWizardState
        }
      };
    case INVESTMENT_WIZARD_CONSTANTS.CHANGE_STEP:
      return {
        ...state,
        [payload.companyId]: {
          ...state[payload.companyId],
          step: payload.step
        }
      };
    case INVESTMENT_WIZARD_CONSTANTS.SET_CURR_INV_ID:
      return {
        ...state,
        [payload.companyId]: {
          ...state[payload.companyId],
          wizardInvId: payload.wizardInvId
        }
      };
    case INVESTMENT_WIZARD_CONSTANTS.SET_FLOW_TYPE:
      return {
        ...state,
        [payload.companyId]: {
          ...state[payload.companyId],
          isCreationFlow: payload.isCreationFlow
        }
      };
    case INVESTMENT_WIZARD_CONSTANTS.CHANGE_NEW_INV_FIELD:
      return {
        ...state,
        [payload.companyId]: {
          ...state[payload.companyId],
          newInvestment: {
            ...state[payload.companyId].newInvestment,
            companyId: payload.companyId,
            [payload.name]: payload.value
          }
        }
      };
    case INVESTMENT_WIZARD_CONSTANTS.CHANGE_CHOOSEN_INV_FIELD:
      return {
        ...state,
        [payload.companyId]: {
          ...state[payload.companyId],
          chosenInvestment: {
            ...state[payload.companyId].chosenInvestment,
            [payload.name]: payload.value
          }
        }
      };
    case INVESTMENT_WIZARD_CONSTANTS.CHOOSE_INVESTMENT:
      return {
        ...state,
        [payload.companyId]: {
          ...state[payload.companyId],
          chosenInvestment: payload.chosenInvestment
        }
      };
    case INVESTMENT_WIZARD_CONSTANTS.CHANGE_TAB_STEP_TWO:
      return {
        ...state,
        [payload.companyId]: {
          ...state[payload.companyId],
          stepTwoActiveTab: payload.stepTwoActiveTab
        }
      };
    case INVESTMENT_WIZARD_CONSTANTS.CHANGE_TAB_STEP_FIVE:
      return {
        ...state,
        [payload.companyId]: {
          ...state[payload.companyId],
          stepFiveActiveTab: payload.stepFiveActiveTab
        }
      };

    default:
      return state;
  }
};
