import React from 'react';
import FormInput from 'views/Common/Inputs/FormInput';
import { FormGroup, Label } from 'reactstrap';
import { Trans as T } from 'react-i18next';
import PropTypes from 'prop-types';
import withTooltip from 'views/Common/Inputs/withTooltip';

const Checkbox = ({ value, textToTheRightOfTheCheckbox, ...props }) => (
  <FormGroup check>
    <FormInput type="checkbox" checked={value} className="form-check-input" {...props} />
    {textToTheRightOfTheCheckbox && (
      <Label className="mb-0">
        <small>
          <T i18nKey={textToTheRightOfTheCheckbox} />
        </small>
      </Label>
    )}
  </FormGroup>
);

export const CheckboxWithTooltip = withTooltip(Checkbox);

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  handleChange: PropTypes.func, // Required if the checkbox is not disabled
  textToTheRightOfTheCheckbox: PropTypes.string
};

export default Checkbox;
