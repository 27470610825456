export const managementFeeConstants = {
  MANAGEMENT_FEE_CREATE_LOADING: 'MANAGEMENT_FEE_CREATE_LOADING',
  MANAGEMENT_FEE_CREATE_SUCCESS: 'MANAGEMENT_FEE_CREATE_SUCCESS',
  MANAGEMENT_FEE_CREATE_FAILURE: 'MANAGEMENT_FEE_CREATE_FAILURE',
  MANAGEMENT_FEE_ERROR_RELOAD: 'MANAGEMENT_FEE_ERROR_RELOAD',

  MANAGEMENT_FEE_LIST_LOADING: 'MANAGEMENT_FEE_LIST_LOADING',
  MANAGEMENT_FEE_LIST_SUCCESS: 'MANAGEMENT_FEE_LIST_SUCCESS',
  MANAGEMENT_FEE_LIST_FAILURE: 'MANAGEMENT_FEE_LIST_FAILURE',
  MANAGEMENT_FEE_LIST_CLEAR: 'MANAGEMENT_FEE_LIST_CLEAR',

  MANAGEMENT_FEE_EDIT_LOADING: 'MANAGEMENT_FEE_EDIT_LOADING',
  MANAGEMENT_FEE_EDIT_SUCCESS: 'MANAGEMENT_FEE_EDIT_SUCCESS',
  MANAGEMENT_FEE_EDIT_FAILURE: 'MANAGEMENT_FEE_EDIT_FAILURE',

  MANAGEMENT_FEE_DELETE_LOADING: 'MANAGEMENT_FEE_DELETE_LOADING',
  MANAGEMENT_FEE_DELETE_SUCCESS: 'MANAGEMENT_FEE_DELETE_SUCCESS',
  MANAGEMENT_FEE_DELETE_FAILURE: 'MANAGEMENT_FEE_DELETE_FAILURE'
};
