export const subscriptionFeeConstants = {
  SUBSCRIPTION_FEE_CREATE_LOADING: 'SUBSCRIPTION_FEE_CREATE_LOADING',
  SUBSCRIPTION_FEE_CREATE_SUCCESS: 'SUBSCRIPTION_FEE_CREATE_SUCCESS',
  SUBSCRIPTION_FEE_CREATE_FAILURE: 'SUBSCRIPTION_FEE_CREATE_FAILURE',
  SUBSCRIPTION_FEE_ERROR_RELOAD: 'SUBSCRIPTION_FEE_ERROR_RELOAD',

  SUBSCRIPTION_FEE_LIST_LOADING: 'SUBSCRIPTION_FEE_LIST_LOADING',
  SUBSCRIPTION_FEE_LIST_SUCCESS: 'SUBSCRIPTION_FEE_LIST_SUCCESS',
  SUBSCRIPTION_FEE_LIST_FAILURE: 'SUBSCRIPTION_FEE_LIST_FAILURE',
  SUBSCRIPTION_FEE_LIST_CLEAR: 'SUBSCRIPTION_FEE_LIST_CLEAR',

  SUBSCRIPTION_FEE_EDIT_LOADING: 'SUBSCRIPTION_FEE_EDIT_LOADING',
  SUBSCRIPTION_FEE_EDIT_SUCCESS: 'SUBSCRIPTION_FEE_EDIT_SUCCESS',
  SUBSCRIPTION_FEE_EDIT_FAILURE: 'SUBSCRIPTION_FEE_EDIT_FAILURE',

  SUBSCRIPTION_FEE_DELETE_LOADING: 'SUBSCRIPTION_FEE_DELETE_LOADING',
  SUBSCRIPTION_FEE_DELETE_SUCCESS: 'SUBSCRIPTION_FEE_DELETE_SUCCESS',
  SUBSCRIPTION_FEE_DELETE_FAILURE: 'SUBSCRIPTION_FEE_DELETE_FAILURE'
};
