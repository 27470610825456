export const embedConstants = {
  EMBED_CREATE_LOADING: 'EMBED_CREATE_LOADING',
  EMBED_CREATE_SUCCESS: 'EMBED_CREATE_SUCCESS',
  EMBED_CREATE_FAILURE: 'EMBED_CREATE_FAILURE',
  EMBED_ERROR_RELOAD: 'EMBED_ERROR_RELOAD',

  EMBED_LIST_LOADING: 'EMBED_LIST_LOADING',
  EMBED_LIST_SUCCESS: 'EMBED_LIST_SUCCESS',
  EMBED_LIST_FAILURE: 'EMBED_LIST_FAILURE',

  EMBED_EDIT_LOADING: 'EMBED_EDIT_LOADING',
  EMBED_EDIT_SUCCESS: 'EMBED_EDIT_SUCCESS',
  EMBED_EDIT_FAILURE: 'EMBED_EDIT_FAILURE',

  EMBED_DELETE_LOADING: 'EMBED_DELETE_LOADING',
  EMBED_DELETE_SUCCESS: 'EMBED_DELETE_SUCCESS',
  EMBED_DELETE_FAILURE: 'EMBED_DELETE_FAILURE'
};
