export const utilityBillConstants = {
  UTILITY_BILL_CREATE_LOADING: 'UTILITY_BILL_CREATE_LOADING',
  UTILITY_BILL_CREATE_SUCCESS: 'UTILITY_BILL_CREATE_SUCCESS',
  UTILITY_BILL_CREATE_FAILURE: 'UTILITY_BILL_CREATE_FAILURE',
  UTILITY_BILL_ERROR_RELOAD: 'UTILITY_BILL_ERROR_RELOAD',

  UTILITY_BILL_LIST_LOADING: 'UTILITY_BILL_LIST_LOADING',
  UTILITY_BILL_LIST_SUCCESS: 'UTILITY_BILL_LIST_SUCCESS',
  UTILITY_BILL_LIST_FAILURE: 'UTILITY_BILL_LIST_FAILURE',

  UTILITY_BILL_EDIT_LOADING: 'UTILITY_BILL_EDIT_LOADING',
  UTILITY_BILL_EDIT_SUCCESS: 'UTILITY_BILL_EDIT_SUCCESS',
  UTILITY_BILL_EDIT_FAILURE: 'UTILITY_BILL_EDIT_FAILURE',

  UTILITY_BILL_DELETE_LOADING: 'UTILITY_BILL_DELETE_LOADING',
  UTILITY_BILL_DELETE_SUCCESS: 'UTILITY_BILL_DELETE_SUCCESS',
  UTILITY_BILL_DELETE_FAILURE: 'UTILITY_BILL_DELETE_FAILURE'
};
