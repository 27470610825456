import { axiosOptions } from 'services/constants';
import api from 'components/api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createItemInitialState,
  editItemInitialState,
  ERROR_TYPES,
  itemsInitialState
} from './constants';
import { validate } from '../validators/common';
import { CustomError } from 'views/helpers';

//region Load items
export const loadItemsPending = state => {
  state.listLoading = true;
  state.listError = null;
};

export const loadItemsFulfilled = (state, { payload }) => {
  state.listLoading = false;
  state.items = payload;
};

export const loadItemsRejected = (state, { payload }) => {
  state.listLoading = false;
  state.listError = payload.reduxPayload;
};

export const getDefaultReducersForEntityItemsLoad = asyncThunk => ({
  [asyncThunk.pending]: loadItemsPending,
  [asyncThunk.fulfilled]: loadItemsFulfilled,
  [asyncThunk.rejected]: loadItemsRejected
});
//endregion

//region Create item
export const createItemPending = state => {
  state.createLoading = true;
  state.createError = null;
};

export const createItemFulfilled = (state, { payload }) => {
  state.createLoading = false;
  if (state.items) {
    state.items.push(payload);
  } else {
    state.items = [payload];
  }
  state.createError = null;
};

export const createItemRejected = (state, { payload }) => {
  state.createLoading = false;
  state.createError = payload.reduxPayload;
};

export const getDefaultReducersForEntityCreate = asyncThunk => ({
  [asyncThunk.pending]: createItemPending,
  [asyncThunk.fulfilled]: createItemFulfilled,
  [asyncThunk.rejected]: createItemRejected
});
//endregion

//region Edit item
export const editItemPending = state => {
  state.editLoading = true;
  state.editError = null;
};

export const editItemFulfilled = (state, { payload }) => {
  if (state.editLoading) state.editLoading = false;
  if (!state.items) return;

  const itemIndex = state.items.findIndex(val => val.id === payload.id);
  if (itemIndex === -1) return;
  state.items.splice(itemIndex, 1, payload);
  state.editError = null;
};

export const editItemRejected = (state, { payload }) => {
  state.editLoading = false;
  state.editError = payload.reduxPayload;
};

export const getDefaultReducersForEntityEdit = asyncThunk => ({
  [asyncThunk.pending]: editItemPending,
  [asyncThunk.fulfilled]: editItemFulfilled,
  [asyncThunk.rejected]: editItemRejected
});
//endregion

export const deleteItemFulfilled = (state, { payload }) => {
  if (!state.items) return;
  state.items = state.items.filter(i => i.id !== payload.id);
};

export const getDefaultReducersForEntityDelete = asyncThunk => ({
  [asyncThunk.fulfilled]: deleteItemFulfilled
});

export const defaultLoadItemsPayloadCreator = url => async (orgId, { rejectWithValue }) => {
  try {
    const response = await api.get(`/org/${orgId}${url}`, axiosOptions);

    return response.data;
  } catch (error) {
    return rejectWithValue(new CustomError(error));
  }
};

export const createThunkForLoadEntityItems = (rootName, baseUrl, possibleResponseUserErrors) =>
  createAsyncThunk(`${rootName}/load`, async (orgId, { rejectWithValue }) => {
    try {
      const response = await api.get(`/org/${orgId}${baseUrl}`, axiosOptions);

      return response.data;
    } catch (error) {
      return rejectWithValue(new CustomError(error, possibleResponseUserErrors));
    }
  });

export const createThunkForEditEntity = (
  rootName,
  baseUrl,
  entityValidation,
  possibleResponseUserErrors
) =>
  createAsyncThunk(`${rootName}/edit`, async ({ entity, orgId }, { rejectWithValue }) => {
    try {
      if (entityValidation) {
        await entityValidation(entity);
      }

      const response = await api.put(`/org/${orgId}${baseUrl}/${entity.id}`, entity);

      return response.data;
    } catch (error) {
      return rejectWithValue(new CustomError(error, possibleResponseUserErrors));
    }
  });

export const createThunkForCreateEntity = (
  rootName,
  baseUrl,
  entityValidation,
  possibleResponseUserErrors
) =>
  createAsyncThunk(`${rootName}/create`, async ({ entity, orgId }, { rejectWithValue }) => {
    try {
      if (entityValidation) {
        await entityValidation(entity);
      }

      const response = await api.post(`/org/${orgId}${baseUrl}`, entity);

      return response.data;
    } catch (error) {
      return rejectWithValue(new CustomError(error, possibleResponseUserErrors));
    }
  });

export const createThunkForDeleteEntity = (rootName, baseUrl, possibleResponseUserErrors) =>
  createAsyncThunk(`${rootName}/delete`, async ({ id, orgId }, { rejectWithValue }) => {
    try {
      const response = await api.delete(`/org/${orgId}${baseUrl}/${id}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(new CustomError(error, possibleResponseUserErrors));
    }
  });

export const createSystemErrors = errors =>
  errors.map(error => ({
    error,
    type: ERROR_TYPES.SYSTEM_ERROR
  }));

/* generateCodeForSlice Example
export const {
  rootName,
  generatedInitialState,
  generatedExtraReducers,
  loadNotificationRecipients,
  editNotificationRecipient,
  deleteNotificationRecipient
} = generateCodeForSlice({ entityName: 'NotificationRecipient' }, [
  SLICE_CODE_FOR.LOAD,
  SLICE_CODE_FOR.EDIT,
  SLICE_CODE_FOR.DELETE
]);
 */
export const SLICE_CODE_FOR = {
  LOAD: 'load', // Invoke create action like this `loadEntitiesAction(orgId)`
  CREATE: 'create', // Invoke create action like this `createEntityAction({ entity, orgId })`
  EDIT: 'edit', // Invoke edit action like this `editEntityAction({ entity, orgId })`
  DELETE: 'delete', // Invoke delete action like this `deleteEntityAction({ id, orgId })`
  RELOAD_ERROR: 'reloadError' // `reloadEntitiesError`
};
export const generateCodeForSlice = (config, codeForThings) => {
  let {
    entityName, // Required. Example: 'EmailNotification'
    rootName, // Optional -> will be created based on entityName. Example: 'emailNotifications'
    baseUrl, // Optional -> will be created based on entityName. Example: '/email_notification'
    entityValidation, // Optional
    possibleResponseUserErrors // Optional. For all CRUD requests in one array. More info in class CustomError
  } = config;

  if (!rootName) {
    rootName = entityName[0].toLowerCase() + entityName.slice(1) + 's';
  }
  if (!baseUrl) {
    baseUrl =
      '/' +
      entityName
        .replace(/([A-Z])/g, '_$1')
        .toLowerCase()
        .slice(1);
  }

  let initialState = {};
  let thunks = {};
  let reducers = {};
  let extraReducers = {};
  for (const codeForThing of codeForThings) {
    switch (codeForThing) {
      case SLICE_CODE_FOR.LOAD: {
        initialState = {
          ...initialState,
          ...itemsInitialState
        };
        const loadThunk = createThunkForLoadEntityItems(
          rootName,
          baseUrl,
          possibleResponseUserErrors
        );
        thunks = {
          ...thunks,
          [`load${rootName[0].toUpperCase() + rootName.substring(1)}`]: loadThunk
        };
        extraReducers = {
          ...extraReducers,
          ...getDefaultReducersForEntityItemsLoad(loadThunk)
        };
        break;
      }
      case SLICE_CODE_FOR.CREATE: {
        initialState = {
          ...initialState,
          ...createItemInitialState
        };
        const createThunk = createThunkForCreateEntity(
          rootName,
          baseUrl,
          entityValidation,
          possibleResponseUserErrors
        );
        thunks = {
          ...thunks,
          [`create${entityName}`]: createThunk
        };
        extraReducers = {
          ...extraReducers,
          ...getDefaultReducersForEntityCreate(createThunk)
        };
        break;
      }
      case SLICE_CODE_FOR.EDIT: {
        initialState = {
          ...initialState,
          ...editItemInitialState
        };
        const editThunk = createThunkForEditEntity(
          rootName,
          baseUrl,
          entityValidation,
          possibleResponseUserErrors
        );
        thunks = {
          ...thunks,
          [`edit${entityName}`]: editThunk
        };
        extraReducers = {
          ...extraReducers,
          ...getDefaultReducersForEntityEdit(editThunk)
        };
        break;
      }
      case SLICE_CODE_FOR.DELETE: {
        const deleteThunk = createThunkForDeleteEntity(
          rootName,
          baseUrl,
          possibleResponseUserErrors
        );
        thunks = {
          ...thunks,
          [`delete${entityName}`]: deleteThunk
        };
        extraReducers = {
          ...extraReducers,
          ...getDefaultReducersForEntityDelete(deleteThunk)
        };
        break;
      }
      case SLICE_CODE_FOR.RELOAD_ERROR: {
        initialState = {
          ...initialState,
          createError: null,
          editError: null
        };
        reducers = {
          ...reducers,
          [`reload${entityName}sError`]: state => {
            state.createError = null;
            state.editError = null;
          }
        };
        break;
      }
      default:
        throw new Error(`Code ${codeForThing} is not supported`);
    }
  }

  return {
    rootName,
    generatedInitialState: initialState,
    generatedReducers: reducers,
    generatedExtraReducers: extraReducers,
    [`${rootName}Selector`]: state => state[rootName], // Example: emailNotificationsSelector
    ...thunks // Examples: loadEmailNotifications, createEmailNotification, editEmailNotification, deleteEmailNotification
  };
};

/* generateSlice Example

export const {
  taxResidentsSlice,
  loadTaxResidents,
  createTaxResident,
  editTaxResident,
  deleteTaxResident
} = generateSlice({ entityName: 'TaxResident', entityValidation: taxResidentValidation }, [
  SLICE_CODE_FOR.LOAD,
  SLICE_CODE_FOR.CREATE,
  SLICE_CODE_FOR.EDIT,
  SLICE_CODE_FOR.DELETE
])({
  extraReducers: {
    [uboConstants.UBO_EDIT_SUCCESS]: uboCreateAndEditSuccessReducer
  }
});

*/
export const generateSlice = (config, codeForThings) => (sliceOptions = {}) => {
  const {
    generatedInitialState,
    generatedReducers,
    generatedExtraReducers,
    ...codeForSlice
  } = generateCodeForSlice(config, codeForThings);

  const slice = createSlice({
    name: codeForSlice.rootName,
    initialState: { ...generatedInitialState, ...(sliceOptions.initialState || {}) },
    reducers: { ...generatedReducers, ...(sliceOptions.reducers || {}) },
    extraReducers: {
      ...generatedExtraReducers,
      ...(sliceOptions.extraReducers || {})
    }
  });

  return {
    [`${codeForSlice.rootName}Slice`]: slice,
    ...codeForSlice,
    ...(slice.actions || {})
  };
};

/* createEntityValidator Example

const taxResidentValidation = createEntityValidator({
  taxNumber: [nullValidator()],
});

*/
export const createEntityValidator = config => {
  return async entity => {
    await validate(entity, config);
  };
};
