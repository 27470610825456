import { List } from 'immutable';
import { serviceProviderAgreementConstants } from '../constants';

export function service_provider_agreements(state = {}, action) {
  switch (action.type) {
    case serviceProviderAgreementConstants.SERVICE_PROVIDER_AGREEMENT_LIST_LOADING:
      return {
        ...state,
        listLoading: true
      };
    case serviceProviderAgreementConstants.SERVICE_PROVIDER_AGREEMENT_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case serviceProviderAgreementConstants.SERVICE_PROVIDER_AGREEMENT_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: [],
        listError: action.error
      };
    case serviceProviderAgreementConstants.SERVICE_PROVIDER_AGREEMENT_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case serviceProviderAgreementConstants.SERVICE_PROVIDER_AGREEMENT_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      return {
        ...state,
        items: items.push(action.response),
        createLoading: false,
        createError: null
      };
    }
    case serviceProviderAgreementConstants.SERVICE_PROVIDER_AGREEMENT_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };

    case serviceProviderAgreementConstants.SERVICE_PROVIDER_AGREEMENT_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case serviceProviderAgreementConstants.SERVICE_PROVIDER_AGREEMENT_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const serviceProviderAgreementIndex = state.items.findIndex(
        val => val.id == action.response.id
      );

      if (serviceProviderAgreementIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(serviceProviderAgreementIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case serviceProviderAgreementConstants.SERVICE_PROVIDER_AGREEMENT_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case serviceProviderAgreementConstants.SERVICE_PROVIDER_AGREEMENT_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case serviceProviderAgreementConstants.SERVICE_PROVIDER_AGREEMENT_DELETE_SUCCESS: {
      const { serviceProviderAgreementId } = action;

      if (!state.items) {
        return state;
      }

      const serviceProviderAgreementIndex = state.items.findIndex(
        val => val.id == serviceProviderAgreementId
      );

      if (serviceProviderAgreementIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(serviceProviderAgreementIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case serviceProviderAgreementConstants.SERVICE_PROVIDER_AGREEMENT_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };
    case serviceProviderAgreementConstants.SERVICE_PROVIDER_AGREEMENT_ERROR_RELOAD:
      return {
        ...state,
        createError: null,
        editError: null
      };

    default:
      return state;
  }
}
