import React, { Fragment } from 'react';
import { Table, TableHead, TableBody, TCell, TableLoader } from '../../../components';
import { Trans as T } from 'react-i18next';

export const TableComponent = ({ cols, items, loading, investorId, orgId }) => (
  <Fragment>
    <Table size="sm" striped responsive>
      <TableHead cols={cols} />
      {items && (items.size > 0 || items.length > 0) && !loading && (
        <TableBody>
          {items.map((item, index) => (
            <tr key={item.id}>
              {cols.map(({ key, CellComponent }) => (
                <TCell key={key}>
                  {CellComponent ? (
                    <CellComponent
                      itemIndex={index}
                      investorId={investorId}
                      orgId={orgId}
                      {...item}
                    />
                  ) : (
                    item[key]
                  )}
                </TCell>
              ))}
            </tr>
          ))}
        </TableBody>
      )}
      {loading && <TableLoader colSpan={cols.length} />}
    </Table>
    {items && (items.size === 0 || items.length === 0) && !loading && (
      <p className="no-data-p">
        <T i18nKey="No data" />
      </p>
    )}
  </Fragment>
);
