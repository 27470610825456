import {
  createEntityValidator,
  generateCodeForSlice,
  generateSlice,
  SLICE_CODE_FOR
} from '../helpers';
import { nullValidator, textLengthValidator } from '../../validators/common';
import i18n from '../../i18n';

const newsValidation = createEntityValidator({
  headline: [nullValidator()],
  articlePreview: [nullValidator(), textLengthValidator({ maxLength: 300 })],
  article: [
    nullValidator(),
    async value => {
      if (value && value.length <= 132) {
        throw i18n.t(`Should not be empty`);
      }
      return value;
    }
  ]
});

export const { generatedInitialState, generatedExtraReducers, createNews } = generateCodeForSlice(
  { entityName: 'News', entityValidation: newsValidation },
  [SLICE_CODE_FOR.CREATE]
);

export const { newsSlice, loadNews, editNews, deleteNews } = generateSlice(
  { entityName: 'News', rootName: 'news', entityValidation: newsValidation },
  [SLICE_CODE_FOR.LOAD, SLICE_CODE_FOR.CREATE, SLICE_CODE_FOR.EDIT, SLICE_CODE_FOR.DELETE]
)({
  initialState: generatedInitialState,
  extraReducers: {
    ...generatedExtraReducers,
    [createNews.fulfilled]: (state, { payload }) => {
      state.createLoading = false;
      if (state.items) {
        state.items.splice(0, 0, payload);
      } else {
        state.items = [payload];
      }
      state.createError = null;
    }
  }
});

export default newsSlice.reducer;
