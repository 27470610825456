export const commitmentConstants = {
  COMMITMENT_CREATE_LOADING: 'COMMITMENT_CREATE_LOADING',
  COMMITMENT_CREATE_SUCCESS: 'COMMITMENT_CREATE_SUCCESS',
  COMMITMENT_CREATE_FAILURE: 'COMMITMENT_CREATE_FAILURE',
  COMMITMENT_ERROR_RELOAD: 'COMMITMENT_ERROR_RELOAD',

  COMMITMENT_LIST_LOADING: 'COMMITMENT_LIST_LOADING',
  COMMITMENT_LIST_SUCCESS: 'COMMITMENT_LIST_SUCCESS',
  COMMITMENT_LIST_FAILURE: 'COMMITMENT_LIST_FAILURE',

  COMMITMENT_EDIT_LOADING: 'COMMITMENT_EDIT_LOADING',
  COMMITMENT_EDIT_SUCCESS: 'COMMITMENT_EDIT_SUCCESS',
  COMMITMENT_EDIT_FAILURE: 'COMMITMENT_EDIT_FAILURE',

  COMMITMENT_DELETE_LOADING: 'COMMITMENT_DELETE_LOADING',
  COMMITMENT_DELETE_SUCCESS: 'COMMITMENT_DELETE_SUCCESS',
  COMMITMENT_DELETE_FAILURE: 'COMMITMENT_DELETE_FAILURE'
};
