import { userConstants } from '../constants';

export function registration(state = {}, action) {
  switch (action.type) {
    case userConstants.REGISTER_REQUEST:
      return { loading: true };
    case userConstants.LOGIN_SUCCESS:
      return {};
    case userConstants.REGISTER_FAILURE:
      return { error: action.error };
    default:
      return state;
  }
}
