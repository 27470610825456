import { List } from 'immutable';
import { quickbooksMappingConstants } from '../constants';

export function quickbooks_mappings(state = {}, action) {
  switch (action.type) {
    case quickbooksMappingConstants.QUICKBOOKS_MAPPING_LIST_LOADING:
      return {
        ...state,
        listLoading: true
      };
    case quickbooksMappingConstants.QUICKBOOKS_MAPPING_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case quickbooksMappingConstants.QUICKBOOKS_MAPPING_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: List([]),
        listError: action.error
      };

    case quickbooksMappingConstants.QUICKBOOKS_MAPPING_LIST_CLEAR:
      return {
        ...state,
        items: null
      };

    case quickbooksMappingConstants.QUICKBOOKS_MAPPING_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case quickbooksMappingConstants.QUICKBOOKS_MAPPING_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      return {
        ...state,
        items: items.push(action.response),
        createLoading: false,
        createError: null
      };
    }
    case quickbooksMappingConstants.QUICKBOOKS_MAPPING_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };

    case quickbooksMappingConstants.QUICKBOOKS_MAPPING_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case quickbooksMappingConstants.QUICKBOOKS_MAPPING_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const quickbooksMappingIndex = state.items.findIndex(val => val.id == action.response.id);

      if (quickbooksMappingIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(quickbooksMappingIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case quickbooksMappingConstants.QUICKBOOKS_MAPPING_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case quickbooksMappingConstants.QUICKBOOKS_MAPPING_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case quickbooksMappingConstants.QUICKBOOKS_MAPPING_DELETE_SUCCESS: {
      const { quickbooksMappingId } = action;

      if (!state.items) {
        return state;
      }

      const quickbooksMappingIndex = state.items.findIndex(val => val.id == quickbooksMappingId);

      if (quickbooksMappingIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(quickbooksMappingIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case quickbooksMappingConstants.QUICKBOOKS_MAPPING_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };
    case quickbooksMappingConstants.QUICKBOOKS_MAPPING_ERROR_RELOAD:
      return {
        ...state,
        createError: null,
        editError: null
      };

    default:
      return state;
  }
}
