export const oppStageConstants = {
  OPP_STAGE_CREATE_LOADING: 'OPP_STAGE_CREATE_LOADING',
  OPP_STAGE_CREATE_SUCCESS: 'OPP_STAGE_CREATE_SUCCESS',
  OPP_STAGE_CREATE_FAILURE: 'OPP_STAGE_CREATE_FAILURE',
  OPP_STAGE_ERROR_RELOAD: 'OPP_STAGE_ERROR_RELOAD',

  OPP_STAGE_LIST_LOADING: 'OPP_STAGE_LIST_LOADING',
  OPP_STAGE_LIST_SUCCESS: 'OPP_STAGE_LIST_SUCCESS',
  OPP_STAGE_LIST_FAILURE: 'OPP_STAGE_LIST_FAILURE',

  OPP_STAGE_EDIT_LOADING: 'OPP_STAGE_EDIT_LOADING',
  OPP_STAGE_EDIT_SUCCESS: 'OPP_STAGE_EDIT_SUCCESS',
  OPP_STAGE_EDIT_FAILURE: 'OPP_STAGE_EDIT_FAILURE',

  OPP_STAGE_DELETE_LOADING: 'OPP_STAGE_DELETE_LOADING',
  OPP_STAGE_DELETE_SUCCESS: 'OPP_STAGE_DELETE_SUCCESS',
  OPP_STAGE_DELETE_FAILURE: 'OPP_STAGE_DELETE_FAILURE',

  OPP_STAGE_SALESFORCE_LIST_LOADING: 'OPP_STAGE_SALESFORCE_LIST_LOADING',
  OPP_STAGE_SALESFORCE_LIST_SUCCESS: 'OPP_STAGE_SALESFORCE_LIST_SUCCESS',
  OPP_STAGE_SALESFORCE_LIST_FAILURE: 'OPP_STAGE_SALESFORCE_LIST_FAILURE',

  OPP_STAGE_PIPEDRIVE_LIST_LOADING: 'OPP_STAGE_PIPEDRIVE_LIST_LOADING',
  OPP_STAGE_PIPEDRIVE_LIST_SUCCESS: 'OPP_STAGE_PIPEDRIVE_LIST_SUCCESS',
  OPP_STAGE_PIPEDRIVE_LIST_FAILURE: 'OPP_STAGE_PIPEDRIVE_LIST_FAILURE',

  OPP_STAGE_INSIGHTLY_LIST_LOADING: 'OPP_STAGE_INSIGHTLY_LIST_LOADING',
  OPP_STAGE_INSIGHTLY_LIST_SUCCESS: 'OPP_STAGE_INSIGHTLY_LIST_SUCCESS',
  OPP_STAGE_INSIGHTLY_LIST_FAILURE: 'OPP_STAGE_INSIGHTLY_LIST_FAILURE'
};
