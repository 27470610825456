import { List } from 'immutable';
import { fundDirectorConstants } from '../constants';

export function fund_directors(state = {}, action) {
  switch (action.type) {
    case fundDirectorConstants.FUND_DIRECTOR_LIST_LOADING:
      return {
        ...state,
        listLoading: true
      };
    case fundDirectorConstants.FUND_DIRECTOR_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case fundDirectorConstants.FUND_DIRECTOR_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: [],
        listError: action.error
      };
    case fundDirectorConstants.FUND_DIRECTOR_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case fundDirectorConstants.FUND_DIRECTOR_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      return {
        ...state,
        items: items.push(action.response),
        createLoading: false,
        createError: null
      };
    }
    case fundDirectorConstants.FUND_DIRECTOR_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };

    case fundDirectorConstants.FUND_DIRECTOR_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case fundDirectorConstants.FUND_DIRECTOR_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const fundDirectorIndex = state.items.findIndex(val => val.id == action.response.id);

      if (fundDirectorIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(fundDirectorIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case fundDirectorConstants.FUND_DIRECTOR_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case fundDirectorConstants.FUND_DIRECTOR_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case fundDirectorConstants.FUND_DIRECTOR_DELETE_SUCCESS: {
      const { fundDirectorId } = action;

      if (!state.items) {
        return state;
      }

      const fundDirectorIndex = state.items.findIndex(val => val.id == fundDirectorId);

      if (fundDirectorIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(fundDirectorIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case fundDirectorConstants.FUND_DIRECTOR_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };
    case fundDirectorConstants.FUND_DIRECTOR_ERROR_RELOAD:
      return {
        ...state,
        createError: null,
        editError: null
      };

    default:
      return state;
  }
}
