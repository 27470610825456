class ConsoleLogger {
  log(message) {
    console.log(message);
  }

  error(e) {
    console.log(e);
    if (e.response) {
      console.error(`
      \n${e.config.method.toUpperCase()} ${e.config.url}
      \n${e.response.status}
      \n${e.response.data.message}
      \n${new Date()}
    `);
    } else {
      console.error(e);
    }
    console.trace('%c Stack trace for error above', 'color:#410e0b;background:#fcebeb;');
  }
}

export default new ConsoleLogger();
