import { List } from 'immutable';
import { directorConstants, passportConstants, utilityBillConstants } from '../constants';
import { history } from '../history';
import * as directorService from 'services/director.service';
import * as investorOnboardingItemActions from './investor_onboarding_item.actions';
import { nullValidator, emailValidator, validate } from '../validators/common';
import {
  formPassportAdd,
  formUtilityBillAdd,
  redirectAfterPassportOrUtilityBillEdit
} from './helpers';
import { createPassports, createUtilityBills } from './ubo.actions';
import { checkPreviousRecipient } from '../features/kyc/circulationContactsSlice';
import { getMessageFromError } from 'views/Admin/helpers';

export const directorValidation = async (firstName, lastName, title, email) => {
  const validateObject = {
    firstName,
    lastName,
    title,
    email
  };

  const validateConfig = {
    firstName: [nullValidator()],
    lastName: [nullValidator()],
    title: [nullValidator()],
    email: [nullValidator(), emailValidator()]
  };

  await validate(validateObject, validateConfig);
};

export function directorCreate(
  orgId,
  investorId,
  firstName,
  lastName,
  title,
  email,
  additionalEmail,
  current,
  sendNotices,
  mainRecipient,
  passports,
  utility_bills,
  cb
) {
  return async dispatch => {
    try {
      await directorValidation(firstName, lastName, title, email);

      const createdPassports = await createPassports(
        passports,
        orgId,
        directorConstants.DIRECTOR_CREATE_FORM_PASSPORT_DELETE,
        dispatch
      );
      const createdUtilityBills = await createUtilityBills(
        utility_bills,
        orgId,
        directorConstants.DIRECTOR_CREATE_FORM_UTILITY_BILL_DELETE,
        dispatch
      );

      dispatch({ type: directorConstants.DIRECTOR_CREATE_LOADING });

      const response = await directorService.create(
        orgId,
        investorId,
        firstName,
        lastName,
        title,
        email,
        additionalEmail,
        current,
        sendNotices,
        mainRecipient,
        createdPassports,
        createdUtilityBills
      );

      dispatch({ type: directorConstants.DIRECTOR_CREATE_SUCCESS, response });
      dispatch(checkPreviousRecipient(response.previousRecipient));
      dispatch(investorOnboardingItemActions.listInvestorOnboardingItems(orgId));

      if (cb) {
        cb();
      }
    } catch (error) {
      dispatch({ type: directorConstants.DIRECTOR_CREATE_FAILURE, error });
    }
  };
}

export function listDirectors(orgId) {
  return async dispatch => {
    try {
      dispatch({ type: directorConstants.DIRECTOR_LIST_LOADING });

      const response = await directorService.listDirectors(orgId);

      dispatch({ type: directorConstants.DIRECTOR_LIST_SUCCESS, response });
    } catch (error) {
      dispatch({ type: directorConstants.DIRECTOR_LIST_FAILURE, error });
    }
  };
}

export function directorEdit(
  orgId,
  directorId,
  investorId,
  firstName,
  lastName,
  title,
  email,
  additionalEmail,
  current,
  sendNotices,
  mainRecipient,
  passportsOld,
  passportsNew,
  utilityBillsOld,
  utilityBillsNew
) {
  return async dispatch => {
    try {
      await directorValidation(firstName, lastName, title, email);

      const createdPassports = await createPassports(
        passportsNew,
        orgId,
        directorConstants.DIRECTOR_EDIT_FORM_PASSPORT_DELETE,
        dispatch
      );
      const passports = List(createdPassports).concat(passportsOld);

      const createdUtilityBills = await createUtilityBills(
        utilityBillsNew,
        orgId,
        directorConstants.DIRECTOR_EDIT_FORM_UTILITY_BILL_DELETE,
        dispatch
      );
      const utilityBills = List(createdUtilityBills).concat(utilityBillsOld);

      dispatch({ type: directorConstants.DIRECTOR_EDIT_LOADING });

      const response = await directorService.edit(
        orgId,
        directorId,
        investorId,
        firstName,
        lastName,
        title,
        email,
        additionalEmail,
        current,
        sendNotices,
        mainRecipient,
        passports,
        utilityBills
      );

      dispatch({ type: directorConstants.DIRECTOR_EDIT_SUCCESS, response });
      dispatch(checkPreviousRecipient(response.previousRecipient));
      dispatch(investorOnboardingItemActions.listInvestorOnboardingItems(orgId));
      history.push(`/kyc/${investorId}`);
    } catch (error) {
      dispatch({ type: directorConstants.DIRECTOR_EDIT_FAILURE, error });
      throw getMessageFromError(error);
    }
  };
}

export function directorDelete(orgId, directorId) {
  return async dispatch => {
    try {
      dispatch({ type: directorConstants.DIRECTOR_DELETE_LOADING });

      await directorService.deleteDirector(orgId, directorId);

      dispatch({ type: directorConstants.DIRECTOR_DELETE_SUCCESS, directorId });
      dispatch(investorOnboardingItemActions.listInvestorOnboardingItems(orgId));
    } catch (error) {
      dispatch({ type: directorConstants.DIRECTOR_DELETE_FAILURE, error });
      throw getMessageFromError(error);
    }
  };
}

export function reloadDirectorError() {
  return async dispatch => {
    dispatch({ type: directorConstants.DIRECTOR_ERROR_RELOAD });
  };
}

export function directorCreateFormPassportAddAction(
  orgId,
  passportNumber,
  country,
  issuedOn,
  validUntil,
  fileId,
  isPrimary,
  onSuccess,
  directorId
) {
  return async dispatch => {
    await formPassportAdd(
      orgId,
      passportNumber,
      country,
      issuedOn,
      validUntil,
      fileId,
      isPrimary,
      directorId,
      onSuccess,
      dispatch,
      directorConstants.DIRECTOR_CREATE_FORM_PASSPORT_ADD
    );
  };
}

export function directorCreateFormPassportEditAction(passport) {
  return async dispatch => {
    dispatch({
      type: directorConstants.DIRECTOR_CREATE_FORM_PASSPORT_EDIT,
      payload: passport
    });
    redirectAfterPassportOrUtilityBillEdit(passport.uboId, passport.directorId);
  };
}

export function directorCreateFormPassportDeleteAction(passportId) {
  return async dispatch => {
    dispatch({
      type: directorConstants.DIRECTOR_CREATE_FORM_PASSPORT_DELETE,
      payload: passportId
    });
  };
}

export function directorCreateFormUtilityBillAddAction(
  orgId,
  date,
  residenceAddress,
  type,
  fileId,
  isPrimary,
  onSuccess,
  directorId
) {
  return async dispatch => {
    await formUtilityBillAdd(
      orgId,
      date,
      residenceAddress,
      type,
      fileId,
      isPrimary,
      onSuccess,
      directorId,
      dispatch,
      directorConstants.DIRECTOR_CREATE_FORM_UTILITY_BILL_ADD
    );
  };
}

export function directorCreateFormUtilityBillEditAction(utilityBill) {
  return async dispatch => {
    dispatch({
      type: directorConstants.DIRECTOR_CREATE_FORM_UTILITY_BILL_EDIT,
      payload: utilityBill
    });
    redirectAfterPassportOrUtilityBillEdit(utilityBill.uboId, utilityBill.directorId);
  };
}

export function directorCreateFormUtilityBillDeleteAction(utilityBillId) {
  return async dispatch => {
    dispatch({
      type: directorConstants.DIRECTOR_CREATE_FORM_UTILITY_BILL_DELETE,
      payload: utilityBillId
    });
  };
}

export function directorEditFormPassportAddAction(
  orgId,
  passportNumber,
  country,
  issuedOn,
  validUntil,
  fileId,
  isPrimary,
  onSuccess,
  directorId
) {
  return async dispatch => {
    await formPassportAdd(
      orgId,
      passportNumber,
      country,
      issuedOn,
      validUntil,
      fileId,
      isPrimary,
      directorId,
      onSuccess,
      dispatch,
      directorConstants.DIRECTOR_EDIT_FORM_PASSPORT_ADD
    );
  };
}

export function directorEditFormPassportEditAction(passport) {
  return async dispatch => {
    dispatch({
      type: directorConstants.DIRECTOR_EDIT_FORM_PASSPORT_EDIT,
      payload: passport
    });
    redirectAfterPassportOrUtilityBillEdit(passport.uboId, passport.directorId);
  };
}

export function directorEditFormPassportDeleteAction(passportId) {
  return async dispatch => {
    dispatch({
      type: directorConstants.DIRECTOR_EDIT_FORM_PASSPORT_DELETE,
      payload: passportId
    });
  };
}

export function directorEditFormResetPassports() {
  return async dispatch => {
    dispatch({ type: directorConstants.DIRECTOR_EDIT_FORM_RESET_PASSPORTS });
  };
}

export function directorEditFormUtilityBillAddAction(
  orgId,
  date,
  residenceAddress,
  type,
  fileId,
  isPrimary,
  onSuccess,
  directorId
) {
  return async dispatch => {
    await formUtilityBillAdd(
      orgId,
      date,
      residenceAddress,
      type,
      fileId,
      isPrimary,
      onSuccess,
      directorId,
      dispatch,
      directorConstants.DIRECTOR_EDIT_FORM_UTILITY_BILL_ADD
    );
  };
}

export function directorEditFormUtilityBillEditAction(passport) {
  return async dispatch => {
    dispatch({
      type: directorConstants.DIRECTOR_EDIT_FORM_UTILITY_BILL_EDIT,
      payload: passport
    });
    redirectAfterPassportOrUtilityBillEdit(passport.uboId, passport.directorId);
  };
}

export function directorEditFormUtilityBillDeleteAction(passportId) {
  return async dispatch => {
    dispatch({
      type: directorConstants.DIRECTOR_EDIT_FORM_UTILITY_BILL_DELETE,
      payload: passportId
    });
  };
}

export function directorEditFormResetUtilityBills() {
  return async dispatch => {
    dispatch({ type: directorConstants.DIRECTOR_EDIT_FORM_RESET_UTILITY_BILLS });
  };
}

export function copyDirector({ orgId, itemId, investorId }) {
  return async dispatch => {
    try {
      const response = await directorService.copyDirector(orgId, itemId, investorId);

      const { utility_bills, passports } = response;
      for (const utilityBill of utility_bills) {
        dispatch({ type: utilityBillConstants.UTILITY_BILL_CREATE_SUCCESS, response: utilityBill });
      }
      for (const passport of passports) {
        dispatch({ type: passportConstants.PASSPORT_CREATE_SUCCESS, response: passport });
      }

      dispatch({
        type: directorConstants.DIRECTOR_CREATE_SUCCESS,
        response: {
          ...response,
          utility_bills: response.utility_bills.map(c => c.id),
          passports: response.passports.map(c => c.id)
        }
      });
    } catch (error) {
      throw getMessageFromError(error);
    }
  };
}
