import { List } from 'immutable';
import { companyDocConstants } from '../constants';

export function companyDocs(state = {}, action) {
  switch (action.type) {
    case companyDocConstants.COMPANY_DOC_LIST_LOADING:
      return {
        ...state,
        listLoading: true
      };
    case companyDocConstants.COMPANY_DOC_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case companyDocConstants.COMPANY_DOC_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: [],
        listError: action.error
      };
    case companyDocConstants.COMPANY_DOC_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case companyDocConstants.COMPANY_DOC_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      return {
        ...state,
        items: items.push(action.response),
        createLoading: false,
        createError: null
      };
    }
    case companyDocConstants.COMPANY_DOC_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };

    case companyDocConstants.COMPANY_DOC_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case companyDocConstants.COMPANY_DOC_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const companyDocIndex = state.items.findIndex(val => val.id == action.response.id);

      if (companyDocIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(companyDocIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case companyDocConstants.COMPANY_DOC_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case companyDocConstants.COMPANY_DOC_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case companyDocConstants.COMPANY_DOC_DELETE_SUCCESS: {
      const { companyDocId } = action;

      if (!state.items) {
        return state;
      }

      const companyDocIndex = state.items.findIndex(val => val.id == companyDocId);

      if (companyDocIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(companyDocIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case companyDocConstants.COMPANY_DOC_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };
    case companyDocConstants.COMPANY_DOC_ERROR_RELOAD:
      return {
        ...state,
        createError: null,
        editError: null
      };

    default:
      return state;
  }
}
