import { passportValidation } from './passport.actions';
import { passportConstants, utilityBillConstants } from '../constants';
import { getUniqueIdentifier } from '../views/KYC/helpers';
import { history } from '../history';
import { utilityBillValidation } from './utility_bill.actions';

export const formPassportAdd = async (
  orgId,
  passportNumber,
  country,
  issuedOn,
  validUntil,
  fileId,
  isPrimary,
  entityId,
  onSuccess,
  dispatch,
  addActionType
) => {
  try {
    await passportValidation(passportNumber, issuedOn, validUntil);
    dispatch({
      type: addActionType,
      payload: {
        id: getUniqueIdentifier(),
        passportNumber,
        country,
        issuedOn,
        validUntil,
        fileId,
        isPrimary,
        entityId
      }
    });
    dispatch({ type: passportConstants.PASSPORT_ERROR_RELOAD });

    if (onSuccess) onSuccess();
  } catch (error) {
    dispatch({ type: passportConstants.PASSPORT_CREATE_FAILURE, error });
  }
};

export const formUtilityBillAdd = async (
  orgId,
  date,
  residenceAddress,
  type,
  fileId,
  isPrimary,
  onSuccess,
  entityId,
  dispatch,
  addActionType
) => {
  try {
    await utilityBillValidation(date, residenceAddress);

    dispatch({
      type: addActionType,
      payload: {
        id: getUniqueIdentifier(),
        date,
        residenceAddress,
        type,
        fileId,
        isPrimary,
        entityId
      }
    });

    dispatch({ type: utilityBillConstants.UTILITY_BILL_ERROR_RELOAD });

    if (onSuccess) onSuccess();
  } catch (error) {
    dispatch({ type: utilityBillConstants.UTILITY_BILL_CREATE_FAILURE, error });
  }
};

export const redirectAfterPassportOrUtilityBillEdit = (uboId, directorId, contactId) => {
  if (uboId) {
    history.push(`/kyc/ubos/${uboId}/edit`);
  } else if (directorId) {
    history.push(`/kyc/directors/${directorId}/edit`);
  } else if (contactId) {
    history.push(`/kyc/circulation_contacts/${contactId}/edit`);
  } else {
    history.push('/kyc');
  }
};

export const errorHandler = (error, possibleErrors) => {
  let changedError = error;
  if (error.response) {
    const currentPossibleError = possibleErrors.find(
      possibleError => possibleError.message === error.response.data.message
    );
    if (currentPossibleError) {
      changedError = {
        type: 'validation',
        fields: { [currentPossibleError.field]: currentPossibleError.message }
      };
    }
  }

  return changedError;
};
