import { List } from 'immutable';
import { qlikSenseConstants } from '../constants/qlik_sense.constants';

export function qlik_sense(state = {}, action) {
  switch (action.type) {
    case qlikSenseConstants.QLIK_SENSE_LIST_LOADING:
      return {
        ...state,
        listLoading: true
      };
    case qlikSenseConstants.QLIK_SENSE_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case qlikSenseConstants.QLIK_SENSE_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: [],
        listError: action.error
      };
    case qlikSenseConstants.QLIK_SENSE_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case qlikSenseConstants.QLIK_SENSE_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      return {
        ...state,
        items: items.push(action.response),
        createLoading: false,
        createError: null
      };
    }
    case qlikSenseConstants.QLIK_SENSE_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };

    case qlikSenseConstants.QLIK_SENSE_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case qlikSenseConstants.QLIK_SENSE_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const embedIndex = state.items.findIndex(val => val.id === action.response.id);

      if (embedIndex === -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(embedIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case qlikSenseConstants.QLIK_SENSE_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case qlikSenseConstants.QLIK_SENSE_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case qlikSenseConstants.QLIK_SENSE_DELETE_SUCCESS: {
      const { embedId } = action;

      if (!state.items) {
        return state;
      }

      const embedIndex = state.items.findIndex(val => val.id === embedId);

      if (embedIndex === -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(embedIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case qlikSenseConstants.QLIK_SENSE_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };
    case qlikSenseConstants.QLIK_SENSE_ERROR_RELOAD:
      return {
        ...state,
        createError: null,
        editError: null,
        deleteError: null
      };
    default:
      return state;
  }
}
