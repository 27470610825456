import * as Sentry from '@sentry/browser';
import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { org } from './org.reducer';
import { orgs } from './orgs.reducer';
import { registration } from './registration.reducer';
import { forgot } from './forgot.reducer';
import { funds } from './funds.reducer';
import { companies } from './companies.reducer';
import { investments } from './investments.reducer';
import { investment_rounds } from './investment_rounds.reducer';
import { klips } from './klips.reducer';
import { opps } from './opps.reducer';
import { fundMetrics } from './fundmetrics.reducer';
import { investors } from './investors.reducer';
import { commitments } from './commitments.reducer';
import { commitment_records } from './commitment_records.reducer';
import { fundMetric_records } from './fundmetric_records.reducer';
import { team } from './team.reducer';
import { company_valuations } from './company_valuations.reducer';
import { fundDocs } from './fund_docs.reducer';
import { companyDocs } from './company_docs.reducer';
import { investorDocs } from './investor_docs.reducer';
import { investmentDocs } from './investment_docs.reducer';
import { user_profile } from './user_profile.reducer';
import { dividends } from './dividends.reducer';
import { escrows } from './escrows.reducer';
import { management_fees } from './management_fees.reducer';
import { subscription_fees } from './subscription_fees.reducer';
import { investment_transactions } from './investment_transactions.reducer';
import { providers } from './providers.reducer';
import { expenses } from './expenses.reducer';
import { xero_companies } from './xero_companies.reducer';
import { xero_mappings } from './xero_mappings.reducer';
import { secondary_deals } from './secondary_deals.reducer';
import { secondary_line_items } from './secondary_line_items.reducer';
import { loans_received } from './loans_received.reducer';
import { forecasted_dividends } from './forecasted_dividends.reducer';
import { debt_valuations } from './debt_valuations.reducer';
import { convertible_valuations } from './convertible_valuations.reducer';
import { directors } from './directors.reducer';
import { ubos } from './ubos.reducer';
import { passports } from './passports.reducer';
import { userConstants } from '../constants';
import { utility_bills } from './utility_bills.reducer';
import { source_of_wealth_proofs } from './source_of_wealth_proofs.reducer';
import { bank_accounts } from './bank_accounts.reducer';
import { kyc_companies } from './kyc_companies.reducer';
import { kyc_company_documents } from './kyc_company_documents.reducer';
import { onboarding_processes } from './onboarding_processes.reducer';
import { onboarding_items } from './onboarding_items.reducer';
import { commitmentDocs } from './commitment_docs.reducer';
import { docusign } from './docusign.reducer';
import { fund_directors } from './fund_directors.reducer';
import { kyc } from './kyc.reducer';
import { crunchbase } from './crunchbase.reducer';
import { apps } from './apps.reducer';
import { app_connections } from './app_connections.reducer';
import { investor_types } from './investor_types.reducer';
import { service_provider_agreements } from './service_provider_agreements.reducer';
import { company_doc_types } from './company_doc_types.reducer';
import { investor_doc_types } from './investor_doc_types.reducer';
import { fund_doc_types } from './fund_doc_types.reducer';
import { industries } from './industries.reducer';
import { opp_stages } from './opp_stages.reducer';
import { founders } from './founders.reducer';
import { share_classes } from './share_classes.reducer';
import { shareholders } from './shareholders.reducer';
import { comparable_transactions } from './comparable_transactions.reducer';
import { comparable_companies } from './comparable_companies.reducer';
import { investor_groups } from './investor_groups.reducer';
import { leads } from './leads.reducer';
import { lead_custom_fields } from './lead_custom_fields.reducer';
import { lead_filters } from './lead_filters.reducer';
import { lead_filter_groups } from './lead_filter_groups.reducer';
import { lead_statuses } from './lead_statuses.reducer';
import { managers } from './managers.reducer';
import { board_members } from './board_members.reducer';
import { company_financials } from './company_financials.reducer';
import { valuation_policies } from './valuation_policies.reducer';
import { portfolio_company_connections } from './portfolio_company_connections.reducer';
import { xero_contacts } from './xero_contacts.reducer';
import { xero_tenants } from './xero_tenants.reducer';
import { embeds } from './embeds.reducer';
import { investor_onboarding_items } from './investor_onboarding_items.reducer';
import { languages } from './language.reducer';
import { sentry } from './sentry.reducer';
import { qlik_sense } from './qlik_sense.reducer';
import { billing } from './billing.reducer';
import { tableau } from './tableau.reducer';
import { google_sheets_company_connections } from './google_sheets_company_connections.reducer';
import { cap_tables } from './cap_tables.reducer';
import { cap_table_records } from './cap_table_records.reducer';
import { tables_filters_values } from './tables_filters_values.reducer';
import { quickbooks_realms } from './quickbooks_realms.reducer';
import { quickbooks_companies } from './quickbooks_companies.reducer';
import { quickbooks_contacts } from './quickbooks_contacts.reducer';
import { quickbooks_mappings } from './quickbooks_mappings.reducer';
import { investment_wizard } from './investment_wizard.reducer';
import { opp_custom_fields } from './opp_custom_fields.reducer';
import { table_properties } from './table_properties.reducer';
import { marketplaceReducer } from '../features/marketplace/reducer';
import circulationContactsReducer from '../features/kyc/circulationContactsSlice';
import taxResidentsReducer from '../features/kyc/taxResidentsSlice';
import sessionsHistoryReducer from '../features/settings/sessionsHistorySlice';
import drawdownsDueReducer from '../features/accounting/drawdownsDueSlice';
import drawdownsReducer from '../features/accounting/drawdownsSlice';
import distributionsReducer from '../features/accounting/distributionsSlice';
import newsReducer from '../features/settings/newsSlice';
import paginationReducer from '../features/settings/paginationSlice';
import investorsSettingsReducer from '../features/settings/investorsSettingsSlice';
import notificationRecipientsReducer from '../features/emailNotifications/notificationRecipientsSlice';
import emailNotificationsReducer from '../features/emailNotifications/emailNotificationsSlice';
import emailHistoryReducer from '../features/emailNotifications/emailHistorySlice';
import logger from 'views/logger';

function rootReducer(state, action) {
  if (action.type === userConstants.LOGOUT) {
    logger.log('detaching sentry context');
    Sentry.configureScope(function(scope) {
      scope.setUser({});
    });

    return {
      authentication: {}
    };
  }

  const intermediateState = org(state, action);
  const finalState = combinedReducer(intermediateState, action);

  return finalState;
}

const combinedReducer = combineReducers({
  org: (state = {}) => state, // hack, to pass through the org state
  authentication,
  app_connections,
  apps,
  bank_accounts,
  board_members,
  billing,
  cap_table_records,
  cap_tables,
  circulationContacts: circulationContactsReducer,
  commitmentDocs,
  companies,
  companyDocs,
  company_doc_types,
  company_financials,
  company_valuations,
  comparable_companies,
  comparable_transactions,
  commitments,
  commitment_records,
  convertible_valuations,
  crunchbase,
  debt_valuations,
  directors,
  distributions: distributionsReducer,
  dividends,
  docusign,
  drawdowns: drawdownsReducer,
  drawdownsDue: drawdownsDueReducer,
  emailNotifications: emailNotificationsReducer,
  emailHistory: emailHistoryReducer,
  embeds,
  escrows,
  expenses,
  forgot,
  forecasted_dividends,
  founders,
  fundDocs,
  fundMetrics,
  fundMetric_records,
  fund_directors,
  fund_doc_types,
  funds,
  google_sheets_company_connections,
  industries,
  investmentDocs,
  investment_rounds,
  investment_transactions,
  investment_wizard,
  investments,
  investorDocs,
  investor_doc_types,
  investor_groups,
  investor_onboarding_items,
  investor_types,
  investors,
  investorsSettings: investorsSettingsReducer,
  klips,
  kyc,
  kyc_companies,
  kyc_company_documents,
  languages,
  lead_custom_fields,
  lead_filter_groups,
  lead_filters,
  lead_statuses,
  leads,
  loans_received,
  management_fees,
  managers,
  marketplaceDeals: marketplaceReducer,
  news: newsReducer,
  notificationRecipients: notificationRecipientsReducer,
  onboarding_items,
  onboarding_processes,
  opp_custom_fields,
  opp_stages,
  opps,
  orgs,
  pagination: paginationReducer,
  passports,
  portfolio_company_connections,
  providers,
  qlik_sense,
  quickbooks_companies,
  quickbooks_contacts,
  quickbooks_mappings,
  quickbooks_realms,
  registration,
  secondary_deals,
  secondary_line_items,
  sentry,
  service_provider_agreements,
  sessionsHistory: sessionsHistoryReducer,
  shareholders,
  share_classes,
  source_of_wealth_proofs,
  subscription_fees,
  tableau,
  table_properties,
  tables_filters_values,
  taxResidents: taxResidentsReducer,
  team,
  ubos,
  user_profile,
  utility_bills,
  valuation_policies,
  xero_companies,
  xero_contacts,
  xero_mappings,
  xero_tenants
});

export default rootReducer;
