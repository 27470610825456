import { userConstants } from '../constants';
import * as userService from 'services/user.service';
import { history } from '../history';
import { nullValidator, emailValidator, validate } from '../validators/common';
import i18n from '../i18n';
import logger from 'views/logger';

export function login(username, password, t) {
  return async dispatch => {
    try {
      const validateObject = {
        username,
        password
      };

      const validateConfig = {
        username: [nullValidator(), emailValidator()],
        password: [nullValidator()]
      };

      await validate(validateObject, validateConfig);

      dispatch({ type: userConstants.LOGIN_REQUEST });

      const response = await userService.loginByCreds(username, password);

      const userLanguage = response.user.public_profile.language;
      i18n.changeLanguage(userLanguage);

      dispatch({ type: userConstants.LOGIN_SUCCESS, response });
      history.push('/');
    } catch (error) {
      let err = error;
      if (error.response && error.response.status === 404) {
        err = {
          fields: { username: t('Invalid credentials'), password: t('Invalid credentials') },
          type: 'validation'
        };
      }
      dispatch({ type: userConstants.LOGIN_FAILURE, error: err });
    }
  };
}

export function validateToken(token, onSuccess) {
  return async dispatch => {
    try {
      dispatch({ type: userConstants.LOGIN_REQUEST });

      const response = await userService.validateToken(token);

      dispatch({ type: userConstants.LOGIN_SUCCESS, response });
      if (onSuccess) onSuccess();
    } catch (error) {
      dispatch({ type: userConstants.VALIDATE_FAILURE, error });
      localStorage.removeItem('token');
      history.push('/login');
    }
  };
}

export function updateMemberships(token) {
  return async dispatch => {
    try {
      const { user } = await userService.validateToken(token);

      dispatch({ type: userConstants.MEMBERSHIPS_SUCCESS, memberships: user.memberships });
    } catch (error) {
      logger.error(error);
    }
  };
}

export function logout(loginUrl = '/login', withoutRequestForAllLogout = false) {
  return async dispatch => {
    try {
      const currentOrgId = localStorage.getItem('orgId');
      await userService.logout(withoutRequestForAllLogout);
      if (currentOrgId === 'f9e9e30a-7dff-4734-93a4-356b5b436311') {
        history.push('/login?org=DealAnswer');
      } else {
        history.push(loginUrl);
      }

      dispatch({ type: userConstants.LOGOUT });
    } catch (e) {
      logger.error(e);
    }
  };
}

export function register(user) {
  return async dispatch => {
    try {
      dispatch({ type: userConstants.REGISTER_REQUEST, user });

      const response = await userService.register(user);

      dispatch({ type: userConstants.LOGIN_SUCCESS, response });
      history.push('/');
    } catch (error) {
      dispatch({ type: userConstants.REGISTER_FAILURE, error });
    }
  };
}

export function editProfile(
  firstName,
  lastName,
  phone,
  linkedin,
  fileId,
  downloadLink,
  language,
  fromOrgSettings
) {
  return async dispatch => {
    try {
      const validateObject = {
        firstName,
        lastName
      };

      const validateConfig = {
        firstName: [nullValidator()],
        lastName: [nullValidator()]
      };

      await validate(validateObject, validateConfig);

      dispatch({ type: userConstants.PROFILE_EDIT_LOADING });

      const response = await userService.editProfile(
        firstName,
        lastName,
        phone,
        linkedin,
        fileId,
        downloadLink,
        language
      );

      dispatch({ type: userConstants.PROFILE_EDIT_SUCCESS, response });
      !fromOrgSettings && history.push('/');
    } catch (error) {
      dispatch({ type: userConstants.PROFILE_EDIT_FAILURE, error });
    }
  };
}

export function reloadUserError() {
  return async dispatch => {
    dispatch({ type: userConstants.USER_ERROR_RELOAD });
  };
}

export function reloadAuthenticationError() {
  return async dispatch => {
    dispatch({ type: userConstants.AUTHENTICATION_ERROR_RELOAD });
  };
}
