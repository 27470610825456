import { List } from 'immutable';
import { xeroTenantsConstants } from '../constants';

export function xero_tenants(state = {}, action) {
  switch (action.type) {
    case xeroTenantsConstants.XERO_TENANTS_LIST_LOADING:
      return {
        ...state,
        listLoading: true
      };
    case xeroTenantsConstants.XERO_TENANTS_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case xeroTenantsConstants.XERO_TENANTS_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: [],
        listError: action.error
      };

    case xeroTenantsConstants.XERO_TENANTS_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case xeroTenantsConstants.XERO_TENANTS_DELETE_SUCCESS: {
      const xeroTenantsId = action.id;

      if (!state.items) {
        return state;
      }

      const xeroTenantsIndex = state.items.findIndex(val => val.id == xeroTenantsId);

      if (xeroTenantsIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(xeroTenantsIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case xeroTenantsConstants.XERO_TENANTS_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };

    default:
      return state;
  }
}
