import { createAsyncThunk } from '@reduxjs/toolkit';
import { generateSlice, getDefaultReducersForEntityEdit, SLICE_CODE_FOR } from '../helpers';
import api from 'components/api';
import { CustomError } from 'views/helpers';
import { editItemInitialState } from '../constants';

export const editInvestorSettings = createAsyncThunk(
  `investorsSettings/edit`,
  async ({ entity, orgId }, { rejectWithValue }) => {
    try {
      const response = await api.put(
        `/org/${orgId}/investor_settings/${entity.investorId}`,
        entity
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(new CustomError(error));
    }
  }
);

export const editAllInvestorsSettings = createAsyncThunk(
  `investorsSettings/editAll`,
  async ({ entity, orgId }, { rejectWithValue }) => {
    try {
      const response = await api.post(`/org/${orgId}/investor_settings`, entity);

      return response.data;
    } catch (error) {
      return rejectWithValue(new CustomError(error));
    }
  }
);

export const { investorsSettingsSlice, loadInvestorsSettings } = generateSlice(
  { entityName: 'InvestorsSetting', baseUrl: '/investor_settings' },
  [SLICE_CODE_FOR.LOAD]
)({
  initialState: { ...editItemInitialState },
  extraReducers: {
    // EDIT
    ...getDefaultReducersForEntityEdit(editInvestorSettings),
    [editInvestorSettings.fulfilled]: (state, { payload }) => {
      if (state.editLoading) state.editLoading = false;
      if (!state.items) return;

      const itemIndex = state.items.findIndex(val => val.investorId === payload.investorId);
      if (itemIndex === -1) return;
      state.items.splice(itemIndex, 1, payload);
      state.editError = null;
    },
    // EDIT ALL
    ...getDefaultReducersForEntityEdit(editAllInvestorsSettings),
    [editAllInvestorsSettings.fulfilled]: (state, { payload }) => {
      if (state.editLoading) state.editLoading = false;
      state.items = payload;
    }
  }
});

export default investorsSettingsSlice.reducer;
