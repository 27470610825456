import axios from 'axios';

import { axiosOptions } from './constants';

export async function create(
  orgId,
  investorId,
  firstName,
  lastName,
  title,
  email,
  additionalEmail,
  phone,
  sendNotices,
  mainRecipient,
  cvFileId,
  passports,
  utility_bills,
  source_of_wealth_proofs,
  taxResidents
) {
  const request = {
    investorId,
    firstName,
    lastName,
    title,
    email,
    additionalEmail,
    phone: phone || null,
    sendNotices,
    mainRecipient,
    cvFileId,
    passports: passports.map(c => c.id),
    utility_bills: utility_bills.map(c => c.id),
    source_of_wealth_proofs: source_of_wealth_proofs.map(c => c.id),
    tax_residents: taxResidents.map(c => c.id)
  };

  const res = await axios.post(`${process.env.API_URL}/org/${orgId}/ubo`, request, axiosOptions);

  return res.data;
}

export async function listUbos(orgId) {
  const request = null;

  const res = await axios.get(`${process.env.API_URL}/org/${orgId}/ubo`, request, axiosOptions);

  return res.data;
}

export async function edit(
  orgId,
  uboId,
  investorId,
  firstName,
  lastName,
  title,
  email,
  additionalEmail,
  phone,
  sendNotices,
  mainRecipient,
  cvFileId,
  passports,
  utility_bills,
  source_of_wealth_proofs,
  taxResidents
) {
  const request = {
    investorId,
    firstName,
    lastName,
    title,
    email,
    additionalEmail,
    phone,
    sendNotices,
    mainRecipient,
    cvFileId,
    passports: passports.map(c => c.id),
    utility_bills: utility_bills.map(c => c.id),
    source_of_wealth_proofs: source_of_wealth_proofs.map(c => c.id),
    tax_residents: taxResidents.map(c => c.id)
  };

  const res = await axios.put(
    `${process.env.API_URL}/org/${orgId}/ubo/${uboId}`,
    request,
    axiosOptions
  );

  return res.data;
}

export async function deleteUbo(orgId, uboId) {
  const request = null;

  const res = await axios.delete(
    `${process.env.API_URL}/org/${orgId}/ubo/${uboId}`,
    request,
    axiosOptions
  );

  return res.data;
}

export async function copyUbo(orgId, uboId, investorId) {
  const res = await axios.post(
    `${process.env.API_URL}/org/${orgId}/copy_ubo`,
    { userInvestorId: uboId, investorId },
    axiosOptions
  );

  return res.data;
}
