export const leadFilterConstants = {
  LEAD_FILTER_CREATE_LOADING: 'LEAD_FILTER_CREATE_LOADING',
  LEAD_FILTER_CREATE_SUCCESS: 'LEAD_FILTER_CREATE_SUCCESS',
  LEAD_FILTER_CREATE_FAILURE: 'LEAD_FILTER_CREATE_FAILURE',
  LEAD_FILTER_ERROR_RELOAD: 'LEAD_FILTER_ERROR_RELOAD',

  LEAD_FILTER_LIST_LOADING: 'LEAD_FILTER_LIST_LOADING',
  LEAD_FILTER_LIST_SUCCESS: 'LEAD_FILTER_LIST_SUCCESS',
  LEAD_FILTER_LIST_FAILURE: 'LEAD_FILTER_LIST_FAILURE',

  LEAD_FILTER_EDIT_LOADING: 'LEAD_FILTER_EDIT_LOADING',
  LEAD_FILTER_EDIT_SUCCESS: 'LEAD_FILTER_EDIT_SUCCESS',
  LEAD_FILTER_EDIT_FAILURE: 'LEAD_FILTER_EDIT_FAILURE',

  LEAD_FILTER_DELETE_LOADING: 'LEAD_FILTER_DELETE_LOADING',
  LEAD_FILTER_DELETE_SUCCESS: 'LEAD_FILTER_DELETE_SUCCESS',
  LEAD_FILTER_DELETE_FAILURE: 'LEAD_FILTER_DELETE_FAILURE'
};
