import { List } from 'immutable';
import { capTableRecordConstants } from '../constants/cap_table_record.constants';

export function cap_table_records(state = {}, action) {
  switch (action.type) {
    case capTableRecordConstants.CAP_TABLE_RECORD_LIST_LOADING:
      return {
        ...state,
        listLoading: true
      };
    case capTableRecordConstants.CAP_TABLE_RECORD_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case capTableRecordConstants.CAP_TABLE_RECORD_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: [],
        listError: action.error
      };
    case capTableRecordConstants.CAP_TABLE_RECORD_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case capTableRecordConstants.CAP_TABLE_RECORD_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      return {
        ...state,
        items: items.push(action.response),
        createLoading: false,
        createError: null
      };
    }
    case capTableRecordConstants.CAP_TABLE_RECORD_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };

    case capTableRecordConstants.CAP_TABLE_RECORD_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case capTableRecordConstants.CAP_TABLE_RECORD_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const capTableRecordIndex = state.items.findIndex(val => val.id === action.response.id);

      if (capTableRecordIndex === -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(capTableRecordIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case capTableRecordConstants.CAP_TABLE_RECORD_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case capTableRecordConstants.CAP_TABLE_RECORD_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case capTableRecordConstants.CAP_TABLE_RECORD_DELETE_SUCCESS: {
      const { capTableRecordId } = action;

      if (!state.items) {
        return state;
      }

      const capTableRecordIndex = state.items.findIndex(val => val.id === capTableRecordId);

      if (capTableRecordIndex === -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(capTableRecordIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case capTableRecordConstants.CAP_TABLE_RECORD_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };
    case capTableRecordConstants.CAP_TABLE_RECORD_ERROR_RELOAD:
      return {
        ...state,
        createError: null,
        editError: null,
        deleteError: null
      };

    case capTableRecordConstants.CAP_TABLE_RECORD_ATTACH_FILE_LOADING: {
      const index = state.items.findIndex(val => val.id === action.capTableRecordId);

      return {
        ...state,
        items: state.items.set(index, { ...state.items.get(index), loading: true })
      };
    }

    case capTableRecordConstants.CAP_TABLE_RECORD_ATTACH_FILE_SUCCESS: {
      const index = state.items.findIndex(val => val.id === action.capTableRecordId);

      return {
        ...state,
        items: state.items.set(index, {
          ...state.items.get(index),
          fileId: action.fileId,
          downloadLink: action.downloadLink,
          loading: false
        })
      };
    }

    case capTableRecordConstants.CAP_TABLE_RECORD_ATTACH_FILE_FAILURE: {
      const index = state.items.findIndex(val => val.id === action.capTableRecordId);

      return {
        ...state,
        items: state.items.set(index, {
          ...state.items.get(index),
          loading: false,
          uploadError: action.err
        })
      };
    }

    default:
      return state;
  }
}
