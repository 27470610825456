export const appConstants = {
  APP_CREATE_LOADING: 'APP_CREATE_LOADING',
  APP_CREATE_SUCCESS: 'APP_CREATE_SUCCESS',
  APP_CREATE_FAILURE: 'APP_CREATE_FAILURE',

  APP_LIST_LOADING: 'APP_LIST_LOADING',
  APP_LIST_SUCCESS: 'APP_LIST_SUCCESS',
  APP_LIST_FAILURE: 'APP_LIST_FAILURE',

  APP_EDIT_LOADING: 'APP_EDIT_LOADING',
  APP_EDIT_SUCCESS: 'APP_EDIT_SUCCESS',
  APP_EDIT_FAILURE: 'APP_EDIT_FAILURE',

  APP_DELETE_LOADING: 'APP_DELETE_LOADING',
  APP_DELETE_SUCCESS: 'APP_DELETE_SUCCESS',
  APP_DELETE_FAILURE: 'APP_DELETE_FAILURE'
};
