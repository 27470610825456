import { List } from 'immutable';
import { appConnectionConstants } from '../constants';

export function app_connections(state = {}, action) {
  switch (action.type) {
    case appConnectionConstants.APP_CONNECTION_LIST_LOADING:
      return {
        ...state,
        items: null,
        listLoading: true
      };
    case appConnectionConstants.APP_CONNECTION_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case appConnectionConstants.APP_CONNECTION_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: null,
        listError: action.error
      };
    case appConnectionConstants.APP_CONNECTION_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case appConnectionConstants.APP_CONNECTION_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      return {
        ...state,
        items: items.push(action.response),
        createLoading: false,
        createError: null
      };
    }

    case appConnectionConstants.APP_CONNECTION_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };

    case appConnectionConstants.APP_CONNECTION_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case appConnectionConstants.APP_CONNECTION_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const appConnectionIndex = state.items.findIndex(val => val.id == action.response.id);

      if (appConnectionIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(appConnectionIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case appConnectionConstants.APP_CONNECTION_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case appConnectionConstants.APP_CONNECTION_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case appConnectionConstants.APP_CONNECTION_DELETE_SUCCESS: {
      const { appConnectionId } = action;

      if (!state.items) {
        return state;
      }

      const appConnectionIndex = state.items.findIndex(val => val.appId == appConnectionId);

      if (appConnectionIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(appConnectionIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case appConnectionConstants.APP_CONNECTION_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };

    case appConnectionConstants.SET_APP_CONNECTION_PARAMETERS: {
      const changedAppConnection =
        state.items.find(appCon => appCon.id === action.payload.appConId) || {};
      const appConnectionsWithoutChanges = state.items.filter(
        appCon => appCon.id !== action.payload.appConId
      );

      return {
        ...state,
        items: List([
          ...appConnectionsWithoutChanges,
          { ...changedAppConnection, ...action.payload.newParameters }
        ])
      };
    }

    default:
      return state;
  }
}
