import { List } from 'immutable';
import { quickbooksRealmsConstants } from '../constants';

export function quickbooks_realms(state = {}, action) {
  switch (action.type) {
    case quickbooksRealmsConstants.QUICKBOOKS_REALMS_LIST_LOADING:
      return {
        ...state,
        listLoading: true
      };
    case quickbooksRealmsConstants.QUICKBOOKS_REALMS_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case quickbooksRealmsConstants.QUICKBOOKS_REALMS_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: [],
        listError: action.error
      };

    case quickbooksRealmsConstants.QUICKBOOKS_REALMS_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case quickbooksRealmsConstants.QUICKBOOKS_REALMS_DELETE_SUCCESS: {
      const quickbooksRealmsId = action.id;

      if (!state.items) {
        return state;
      }

      const quickbooksRealmsIndex = state.items.findIndex(val => val.id == quickbooksRealmsId);

      if (quickbooksRealmsIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(quickbooksRealmsIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case quickbooksRealmsConstants.QUICKBOOKS_REALMS_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };

    default:
      return state;
  }
}
