import { call, put, throttle } from 'redux-saga/effects';
import {
  voidInvestorOnboardingItem,
  listInvestorOnboardingItems
} from 'services/investor_onboarding_item.service';

function* voidInvestorOnboardingCall({ payload: { orgId, itemId } }) {
  try {
    yield put({ type: 'VOID_ENVELOPE_LOADING_TRUE' });
    yield call(voidInvestorOnboardingItem, orgId, itemId);
    yield call(listInvestorOnboardingItems, orgId);
    yield put({ type: 'VOID_ENVELOPE_LOADING_FALSE' });
  } catch (e) {
    yield put({ type: 'SAGA_VOID_ENVELOPE_LOADING_ERROR', message: e.message });
  }
}

export function* watchVoid() {
  yield throttle(3000, 'VOID_ENVELOPE', voidInvestorOnboardingCall);
}
