import { List } from 'immutable';
import { onboardingProcessConstants } from '../constants';

export function onboarding_processes(state = {}, action) {
  switch (action.type) {
    case onboardingProcessConstants.ONBOARDING_PROCESS_LIST_LOADING:
      return {
        ...state,
        items: null,
        listLoading: true
      };
    case onboardingProcessConstants.ONBOARDING_PROCESS_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case onboardingProcessConstants.ONBOARDING_PROCESS_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: null,
        listError: action.error
      };

    case onboardingProcessConstants.ONBOARDING_PROCESS_LIST_CLEAR:
      return {
        ...state,
        items: null
      };

    case onboardingProcessConstants.ONBOARDING_PROCESS_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case onboardingProcessConstants.ONBOARDING_PROCESS_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      return {
        ...state,
        items: items.push(action.response),
        createLoading: false,
        createError: null
      };
    }
    case onboardingProcessConstants.ONBOARDING_PROCESS_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };

    case onboardingProcessConstants.ONBOARDING_PROCESS_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case onboardingProcessConstants.ONBOARDING_PROCESS_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const onboardingProcessIndex = state.items.findIndex(val => val.id == action.response.id);

      if (onboardingProcessIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(onboardingProcessIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case onboardingProcessConstants.ONBOARDING_PROCESS_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case onboardingProcessConstants.ONBOARDING_PROCESS_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case onboardingProcessConstants.ONBOARDING_PROCESS_DELETE_SUCCESS: {
      const { onboardingProcessId } = action;

      if (!state.items) {
        return state;
      }

      const onboardingProcessIndex = state.items.findIndex(val => val.id == onboardingProcessId);

      if (onboardingProcessIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(onboardingProcessIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case onboardingProcessConstants.ONBOARDING_PROCESS_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };
    case onboardingProcessConstants.ONBOARDING_PROCESS_ERROR_RELOAD:
      return {
        ...state,
        createError: null,
        editError: null
      };

    default:
      return state;
  }
}
