import { List } from 'immutable';
import { investorTypeConstants } from '../constants';

export function investor_types(state = {}, action) {
  switch (action.type) {
    case investorTypeConstants.INVESTOR_TYPE_LIST_LOADING:
      return {
        ...state,
        items: null,
        listLoading: true
      };
    case investorTypeConstants.INVESTOR_TYPE_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case investorTypeConstants.INVESTOR_TYPE_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: null,
        listError: action.error
      };
    case investorTypeConstants.INVESTOR_TYPE_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case investorTypeConstants.INVESTOR_TYPE_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      const investorTypes = items.map(investorType => ({
        ...investorType,
        isDefault: action.response.isDefault ? false : investorType.isDefault
      }));

      return {
        ...state,
        items: investorTypes.push(action.response),
        createLoading: false,
        createError: null
      };
    }
    case investorTypeConstants.INVESTOR_TYPE_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };

    case investorTypeConstants.INVESTOR_TYPE_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case investorTypeConstants.INVESTOR_TYPE_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const investorTypeIndex = state.items.findIndex(val => val.id == action.response.id);

      if (investorTypeIndex == -1) {
        return state;
      }

      const types = state.items.map(investorType => ({
        ...investorType,
        isDefault: action.response.isDefault ? false : investorType.isDefault
      }));

      return {
        ...state,
        items: types.set(investorTypeIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case investorTypeConstants.INVESTOR_TYPE_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case investorTypeConstants.INVESTOR_TYPE_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case investorTypeConstants.INVESTOR_TYPE_DELETE_SUCCESS: {
      const { investorTypeId } = action;

      if (!state.items) {
        return state;
      }

      const investorTypeIndex = state.items.findIndex(val => val.id == investorTypeId);

      if (investorTypeIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(investorTypeIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case investorTypeConstants.INVESTOR_TYPE_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };
    case investorTypeConstants.INVESTOR_TYPE_RELOAD_ERROR:
      return {
        ...state,
        createError: null,
        editError: null
      };

    default:
      return state;
  }
}
