import { List } from 'immutable';
import { kycCompanyDocumentConstants, kycCompanyConstants } from '../constants';

export function kyc_company_documents(state = {}, action) {
  switch (action.type) {
    case kycCompanyDocumentConstants.KYC_COMPANY_DOCUMENT_LIST_LOADING:
      return {
        ...state,
        items: null,
        listLoading: true
      };
    case kycCompanyDocumentConstants.KYC_COMPANY_DOCUMENT_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case kycCompanyDocumentConstants.KYC_COMPANY_DOCUMENT_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: null,
        listError: action.error
      };
    case kycCompanyDocumentConstants.KYC_COMPANY_DOCUMENT_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case kycCompanyDocumentConstants.KYC_COMPANY_DOCUMENT_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      return {
        ...state,
        items: items.push(action.response),
        createLoading: false,
        createError: null
      };
    }
    case kycCompanyDocumentConstants.KYC_COMPANY_DOCUMENT_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };

    case kycCompanyDocumentConstants.KYC_COMPANY_DOCUMENT_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case kycCompanyDocumentConstants.KYC_COMPANY_DOCUMENT_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const kycCompanyDocumentIndex = state.items.findIndex(val => val.id == action.response.id);

      if (kycCompanyDocumentIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(kycCompanyDocumentIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case kycCompanyDocumentConstants.KYC_COMPANY_DOCUMENT_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case kycCompanyDocumentConstants.KYC_COMPANY_DOCUMENT_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case kycCompanyDocumentConstants.KYC_COMPANY_DOCUMENT_DELETE_SUCCESS: {
      const { kycCompanyDocumentId } = action;

      if (!state.items) {
        return state;
      }

      const kycCompanyDocumentIndex = state.items.findIndex(val => val.id == kycCompanyDocumentId);

      if (kycCompanyDocumentIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(kycCompanyDocumentIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case kycCompanyConstants.KYC_COMPANY_EDIT_SUCCESS:
    case kycCompanyConstants.KYC_COMPANY_CREATE_SUCCESS: {
      let changedItems = List([...state.items]);

      const { kyc_company_documents } = action.response;

      for (const kyc_company_document of kyc_company_documents) {
        const kycCompanyDocumentIndex = changedItems.findIndex(
          val => val.id === kyc_company_document.id
        );

        if (kycCompanyDocumentIndex == -1) {
          continue;
        }

        const newKycCompanyDocument = {
          ...changedItems.get(kycCompanyDocumentIndex),
          kycCompanyId: action.response.id
        };

        changedItems = changedItems.set(kycCompanyDocumentIndex, newKycCompanyDocument);
      }

      return {
        ...state,
        items: changedItems
      };
    }
    case kycCompanyDocumentConstants.KYC_COMPANY_DOCUMENT_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };
    case kycCompanyDocumentConstants.KYC_COMPANY_DOCUMENT_ERROR_RELOAD:
      return {
        ...state,
        createError: null,
        editError: null
      };

    default:
      return state;
  }
}
