import axios from 'axios';

import { axiosOptions } from './constants';

export async function create(
  orgId,
  investorId,
  firstName,
  lastName,
  title,
  email,
  additionalEmail,
  current,
  sendNotices,
  mainRecipient,
  passports,
  utility_bills
) {
  const request = {
    investorId,
    firstName,
    lastName,
    title,
    email,
    additionalEmail,
    current,
    sendNotices,
    mainRecipient,
    passports: passports.map(c => c.id),
    utility_bills: utility_bills.map(c => c.id)
  };

  const res = await axios.post(
    `${process.env.API_URL}/org/${orgId}/director`,
    request,
    axiosOptions
  );

  return res.data;
}

export async function listDirectors(orgId) {
  const request = null;

  const res = await axios.get(
    `${process.env.API_URL}/org/${orgId}/director`,
    request,
    axiosOptions
  );

  return res.data;
}

export async function edit(
  orgId,
  directorId,
  investorId,
  firstName,
  lastName,
  title,
  email,
  additionalEmail,
  current,
  sendNotices,
  mainRecipient,
  passports,
  utility_bills
) {
  const request = {
    investorId,
    firstName,
    lastName,
    title,
    email,
    additionalEmail,
    current,
    sendNotices,
    mainRecipient,
    passports: passports.map(c => c.id),
    utility_bills: utility_bills.map(c => c.id)
  };

  const res = await axios.put(
    `${process.env.API_URL}/org/${orgId}/director/${directorId}`,
    request,
    axiosOptions
  );

  return res.data;
}

export async function deleteDirector(orgId, directorId) {
  const request = null;

  const res = await axios.delete(
    `${process.env.API_URL}/org/${orgId}/director/${directorId}`,
    request,
    axiosOptions
  );

  return res.data;
}

export async function copyDirector(orgId, directorId, investorId) {
  const res = await axios.post(
    `${process.env.API_URL}/org/${orgId}/copy_director`,
    { userInvestorId: directorId, investorId },
    axiosOptions
  );

  return res.data;
}
