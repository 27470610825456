export const INVESTMENT_WIZARD_CONSTANTS = {
  EQUITY: 'Equity',
  DEBT: 'Debt',
  CONVERTIBLE: 'Convertible',
  SHAREHOLDERS: 'Shareholders',
  SHARECLASSES: 'ShareClasses',
  CAPTABLES: 'CapTables',
  TRANSACTIONS: 'InvestmentTransactions',
  XEROMAPPINGS: 'XeroMappings',
  QUICKBOOKSMAPPINGS: 'QuickbooksMappings',

  RESET: 'RESET',
  REGISTER: 'REGISTER',
  CHANGE_STEP: 'CHANGE_STEP',
  SET_CURR_INV_ID: 'SET_CURR_INV_ID',
  SET_FLOW_TYPE: 'SET_FLOW_TYPE',
  CHANGE_NEW_INV_FIELD: 'CHANGE_NEW_INV_FIELD',
  CHANGE_CHOOSEN_INV_FIELD: 'CHANGE_CHOOSEN_INV_FIELD',
  CHOOSE_INVESTMENT: 'CHOOSE_INVESTMENT',
  CHANGE_TAB_STEP_TWO: 'CHANGE_TAB_STEP_TWO',
  CHANGE_TAB_STEP_FIVE: 'CHANGE_TAB_STEP_FIVE'
};
