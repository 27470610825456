import axios from 'axios';

import { axiosOptions } from './constants';

export async function create(orgId, date, residenceAddress, type, fileId, isPrimary) {
  const request = {
    date,
    residenceAddress,
    type,
    fileId,
    isPrimary
  };

  const res = await axios.post(
    `${process.env.API_URL}/org/${orgId}/utility_bill`,
    request,
    axiosOptions
  );

  return res.data;
}

export async function listUtilityBills(orgId) {
  const request = null;

  const res = await axios.get(
    `${process.env.API_URL}/org/${orgId}/utility_bill`,
    request,
    axiosOptions
  );

  return res.data;
}

export async function edit(orgId, utilityBillId, date, residenceAddress, type, fileId, isPrimary) {
  const request = {
    date,
    residenceAddress,
    type,
    fileId,
    isPrimary
  };

  const res = await axios.put(
    `${process.env.API_URL}/org/${orgId}/utility_bill/${utilityBillId}`,
    request,
    axiosOptions
  );

  return res.data;
}

export async function deleteUtilityBill(orgId, utilityBillId) {
  const request = null;

  const res = await axios.delete(
    `${process.env.API_URL}/org/${orgId}/utility_bill/${utilityBillId}`,
    request,
    axiosOptions
  );

  return res.data;
}
