export const companyDocConstants = {
  COMPANY_DOC_CREATE_LOADING: 'COMPANY_DOC_CREATE_LOADING',
  COMPANY_DOC_CREATE_SUCCESS: 'COMPANY_DOC_CREATE_SUCCESS',
  COMPANY_DOC_CREATE_FAILURE: 'COMPANY_DOC_CREATE_FAILURE',
  COMPANY_DOC_ERROR_RELOAD: 'COMPANY_DOC_ERROR_RELOAD',

  COMPANY_DOC_LIST_LOADING: 'COMPANY_DOC_LIST_LOADING',
  COMPANY_DOC_LIST_SUCCESS: 'COMPANY_DOC_LIST_SUCCESS',
  COMPANY_DOC_LIST_FAILURE: 'COMPANY_DOC_LIST_FAILURE',

  COMPANY_DOC_EDIT_LOADING: 'COMPANY_DOC_EDIT_LOADING',
  COMPANY_DOC_EDIT_SUCCESS: 'COMPANY_DOC_EDIT_SUCCESS',
  COMPANY_DOC_EDIT_FAILURE: 'COMPANY_DOC_EDIT_FAILURE',

  COMPANY_DOC_DELETE_LOADING: 'COMPANY_DOC_DELETE_LOADING',
  COMPANY_DOC_DELETE_SUCCESS: 'COMPANY_DOC_DELETE_SUCCESS',
  COMPANY_DOC_DELETE_FAILURE: 'COMPANY_DOC_DELETE_FAILURE'
};
