import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'components/api';
import { CustomError } from 'views/helpers';
import { getDefaultReducersForEntityItemsLoad, loadItemsFulfilled } from '../helpers';

export const createRefreshOfAccountingEntity = entityName =>
  createAsyncThunk(`${entityName.toLowerCase()}s/refresh`, async (orgId, { rejectWithValue }) => {
    try {
      const response = await api.get(`/org/${orgId}/${entityName.toLowerCase()}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(new CustomError(error));
    }
  });
export const generateCodeForRefreshOfAccountingEntity = entityName => {
  const refresh = createRefreshOfAccountingEntity(entityName);
  return {
    [`refresh${entityName}s`]: refresh,
    generatedExtraReducers: {
      [refresh.fulfilled]: loadItemsFulfilled
    }
  };
};

export const createSyncWithXeroOfAccountingEntity = entityName =>
  createAsyncThunk(
    `${entityName.toLowerCase()}s/syncWithXero`,
    async (orgId, { rejectWithValue }) => {
      try {
        const response = await api.get(`/org/${orgId}/${entityName.toLowerCase()}/xero_sync`);

        return response.data;
      } catch (error) {
        return rejectWithValue(new CustomError(error));
      }
    }
  );
export const generateCodeForSyncWithXeroOfAccountingEntity = entityName => {
  const syncWithXero = createSyncWithXeroOfAccountingEntity(entityName);
  return {
    [`sync${entityName}sWithXero`]: syncWithXero,
    generatedExtraReducers: getDefaultReducersForEntityItemsLoad(syncWithXero)
  };
};

export const createSyncWithQuickbooksOfAccountingEntity = entityName =>
  createAsyncThunk(
    `${entityName.toLowerCase()}s/syncWithQuickbooks`,
    async (orgId, { rejectWithValue }) => {
      try {
        const response = await api.get(`/org/${orgId}/${entityName.toLowerCase()}/quickbooks_sync`);

        return response.data;
      } catch (error) {
        return rejectWithValue(new CustomError(error));
      }
    }
  );
export const generateCodeForSyncWithQuickbooksOfAccountingEntity = entityName => {
  const syncWithQuickbooks = createSyncWithQuickbooksOfAccountingEntity(entityName);
  return {
    [`sync${entityName}sWithQuickbooks`]: syncWithQuickbooks,
    generatedExtraReducers: getDefaultReducersForEntityItemsLoad(syncWithQuickbooks)
  };
};

export const createExportOfAccountingEntity = entityName => async orgId => {
  const response = await api.post(`/org/${orgId}/export${entityName}s`, {});
  return response.data;
};

export const createImportOfAccountingEntity = entityName => async (orgId, fileId) => {
  const response = await api.post(`/org/${orgId}/import${entityName}s`, {
    fileId
  });
  return response.data;
};

export const generateCommonCodeForAccountingEntity = entityName => {
  const {
    generatedExtraReducers: refreshGeneratedExtraReducers,
    ...refresh
  } = generateCodeForRefreshOfAccountingEntity(entityName);

  const {
    generatedExtraReducers: syncWithXeroGeneratedExtraReducers,
    ...syncWithXero
  } = generateCodeForSyncWithXeroOfAccountingEntity(entityName);

  const {
    generatedExtraReducers: syncWithQuickbooksGeneratedExtraReducers,
    ...syncWithQuickbooks
  } = generateCodeForSyncWithQuickbooksOfAccountingEntity(entityName);

  return {
    ...refresh, // [`refresh${entityName}s`]
    ...syncWithXero, // [`sync${entityName}sWithXero`]
    ...syncWithQuickbooks, // [`sync${entityName}sWithQuickbooks`]
    [`export${entityName}s`]: createExportOfAccountingEntity(entityName),
    [`import${entityName}s`]: createImportOfAccountingEntity(entityName),
    generatedExtraReducers: {
      ...refreshGeneratedExtraReducers,
      ...syncWithXeroGeneratedExtraReducers,
      ...syncWithQuickbooksGeneratedExtraReducers
    }
  };
};
