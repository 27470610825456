export const valuationPolicyConstants = {
  VALUATION_POLICY_CREATE_LOADING: 'VALUATION_POLICY_CREATE_LOADING',
  VALUATION_POLICY_CREATE_SUCCESS: 'VALUATION_POLICY_CREATE_SUCCESS',
  VALUATION_POLICY_CREATE_FAILURE: 'VALUATION_POLICY_CREATE_FAILURE',
  VALUATION_POLICY_ERROR_RELOAD: 'VALUATION_POLICY_ERROR_RELOAD',

  VALUATION_POLICY_LIST_LOADING: 'VALUATION_POLICY_LIST_LOADING',
  VALUATION_POLICY_LIST_SUCCESS: 'VALUATION_POLICY_LIST_SUCCESS',
  VALUATION_POLICY_LIST_FAILURE: 'VALUATION_POLICY_LIST_FAILURE',

  VALUATION_POLICY_EDIT_LOADING: 'VALUATION_POLICY_EDIT_LOADING',
  VALUATION_POLICY_EDIT_SUCCESS: 'VALUATION_POLICY_EDIT_SUCCESS',
  VALUATION_POLICY_EDIT_FAILURE: 'VALUATION_POLICY_EDIT_FAILURE',

  VALUATION_POLICY_DELETE_LOADING: 'VALUATION_POLICY_DELETE_LOADING',
  VALUATION_POLICY_DELETE_SUCCESS: 'VALUATION_POLICY_DELETE_SUCCESS',
  VALUATION_POLICY_DELETE_FAILURE: 'VALUATION_POLICY_DELETE_FAILURE'
};
