import { List } from 'immutable';
import { companyValuationConstants } from 'constants';

export function company_valuations(state = {}, action) {
  switch (action.type) {
    case companyValuationConstants.COMPANY_VALUATION_LIST_LOADING:
      return {
        ...state,
        items: null,
        listLoading: true
      };
    case companyValuationConstants.SYNC_WITH_CONN_ORGS_START:
      return {
        ...state,
        listLoading: true
      };
    case companyValuationConstants.COMPANY_VALUATION_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case companyValuationConstants.COMPANY_VALUATION_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: null,
        listError: action.error
      };
    case companyValuationConstants.COMPANY_VALUATION_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case companyValuationConstants.COMPANY_VALUATION_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      return {
        ...state,
        items: items
          .push(action.response)
          .sort((a, b) => new Date(a.dateFrom).getTime() - new Date(b.dateFrom).getTime()), // Sort by date
        createLoading: false,
        createError: null
      };
    }

    case companyValuationConstants.COMPANY_VALUATION_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };

    case companyValuationConstants.COMPANY_VALUATION_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case companyValuationConstants.COMPANY_VALUATION_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const companyValuationIndex = state.items.findIndex(val => val.id == action.response.id);

      if (companyValuationIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(companyValuationIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case companyValuationConstants.COMPANY_VALUATION_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case companyValuationConstants.COMPANY_VALUATION_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case companyValuationConstants.COMPANY_VALUATION_DELETE_SUCCESS: {
      const { companyValuationId } = action;

      if (!state.items) {
        return state;
      }

      const companyValuationIndex = state.items.findIndex(val => val.id == companyValuationId);

      if (companyValuationIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(companyValuationIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case companyValuationConstants.COMPANY_VALUATION_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };
    case companyValuationConstants.COMPANY_VALUATION_ERROR_RELOAD:
      return {
        ...state,
        createError: null,
        editError: null
      };

    default:
      return state;
  }
}
