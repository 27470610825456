export const tableauConstants = {
  TABLEAU_CREATE_LOADING: 'TABLEAU_CREATE_LOADING',
  TABLEAU_CREATE_SUCCESS: 'TABLEAU_CREATE_SUCCESS',
  TABLEAU_CREATE_FAILURE: 'TABLEAU_CREATE_FAILURE',
  TABLEAU_ERROR_RELOAD: 'TABLEAU_ERROR_RELOAD',

  TABLEAU_LIST_LOADING: 'TABLEAU_LIST_LOADING',
  TABLEAU_LIST_SUCCESS: 'TABLEAU_LIST_SUCCESS',
  TABLEAU_LIST_FAILURE: 'TABLEAU_LIST_FAILURE',

  TABLEAU_EDIT_LOADING: 'TABLEAU_EDIT_LOADING',
  TABLEAU_EDIT_SUCCESS: 'TABLEAU_EDIT_SUCCESS',
  TABLEAU_EDIT_FAILURE: 'TABLEAU_EDIT_FAILURE',

  TABLEAU_DELETE_LOADING: 'TABLEAU_DELETE_LOADING',
  TABLEAU_DELETE_SUCCESS: 'TABLEAU_DELETE_SUCCESS',
  TABLEAU_DELETE_FAILURE: 'TABLEAU_DELETE_FAILURE'
};
