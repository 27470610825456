export const quickbooksCompanyConstants = {
  QUICKBOOKS_COMPANY_CREATE_LOADING: 'QUICKBOOKS_COMPANY_CREATE_LOADING',
  QUICKBOOKS_COMPANY_CREATE_SUCCESS: 'QUICKBOOKS_COMPANY_CREATE_SUCCESS',
  QUICKBOOKS_COMPANY_CREATE_FAILURE: 'QUICKBOOKS_COMPANY_CREATE_FAILURE',
  QUICKBOOKS_COMPANY_ERROR_RELOAD: 'QUICKBOOKS_COMPANY_ERROR_RELOAD',

  QUICKBOOKS_COMPANY_LIST_LOADING: 'QUICKBOOKS_COMPANY_LIST_LOADING',
  QUICKBOOKS_COMPANY_LIST_SUCCESS: 'QUICKBOOKS_COMPANY_LIST_SUCCESS',
  QUICKBOOKS_COMPANY_LIST_FAILURE: 'QUICKBOOKS_COMPANY_LIST_FAILURE',

  QUICKBOOKS_COMPANY_EDIT_LOADING: 'QUICKBOOKS_COMPANY_EDIT_LOADING',
  QUICKBOOKS_COMPANY_EDIT_SUCCESS: 'QUICKBOOKS_COMPANY_EDIT_SUCCESS',
  QUICKBOOKS_COMPANY_EDIT_FAILURE: 'QUICKBOOKS_COMPANY_EDIT_FAILURE',

  QUICKBOOKS_COMPANY_DELETE_LOADING: 'QUICKBOOKS_COMPANY_DELETE_LOADING',
  QUICKBOOKS_COMPANY_DELETE_SUCCESS: 'QUICKBOOKS_COMPANY_DELETE_SUCCESS',
  QUICKBOOKS_COMPANY_DELETE_FAILURE: 'QUICKBOOKS_COMPANY_DELETE_FAILURE'
};
