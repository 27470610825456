export const fundDocTypeConstants = {
  FUND_DOC_TYPE_CREATE_LOADING: 'FUND_DOC_TYPE_CREATE_LOADING',
  FUND_DOC_TYPE_CREATE_SUCCESS: 'FUND_DOC_TYPE_CREATE_SUCCESS',
  FUND_DOC_TYPE_CREATE_FAILURE: 'FUND_DOC_TYPE_CREATE_FAILURE',
  FUND_DOC_TYPE_ERROR_RELOAD: 'FUND_DOC_TYPE_ERROR_RELOAD',

  FUND_DOC_TYPE_LIST_LOADING: 'FUND_DOC_TYPE_LIST_LOADING',
  FUND_DOC_TYPE_LIST_SUCCESS: 'FUND_DOC_TYPE_LIST_SUCCESS',
  FUND_DOC_TYPE_LIST_FAILURE: 'FUND_DOC_TYPE_LIST_FAILURE',

  FUND_DOC_TYPE_EDIT_LOADING: 'FUND_DOC_TYPE_EDIT_LOADING',
  FUND_DOC_TYPE_EDIT_SUCCESS: 'FUND_DOC_TYPE_EDIT_SUCCESS',
  FUND_DOC_TYPE_EDIT_FAILURE: 'FUND_DOC_TYPE_EDIT_FAILURE',

  FUND_DOC_TYPE_DELETE_LOADING: 'FUND_DOC_TYPE_DELETE_LOADING',
  FUND_DOC_TYPE_DELETE_SUCCESS: 'FUND_DOC_TYPE_DELETE_SUCCESS',
  FUND_DOC_TYPE_DELETE_FAILURE: 'FUND_DOC_TYPE_DELETE_FAILURE'
};
