import { List } from 'immutable';

export const createFormPassportAdd = (state, action, idKey) => ({
  ...state,
  createForm: {
    ...state.createForm,
    passports: [
      ...state.createForm.passports,
      { ...action.payload, [idKey]: action.payload.entityId }
    ]
  }
});

export const createFormPassportEdit = (state, action) => ({
  ...state,
  createForm: {
    ...state.createForm,
    passports: [
      ...state.createForm.passports.filter(p => p.id !== action.payload.id),
      action.payload
    ]
  }
});

export const createFormPassportDelete = (state, action) => ({
  ...state,
  createForm: {
    ...state.createForm,
    passports: state.createForm.passports.filter(p => p.id !== action.payload)
  }
});

export const createFormUtilityBillAdd = (state, action, idKey) => ({
  ...state,
  createForm: {
    ...state.createForm,
    utilityBills: [
      ...state.createForm.utilityBills,
      { ...action.payload, [idKey]: action.payload.entityId }
    ]
  }
});

export const createFormUtilityBillEdit = (state, action) => ({
  ...state,
  createForm: {
    ...state.createForm,
    utilityBills: [
      ...state.createForm.utilityBills.filter(p => p.id !== action.payload.id),
      action.payload
    ]
  }
});

export const createFormUtilityBillDelete = (state, action) => ({
  ...state,
  createForm: {
    ...state.createForm,
    utilityBills: state.createForm.utilityBills.filter(p => p.id !== action.payload)
  }
});

export const editFormPassportAdd = (state, action, idKey) => ({
  ...state,
  editForm: {
    ...state.editForm,
    passports: [
      ...state.editForm.passports,
      { ...action.payload, [idKey]: action.payload.entityId }
    ]
  }
});

export const editFormUtilityBillAdd = (state, action, idKey) => ({
  ...state,
  editForm: {
    ...state.editForm,
    utilityBills: [
      ...state.editForm.utilityBills,
      { ...action.payload, [idKey]: action.payload.entityId }
    ]
  }
});

export const setPassportEntityId = (state, action, idKey) => {
  let changedItems = List([...state.items]);

  const { passports } = action.response;

  for (const passport of passports) {
    const passportIndex = changedItems.findIndex(val => {
      return val.id === passport.id;
    });

    if (passportIndex == -1) {
      continue;
    }

    const newPassport = {
      ...changedItems.get(passportIndex),
      [idKey]: action.response.id
    };

    changedItems = changedItems.set(passportIndex, newPassport);
  }

  return {
    ...state,
    items: changedItems
  };
};

export const setUtilityBillEntityId = (state, action, idKey) => {
  let changedItems = List([...state.items]);

  const { utility_bills } = action.response;

  for (const utility_bill of utility_bills) {
    const utilityBillIndex = changedItems.findIndex(val => val.id === utility_bill.id);

    if (utilityBillIndex == -1) {
      continue;
    }

    const newUtilityBill = {
      ...changedItems.get(utilityBillIndex),
      [idKey]: action.response.id
    };

    changedItems = changedItems.set(utilityBillIndex, newUtilityBill);
  }

  return {
    ...state,
    items: changedItems
  };
};
