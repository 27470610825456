export const expenseConstants = {
  EXPENSE_CREATE_LOADING: 'EXPENSE_CREATE_LOADING',
  EXPENSE_CREATE_SUCCESS: 'EXPENSE_CREATE_SUCCESS',
  EXPENSE_CREATE_FAILURE: 'EXPENSE_CREATE_FAILURE',
  EXPENSE_ERROR_RELOAD: 'EXPENSE_ERROR_RELOAD',

  EXPENSE_LIST_LOADING: 'EXPENSE_LIST_LOADING',
  EXPENSE_LIST_SUCCESS: 'EXPENSE_LIST_SUCCESS',
  EXPENSE_LIST_FAILURE: 'EXPENSE_LIST_FAILURE',
  EXPENSE_LIST_CLEAR: 'EXPENSE_LIST_CLEAR',

  EXPENSE_EDIT_LOADING: 'EXPENSE_EDIT_LOADING',
  EXPENSE_EDIT_SUCCESS: 'EXPENSE_EDIT_SUCCESS',
  EXPENSE_EDIT_FAILURE: 'EXPENSE_EDIT_FAILURE',

  EXPENSE_DELETE_LOADING: 'EXPENSE_DELETE_LOADING',
  EXPENSE_DELETE_SUCCESS: 'EXPENSE_DELETE_SUCCESS',
  EXPENSE_DELETE_FAILURE: 'EXPENSE_DELETE_FAILURE'
};
