export const dividendConstants = {
  DIVIDEND_CREATE_LOADING: 'DIVIDEND_CREATE_LOADING',
  DIVIDEND_CREATE_SUCCESS: 'DIVIDEND_CREATE_SUCCESS',
  DIVIDEND_CREATE_FAILURE: 'DIVIDEND_CREATE_FAILURE',
  DIVIDEND_ERROR_RELOAD: 'DIVIDEND_ERROR_RELOAD',

  DIVIDEND_LIST_LOADING: 'DIVIDEND_LIST_LOADING',
  DIVIDEND_LIST_SUCCESS: 'DIVIDEND_LIST_SUCCESS',
  DIVIDEND_LIST_FAILURE: 'DIVIDEND_LIST_FAILURE',

  DIVIDEND_EDIT_LOADING: 'DIVIDEND_EDIT_LOADING',
  DIVIDEND_EDIT_SUCCESS: 'DIVIDEND_EDIT_SUCCESS',
  DIVIDEND_EDIT_FAILURE: 'DIVIDEND_EDIT_FAILURE',

  DIVIDEND_DELETE_LOADING: 'DIVIDEND_DELETE_LOADING',
  DIVIDEND_DELETE_SUCCESS: 'DIVIDEND_DELETE_SUCCESS',
  DIVIDEND_DELETE_FAILURE: 'DIVIDEND_DELETE_FAILURE'
};
