import { List } from 'immutable';
import { commitmentConstants } from '../constants';

export function commitments(state = {}, action) {
  switch (action.type) {
    case commitmentConstants.COMMITMENT_LIST_LOADING:
      return {
        ...state,
        listLoading: true
      };
    case commitmentConstants.COMMITMENT_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case commitmentConstants.COMMITMENT_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: [],
        listError: action.error
      };
    case commitmentConstants.COMMITMENT_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case commitmentConstants.COMMITMENT_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      return {
        ...state,
        items: items.push(action.response),
        createLoading: false,
        createError: null
      };
    }
    case commitmentConstants.COMMITMENT_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };

    case commitmentConstants.COMMITMENT_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case commitmentConstants.COMMITMENT_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const commitmentIndex = state.items.findIndex(val => val.id == action.response.id);

      if (commitmentIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(commitmentIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case commitmentConstants.COMMITMENT_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case commitmentConstants.COMMITMENT_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case commitmentConstants.COMMITMENT_DELETE_SUCCESS: {
      const { commitmentId } = action;

      if (!state.items) {
        return state;
      }

      const commitmentIndex = state.items.findIndex(val => val.id == commitmentId);

      if (commitmentIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(commitmentIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case commitmentConstants.COMMITMENT_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };
    case commitmentConstants.COMMITMENT_ERROR_RELOAD:
      return {
        ...state,
        createError: null,
        editError: null
      };

    default:
      return state;
  }
}
