import { List } from 'immutable';
import { orgConstants, userConstants } from '../constants';

export function org(state = {}, action) {
  switch (action.type) {
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        org: {
          ...state.org,
          memberships:
            action.response.user.memberships && state.org && state.org.id
              ? action.response.user.memberships.filter(m => m.orgId === state.org.id)
              : []
        }
      };

    case orgConstants.SET_ORG: // login
      return {
        authentication: state.authentication,
        org: {
          id: action.orgId,
          memberships: state.authentication.user.memberships
            ? state.authentication.user.memberships.filter(m => m.orgId === action.orgId)
            : []
        },
        orgs: state.orgs
      };

    case orgConstants.ORG_ALL_FUNDS_LOADING:
      return {
        ...state,
        org: {
          ...state.org,
          allFundsLoading: true
        }
      };
    case orgConstants.ORG_ALL_FUNDS_SUCCESS:
      return {
        ...state,
        org: {
          ...state.org,
          allFunds: List(action.response.data),
          allFundsLoading: false
        }
      };
    case orgConstants.ORG_ALL_FUNDS_FAILURE:
      return {
        ...state,
        org: {
          ...state.org,
          allFunds: List(),
          allFundsLoading: false,
          allFundsError: action.error
        }
      };

    case orgConstants.REMOVE_ORG: // logoff
      return {
        authentication: state.authentication,
        orgs: state.orgs
      };

    case orgConstants.ORG_CREATE_SUCCESS: {
      return {
        authentication: state.authentication,
        org: {
          id: action.response.id,
          memberships: state.authentication.user.memberships
            ? state.authentication.user.memberships.filter(m => m.orgId === action.response.id)
            : []
        },
        orgs: state.orgs
      };
    }

    case orgConstants.ORG_DELETE_SUCCESS: {
      const { orgId } = action;

      if (state.org.id === orgId) {
        return {
          authentication: state.authentication,
          org: {},
          orgs: state.orgs
        };
      }
      return state;
    }

    case orgConstants.SET_TABLEAU_DATA: {
      const changedOrg = state.orgs.items.find(org => org.id === state.org.id);
      const orgsWithoutChanges = state.orgs.items.filter(org => org.id !== state.org.id);

      return {
        ...state,
        orgs: {
          ...state.orgs,
          items: List([
            ...orgsWithoutChanges,
            {
              ...changedOrg,
              tableau: {
                ...changedOrg.tableau,
                ...action.newParameters
              }
            }
          ])
        }
      };
    }

    default:
      return state;
  }
}
