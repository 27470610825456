export const quickbooksMappingConstants = {
  QUICKBOOKS_MAPPING_CREATE_LOADING: 'QUICKBOOKS_MAPPING_CREATE_LOADING',
  QUICKBOOKS_MAPPING_CREATE_SUCCESS: 'QUICKBOOKS_MAPPING_CREATE_SUCCESS',
  QUICKBOOKS_MAPPING_CREATE_FAILURE: 'QUICKBOOKS_MAPPING_CREATE_FAILURE',
  QUICKBOOKS_MAPPING_ERROR_RELOAD: 'QUICKBOOKS_MAPPING_ERROR_RELOAD',

  QUICKBOOKS_MAPPING_LIST_LOADING: 'QUICKBOOKS_MAPPING_LIST_LOADING',
  QUICKBOOKS_MAPPING_LIST_SUCCESS: 'QUICKBOOKS_MAPPING_LIST_SUCCESS',
  QUICKBOOKS_MAPPING_LIST_FAILURE: 'QUICKBOOKS_MAPPING_LIST_FAILURE',
  QUICKBOOKS_MAPPING_LIST_CLEAR: 'QUICKBOOKS_MAPPING_LIST_CLEAR',

  QUICKBOOKS_MAPPING_EDIT_LOADING: 'QUICKBOOKS_MAPPING_EDIT_LOADING',
  QUICKBOOKS_MAPPING_EDIT_SUCCESS: 'QUICKBOOKS_MAPPING_EDIT_SUCCESS',
  QUICKBOOKS_MAPPING_EDIT_FAILURE: 'QUICKBOOKS_MAPPING_EDIT_FAILURE',

  QUICKBOOKS_MAPPING_DELETE_LOADING: 'QUICKBOOKS_MAPPING_DELETE_LOADING',
  QUICKBOOKS_MAPPING_DELETE_SUCCESS: 'QUICKBOOKS_MAPPING_DELETE_SUCCESS',
  QUICKBOOKS_MAPPING_DELETE_FAILURE: 'QUICKBOOKS_MAPPING_DELETE_FAILURE'
};
