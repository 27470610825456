import { tablePropertyConstants } from '../constants';

/*
 * [
 *  {
 *    tableName: "",
 *    columnProperties: [
 *      {
 *        propertiesId: "",
 *        columnName: "",
 *        columnWidth: 100
 *      }
 *    ]
 *  }
 * ]
 */

export function table_properties(state = [], action) {
  switch (action.type) {
    case tablePropertyConstants.TABLE_PROPERTY_LOAD_SUCCESS: {
      const filtered = state.filter(
        tableProperty => tableProperty.tableName !== action.payload.tableName
      );
      return [...filtered, action.payload];
    }
    default:
      return state;
  }
}
