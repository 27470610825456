import { List } from 'immutable';
import { oppStageConstants } from '../constants';

export function opp_stages(state = {}, action) {
  switch (action.type) {
    case oppStageConstants.OPP_STAGE_LIST_LOADING:
      return {
        ...state,
        listLoading: true
      };
    case oppStageConstants.OPP_STAGE_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case oppStageConstants.OPP_STAGE_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: null,
        listError: action.error
      };
    case oppStageConstants.OPP_STAGE_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case oppStageConstants.OPP_STAGE_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      return {
        ...state,
        items: items.push(action.response),
        createLoading: false,
        createError: null
      };
    }
    case oppStageConstants.OPP_STAGE_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };

    case oppStageConstants.OPP_STAGE_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case oppStageConstants.OPP_STAGE_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const oppStageIndex = state.items.findIndex(val => val.id == action.response.id);

      if (oppStageIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(oppStageIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case oppStageConstants.OPP_STAGE_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case oppStageConstants.OPP_STAGE_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case oppStageConstants.OPP_STAGE_DELETE_SUCCESS: {
      const { oppStageId } = action;

      if (!state.items) {
        return state;
      }

      const oppStageIndex = state.items.findIndex(val => val.id == oppStageId);

      if (oppStageIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(oppStageIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case oppStageConstants.OPP_STAGE_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };
    case oppStageConstants.OPP_STAGE_ERROR_RELOAD:
      return {
        ...state,
        createError: null,
        editError: null
      };
    case oppStageConstants.OPP_STAGE_SALESFORCE_LIST_LOADING:
      return {
        ...state,
        salesforceListLoading: true
      };
    case oppStageConstants.OPP_STAGE_SALESFORCE_LIST_SUCCESS:
      return {
        ...state,
        salesforceItems: List(action.response),
        salesforceListLoading: false
      };
    case oppStageConstants.OPP_STAGE_SALESFORCE_LIST_FAILURE:
      return {
        ...state,
        salesforceListLoading: false,
        salesforceItems: null,
        salesforceListError: action.error
      };
    case oppStageConstants.OPP_STAGE_PIPEDRIVE_LIST_LOADING:
      return {
        ...state,
        pipedriveListLoading: true
      };
    case oppStageConstants.OPP_STAGE_PIPEDRIVE_LIST_SUCCESS:
      return {
        ...state,
        pipedriveItems: List(action.response),
        pipedriveListLoading: false
      };
    case oppStageConstants.OPP_STAGE_PIPEDRIVE_LIST_FAILURE:
      return {
        ...state,
        pipedriveListLoading: false,
        pipedriveItems: null,
        pipedriveListError: action.error
      };
    case oppStageConstants.OPP_STAGE_INSIGHTLY_LIST_LOADING:
      return {
        ...state,
        insightlyListLoading: true
      };
    case oppStageConstants.OPP_STAGE_INSIGHTLY_LIST_SUCCESS:
      return {
        ...state,
        insightlyItems: List(action.response),
        insightlyListLoading: false
      };
    case oppStageConstants.OPP_STAGE_INSIGHTLY_LIST_FAILURE:
      return {
        ...state,
        insightlyListLoading: false,
        insightlyItems: null,
        insightlyListError: action.error
      };
    default:
      return state;
  }
}
