import { createEntityValidator, generateSlice, SLICE_CODE_FOR } from '../helpers';
import { nullValidator } from '../../validators/common';
import { uboConstants } from 'constants/ubo.constants';

const taxResidentValidation = createEntityValidator({
  countryOfTaxResidence: [nullValidator()],
  taxNumber: [nullValidator()],
  startingDate: [nullValidator()]
});

const uboCreateAndEditSuccessReducer = (state, action) => {
  const { tax_residents } = action.response;
  for (const { id } of tax_residents) {
    const taxResidentIndex = state.items.findIndex(val => val.id === id);
    if (taxResidentIndex === -1) {
      continue;
    }
    state.items[taxResidentIndex] = { ...state.items[taxResidentIndex], uboId: action.response.id };
  }
};

export const {
  taxResidentsSlice,
  loadTaxResidents,
  createTaxResident,
  editTaxResident,
  deleteTaxResident
} = generateSlice({ entityName: 'TaxResident', entityValidation: taxResidentValidation }, [
  SLICE_CODE_FOR.LOAD,
  SLICE_CODE_FOR.CREATE,
  SLICE_CODE_FOR.EDIT,
  SLICE_CODE_FOR.DELETE
])({
  extraReducers: {
    [uboConstants.UBO_EDIT_SUCCESS]: uboCreateAndEditSuccessReducer,
    [uboConstants.UBO_CREATE_SUCCESS]: uboCreateAndEditSuccessReducer
  }
});

export default taxResidentsSlice.reducer;
