import { List } from 'immutable';
import { fundmetricConstants } from '../constants';

export function fundMetrics(state = {}, action) {
  switch (action.type) {
    case fundmetricConstants.FUNDMETRIC_LIST_LOADING:
      return {
        ...state,
        listLoading: true
      };
    case fundmetricConstants.FUNDMETRIC_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case fundmetricConstants.FUNDMETRIC_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: [],
        listError: action.error
      };
    case fundmetricConstants.FUNDMETRIC_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case fundmetricConstants.FUNDMETRIC_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      return {
        ...state,
        items: items.push(action.response),
        createLoading: false,
        createError: null
      };
    }
    case fundmetricConstants.FUNDMETRIC_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };

    case fundmetricConstants.FUNDMETRIC_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case fundmetricConstants.FUNDMETRIC_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const fundMetricIndex = state.items.findIndex(val => val.id == action.response.id);

      if (fundMetricIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(fundMetricIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case fundmetricConstants.FUNDMETRIC_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case fundmetricConstants.FUNDMETRIC_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case fundmetricConstants.FUNDMETRIC_DELETE_SUCCESS: {
      const { fundMetricId } = action;

      if (!state.items) {
        return state;
      }

      const fundMetricIndex = state.items.findIndex(val => val.id == fundMetricId);

      if (fundMetricIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(fundMetricIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case fundmetricConstants.FUNDMETRIC_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };
    case fundmetricConstants.FUNDMETRIC_ERROR_RELOAD:
      return {
        ...state,
        createError: null,
        editError: null
      };

    default:
      return state;
  }
}
