export const commitmentDocConstants = {
  COMMITMENT_DOC_CREATE_LOADING: 'COMMITMENT_DOC_CREATE_LOADING',
  COMMITMENT_DOC_CREATE_SUCCESS: 'COMMITMENT_DOC_CREATE_SUCCESS',
  COMMITMENT_DOC_CREATE_FAILURE: 'COMMITMENT_DOC_CREATE_FAILURE',
  COMMITMENT_DOC_ERROR_RELOAD: 'COMMITMENT_DOC_ERROR_RELOAD',

  COMMITMENT_DOC_LIST_LOADING: 'COMMITMENT_DOC_LIST_LOADING',
  COMMITMENT_DOC_LIST_SUCCESS: 'COMMITMENT_DOC_LIST_SUCCESS',
  COMMITMENT_DOC_LIST_FAILURE: 'COMMITMENT_DOC_LIST_FAILURE',
  COMMITMENT_DOC_LIST_CLEAR: 'COMMITMENT_DOC_LIST_CLEAR',

  COMMITMENT_DOC_EDIT_LOADING: 'COMMITMENT_DOC_EDIT_LOADING',
  COMMITMENT_DOC_EDIT_SUCCESS: 'COMMITMENT_DOC_EDIT_SUCCESS',
  COMMITMENT_DOC_EDIT_FAILURE: 'COMMITMENT_DOC_EDIT_FAILURE',

  COMMITMENT_DOC_DELETE_LOADING: 'COMMITMENT_DOC_DELETE_LOADING',
  COMMITMENT_DOC_DELETE_SUCCESS: 'COMMITMENT_DOC_DELETE_SUCCESS',
  COMMITMENT_DOC_DELETE_FAILURE: 'COMMITMENT_DOC_DELETE_FAILURE'
};
