export const providerConstants = {
  PROVIDER_CREATE_LOADING: 'PROVIDER_CREATE_LOADING',
  PROVIDER_CREATE_SUCCESS: 'PROVIDER_CREATE_SUCCESS',
  PROVIDER_CREATE_FAILURE: 'PROVIDER_CREATE_FAILURE',
  PROVIDER_ERROR_RELOAD: 'PROVIDER_ERROR_RELOAD',

  PROVIDER_LIST_LOADING: 'PROVIDER_LIST_LOADING',
  PROVIDER_LIST_SUCCESS: 'PROVIDER_LIST_SUCCESS',
  PROVIDER_LIST_FAILURE: 'PROVIDER_LIST_FAILURE',

  PROVIDER_EDIT_LOADING: 'PROVIDER_EDIT_LOADING',
  PROVIDER_EDIT_SUCCESS: 'PROVIDER_EDIT_SUCCESS',
  PROVIDER_EDIT_FAILURE: 'PROVIDER_EDIT_FAILURE',

  PROVIDER_DELETE_LOADING: 'PROVIDER_DELETE_LOADING',
  PROVIDER_DELETE_SUCCESS: 'PROVIDER_DELETE_SUCCESS',
  PROVIDER_DELETE_FAILURE: 'PROVIDER_DELETE_FAILURE'
};
