export const orgConstants = {
  SET_ORG: 'ORG_SET',
  REMOVE_ORG: 'ORG_REMOVE',

  ORG_CREATE_LOADING: 'ORG_CREATE_LOADING',
  ORG_CREATE_SUCCESS: 'ORG_CREATE_SUCCESS',
  ORG_CREATE_FAILURE: 'ORG_CREATE_FAILURE',
  ORG_ERROR_RELOAD: 'ORG_ERROR_RELOAD',

  ORG_LIST_LOADING: 'ORG_LIST_LOADING',
  ORG_LIST_SUCCESS: 'ORG_LIST_SUCCESS',
  ORG_LIST_FAILURE: 'ORG_LIST_FAILURE',

  ORG_EDIT_LOADING: 'ORG_EDIT_LOADING',
  ORG_EDIT_SUCCESS: 'ORG_EDIT_SUCCESS',
  ORG_EDIT_FAILURE: 'ORG_EDIT_FAILURE',

  ORG_DELETE_LOADING: 'ORG_DELETE_LOADING',
  ORG_DELETE_SUCCESS: 'ORG_DELETE_SUCCESS',
  ORG_DELETE_FAILURE: 'ORG_DELETE_FAILURE',

  ORG_ALL_FUNDS_LOADING: 'ORG_ALL_FUNDS_LOADING',
  ORG_ALL_FUNDS_SUCCESS: 'ORG_ALL_FUNDS_SUCCESS',
  ORG_ALL_FUNDS_FAILURE: 'ORG_ALL_FUNDS_FAILURE',

  SET_TABLEAU_DATA: 'SET_TABLEAU_DATA'
};
