export const leadCustomFieldConstants = {
  LEAD_CUSTOM_FIELD_CREATE_LOADING: 'LEAD_CUSTOM_FIELD_CREATE_LOADING',
  LEAD_CUSTOM_FIELD_CREATE_SUCCESS: 'LEAD_CUSTOM_FIELD_CREATE_SUCCESS',
  LEAD_CUSTOM_FIELD_CREATE_FAILURE: 'LEAD_CUSTOM_FIELD_CREATE_FAILURE',

  LEAD_CUSTOM_FIELD_LIST_LOADING: 'LEAD_CUSTOM_FIELD_LIST_LOADING',
  LEAD_CUSTOM_FIELD_LIST_SUCCESS: 'LEAD_CUSTOM_FIELD_LIST_SUCCESS',
  LEAD_CUSTOM_FIELD_LIST_FAILURE: 'LEAD_CUSTOM_FIELD_LIST_FAILURE',

  LEAD_CUSTOM_FIELD_EDIT_LOADING: 'LEAD_CUSTOM_FIELD_EDIT_LOADING',
  LEAD_CUSTOM_FIELD_EDIT_SUCCESS: 'LEAD_CUSTOM_FIELD_EDIT_SUCCESS',
  LEAD_CUSTOM_FIELD_EDIT_FAILURE: 'LEAD_CUSTOM_FIELD_EDIT_FAILURE',

  LEAD_CUSTOM_FIELD_DELETE_LOADING: 'LEAD_CUSTOM_FIELD_DELETE_LOADING',
  LEAD_CUSTOM_FIELD_DELETE_SUCCESS: 'LEAD_CUSTOM_FIELD_DELETE_SUCCESS',
  LEAD_CUSTOM_FIELD_DELETE_FAILURE: 'LEAD_CUSTOM_FIELD_DELETE_FAILURE',

  LEAD_CUSTOM_FIELD_ERROR_RELOAD: 'LEAD_CUSTOM_FIELD_ERROR_RELOAD'
};
