import { kycConstants } from '../constants';

export function kyc(state = {}, action) {
  switch (action.type) {
    case kycConstants.KYC_SET_INVESTOR_ID:
      return {
        ...state,
        investorId: action.investorId
      };

    case kycConstants.KYC_SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.tab
      };

    default:
      return state;
  }
}
