import { List } from 'immutable';
import { directorConstants, passportConstants, uboConstants } from '../constants';
import { setPassportEntityId } from './helpers';
import {
  createCirculationContact,
  editCirculationContact
} from '../features/kyc/circulationContactsSlice';

export function passports(state = {}, action) {
  switch (action.type) {
    case passportConstants.PASSPORT_LIST_LOADING:
      return {
        ...state,
        listLoading: true
      };
    case passportConstants.PASSPORT_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case passportConstants.PASSPORT_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: null,
        listError: action.error
      };
    case passportConstants.PASSPORT_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case passportConstants.PASSPORT_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      return {
        ...state,
        items: items.push(action.response),
        createLoading: false,
        createError: null
      };
    }
    case passportConstants.PASSPORT_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };

    case passportConstants.PASSPORT_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case passportConstants.PASSPORT_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const passportIndex = state.items.findIndex(val => val.id == action.response.id);

      if (passportIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(passportIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case passportConstants.PASSPORT_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case passportConstants.PASSPORT_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case passportConstants.PASSPORT_DELETE_SUCCESS: {
      const { passportId } = action;

      if (!state.items) {
        return state;
      }

      const passportIndex = state.items.findIndex(val => val.id == passportId);

      if (passportIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(passportIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case uboConstants.UBO_EDIT_SUCCESS:
    case uboConstants.UBO_CREATE_SUCCESS: {
      return setPassportEntityId(state, action, 'uboId');
    }
    case directorConstants.DIRECTOR_EDIT_SUCCESS:
    case directorConstants.DIRECTOR_CREATE_SUCCESS: {
      return setPassportEntityId(state, action, 'directorId');
    }

    case [createCirculationContact.fulfilled]:
    case [editCirculationContact.fulfilled]: {
      return setPassportEntityId(state, action, 'contactId');
    }

    case passportConstants.PASSPORT_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };
    case passportConstants.PASSPORT_ERROR_RELOAD:
      return {
        ...state,
        createError: null,
        editError: null
      };

    default:
      return state;
  }
}
