export const teamConstants = {
  TEAM_CREATE_LOADING: 'TEAM_CREATE_LOADING',
  TEAM_CREATE_SUCCESS: 'TEAM_CREATE_SUCCESS',
  TEAM_CREATE_FAILURE: 'TEAM_CREATE_FAILURE',
  TEAM_RELOAD_ERROR: 'TEAM_RELOAD_ERROR',

  TEAM_LIST_LOADING: 'TEAM_LIST_LOADING',
  TEAM_LIST_SUCCESS: 'TEAM_LIST_SUCCESS',
  TEAM_LIST_FAILURE: 'TEAM_LIST_FAILURE',

  TEAM_ORG_LIST_LOADING: 'TEAM_ORG_LIST_LOADING',
  TEAM_ORG_LIST_SUCCESS: 'TEAM_ORG_LIST_SUCCESS',
  TEAM_ORG_LIST_FAILURE: 'TEAM_ORG_LIST_FAILURE',

  TEAM_INVESTORS_LIST_LOADING: 'TEAM_INVESTORS_LIST_LOADING',
  TEAM_INVESTORS_LIST_SUCCESS: 'TEAM_INVESTORS_LIST_SUCCESS',
  TEAM_INVESTORS_LIST_FAILURE: 'TEAM_INVESTORS_LIST_FAILURE',

  TEAM_EDIT_LOADING: 'TEAM_EDIT_LOADING',
  TEAM_EDIT_SUCCESS: 'TEAM_EDIT_SUCCESS',
  TEAM_EDIT_FAILURE: 'TEAM_EDIT_FAILURE',

  TEAM_DELETE_LOADING: 'TEAM_DELETE_LOADING',
  TEAM_DELETE_SUCCESS: 'TEAM_DELETE_SUCCESS',
  TEAM_DELETE_FAILURE: 'TEAM_DELETE_FAILURE'
};
