import { List } from 'immutable';
import { orgConstants } from '../constants';

export function orgs(state = {}, action) {
  switch (action.type) {
    case orgConstants.ORG_LIST_LOADING:
      return {
        ...state,
        listLoading: true
      };
    case orgConstants.ORG_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case orgConstants.ORG_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: List(),
        listError: action.error
      };
    case orgConstants.ORG_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case orgConstants.ORG_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      return {
        ...state,
        items: items.push(action.response),
        createLoading: false,
        createError: null
      };
    }
    case orgConstants.ORG_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };

    case orgConstants.ORG_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case orgConstants.ORG_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }
      const orgIndex = state.items.findIndex(val => val.id == action.response.id);

      if (orgIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(orgIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case orgConstants.ORG_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case orgConstants.ORG_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case orgConstants.ORG_DELETE_SUCCESS: {
      const { orgId } = action;

      if (!state.items) {
        return state;
      }

      const orgIndex = state.items.findIndex(val => val.id == orgId);

      if (orgIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(orgIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case orgConstants.ORG_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };
    case orgConstants.ORG_ERROR_RELOAD:
      return {
        ...state,
        createError: null,
        editError: null
      };

    default:
      return state;
  }
}
