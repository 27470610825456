exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "", ""]);

// Exports
exports.locals = {
	"white": "#fff",
	"black": "#333",
	"grey": "#dbe0f7",
	"darkGrey": "#b0b9de",
	"red": "#ee553c",
	"yellow": "#ffcc31",
	"blue": "#2665f6",
	"darkBlue": "#01319f",
	"purple": "#ab17d6",
	"turquoise": "#36c9e4",
	"turquoiseDark": "#7eb8c4",
	"green": "#4dbd74",
	"greyForText": "#737a8b",
	"grayForBorder": "#c8cee8",
	"sidebarColor": "#e1e5ec",
	"sidebarText": "#7f889a",
	"contentBackground": "#f0f2f6",
	"companyReportBoxShadow": "0 4px 28px rgba(0,0,0,.08)"
};