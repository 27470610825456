import { createSlice } from '@reduxjs/toolkit';

export const paginationSlice = createSlice({
  name: 'pagination',
  initialState: {}, // { [tableName]: { page, size, sortField, sortOrder } }
  reducers: {
    savePagination: (state, { payload }) => {
      state[payload.tableName] = payload.pagination;
    }
  }
});

export const { savePagination } = paginationSlice.actions;

export default paginationSlice.reducer;
