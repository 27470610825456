import React, { Fragment } from 'react';
import { FormText, Input as rsInput } from 'reactstrap';
import styled from 'styled-components';
import { Trans as T, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isEmptyObject, toLowerCaseIfEmail } from 'views/helpers';

const Input = styled(rsInput)`
  &::placeholder {
    opacity: 0.5 !important;
  }
`;

const FormInput = ({
  type,
  name,
  id,
  placeholder,
  value,
  handleChange,
  hintText,
  customHintText,
  children,
  ...props
}) => {
  const { t } = useTranslation();

  let currentPlaceholder = '';
  if (type === 'date' && placeholder == null) {
    currentPlaceholder = t('yyyy-mm-dd');
  } else {
    currentPlaceholder = placeholder || '';
  }

  return (
    <Fragment>
      <Input
        type={type || 'text'}
        name={name}
        id={id}
        placeholder={currentPlaceholder}
        value={value}
        onChange={handleChange}
        {...props}
      >
        {React.Children.map(children, child => child)}
      </Input>
      {(hintText || customHintText) && (
        <FormText color="muted">
          {customHintText ? customHintText : <T i18nKey={hintText} />}
        </FormText>
      )}
    </Fragment>
  );
};

export const inputPropTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Required if the input is not a checkbox
  handleChange: PropTypes.func, // Required if the input is not disabled
  hintText: PropTypes.string,
  customHintText: PropTypes.string
};
FormInput.propTypes = inputPropTypes;

export const getHandleChange = (setEntity, validationError, reloadValidationError, dispatch) => {
  return event => {
    const { type, checked, name, value } = event.target;

    const realValue = type === 'checkbox' ? checked : value;
    const modifiedValue = toLowerCaseIfEmail(realValue, name);
    if (validationError != null && !isEmptyObject(validationError) && reloadValidationError) {
      dispatch(reloadValidationError());
    }
    setEntity(prevEntity => ({
      ...prevEntity,
      [name]: modifiedValue
    }));
  };
};

export default FormInput;
