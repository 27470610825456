import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  greaterThanValidator,
  maxValueValidator,
  minValueValidator,
  nullValidator,
  numberValidator
} from '../../validators/common';
import { applyDealFulfilled, unapplyDealFulfilled } from './helpers';
import { applyDeal, unapplyDeal } from './common';
import {
  createEntityValidator,
  defaultLoadItemsPayloadCreator,
  generateCodeForSlice,
  loadItemsFulfilled,
  loadItemsPending,
  loadItemsRejected,
  SLICE_CODE_FOR
} from '../helpers';
import { itemsInitialState } from '../constants';

const marketplaceDealValidation = createEntityValidator({
  name: [nullValidator()],
  minAmount: [nullValidator(), numberValidator(), minValueValidator(1)],
  maxPackage: [nullValidator(), numberValidator(), greaterThanValidator(0), maxValueValidator(100)],
  currency: [nullValidator()]
});

export const {
  generatedInitialState,
  generatedExtraReducers,
  createMarketplaceDeal,
  editMarketplaceDeal,
  deleteMarketplaceDeal
} = generateCodeForSlice(
  {
    entityName: 'MarketplaceDeal',
    baseUrl: '/marketplace/deal',
    entityValidation: marketplaceDealValidation,
    possibleResponseUserErrors: [{ error: 'DEAL_DATA_BR', formField: 'name' }]
  },
  [SLICE_CODE_FOR.CREATE, SLICE_CODE_FOR.EDIT, SLICE_CODE_FOR.DELETE]
);

export const loadUserDeals = createAsyncThunk(
  'userDeals/load',
  defaultLoadItemsPayloadCreator('/marketplace/user/orgdeals')
);

export const userDealsSlice = createSlice({
  name: 'userDeals',
  initialState: { ...generatedInitialState, ...itemsInitialState },
  reducers: {
    reloadDealsError: state => {
      state.createError = null;
      state.editError = null;
    }
  },
  extraReducers: {
    ...generatedExtraReducers,
    // LOAD
    [loadUserDeals.pending]: loadItemsPending,
    [loadUserDeals.fulfilled]: loadItemsFulfilled,
    [loadUserDeals.rejected]: loadItemsRejected,
    // APPLY DEAL
    [applyDeal.fulfilled]: applyDealFulfilled,
    // UNAPPLY DEAL
    [unapplyDeal.fulfilled]: unapplyDealFulfilled
  }
});

export const { reloadDealsError } = userDealsSlice.actions;
export default userDealsSlice.reducer;
