export const userConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
  VALIDATE_FAILURE: 'VALIDATE_TOKEN_FAILURE',

  MEMBERSHIPS_SUCCESS: 'MEMBERSHIPS_SUCCESS',

  LOGOUT: 'USERS_LOGOUT',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE',

  FORGOT_RECOVER_REQUEST: 'FORGOT_RECOVER_REQUEST',
  FORGOT_RECOVER_SUCCESS: 'FORGOT_RECOVER_SUCCESS',
  FORGOT_RECOVER_FAILURE: 'FORGOT_RECOVER_FAILURE',
  FORGOT_RECOVER_CLEAR: 'FORGOT_RECOVER_CLEAR',

  FORGOT_AUTH_REQUEST: 'FORGOT_AUTH_REQUEST',
  FORGOT_AUTH_SUCCESS: 'FORGOT_AUTH_SUCCESS',
  FORGOT_AUTH_FAILURE: 'FORGOT_AUTH_FAILURE',

  PROFILE_EDIT_LOADING: 'PROFILE_EDIT_LOADING',
  PROFILE_EDIT_SUCCESS: 'PROFILE_EDIT_SUCCESS',
  PROFILE_EDIT_FAILURE: 'PROFILE_EDIT_FAILURE',

  USER_ERROR_RELOAD: 'USER_ERROR_RELOAD',
  AUTHENTICATION_ERROR_RELOAD: 'AUTHENTICATION_ERROR_RELOAD',

  VISIBLE_FIELDS_EDIT_SUCCESS: 'VISIBLE_FIELDS_EDIT_SUCCESS'
};
