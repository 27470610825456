import axios from 'axios';
import { axiosOptions } from 'services/constants';

const DEFAULT_SORT_ORDER = 'desc';

class Api {
  constructor(apiUrl) {
    this.apiUrl = apiUrl;
  }

  getPaginationUrl(pagination) {
    let paginationUrlParts = [];
    if (pagination.page != null) {
      paginationUrlParts.push(`page=${pagination.page + 1}`);
      if (pagination.size) {
        paginationUrlParts.push(`size=${pagination.size}`);
      }
      if (pagination.sortField) {
        paginationUrlParts.push(
          `sort=${pagination.sortField},${pagination.sortOrder || DEFAULT_SORT_ORDER}`
        );
      }
    }
    return paginationUrlParts.length > 0 ? '?' + paginationUrlParts.join('&') : '';
  }

  async get(url, config = axiosOptions) {
    return axios.get(`${this.apiUrl}${url}`, config);
  }

  async post(url, data, pagination = {}, config = axiosOptions) {
    return axios.post(`${this.apiUrl}${url}${this.getPaginationUrl(pagination)}`, data, config);
  }

  async put(url, data, config = axiosOptions) {
    return axios.put(`${this.apiUrl}${url}`, data, config);
  }

  async delete(url, config = axiosOptions) {
    return axios.delete(`${this.apiUrl}${url}`, config);
  }
}

const api = new Api(process.env.API_URL);

export default api;
