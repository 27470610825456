import { List } from 'immutable';
import { languageConstants } from '../constants';

export function languages(state = {}, action) {
  switch (action.type) {
    case languageConstants.AVAILABLE_LANGUAGE_LIST_LOADING:
      return {
        ...state,
        loading: true
      };
    case languageConstants.AVAILABLE_LANGUAGE_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        loading: false
      };
    case languageConstants.AVAILABLE_LANGUAGE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}
