export const shareClassConstants = {
  SHARE_CLASS_CREATE_LOADING: 'SHARE_CLASS_CREATE_LOADING',
  SHARE_CLASS_CREATE_SUCCESS: 'SHARE_CLASS_CREATE_SUCCESS',
  SHARE_CLASS_CREATE_FAILURE: 'SHARE_CLASS_CREATE_FAILURE',
  SHARE_CLASS_ERROR_RELOAD: 'SHARE_CLASS_ERROR_RELOAD',

  SHARE_CLASS_LIST_LOADING: 'SHARE_CLASS_LIST_LOADING',
  SHARE_CLASS_LIST_SUCCESS: 'SHARE_CLASS_LIST_SUCCESS',
  SHARE_CLASS_LIST_FAILURE: 'SHARE_CLASS_LIST_FAILURE',

  SHARE_CLASS_EDIT_LOADING: 'SHARE_CLASS_EDIT_LOADING',
  SHARE_CLASS_EDIT_SUCCESS: 'SHARE_CLASS_EDIT_SUCCESS',
  SHARE_CLASS_EDIT_FAILURE: 'SHARE_CLASS_EDIT_FAILURE',

  SHARE_CLASS_DELETE_LOADING: 'SHARE_CLASS_DELETE_LOADING',
  SHARE_CLASS_DELETE_SUCCESS: 'SHARE_CLASS_DELETE_SUCCESS',
  SHARE_CLASS_DELETE_FAILURE: 'SHARE_CLASS_DELETE_FAILURE'
};
