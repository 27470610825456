import React from 'react';
import { useTranslation } from 'react-i18next';

export default ({ key, title, children, ...rest }) => {
  const { t } = useTranslation();

  return (
    <th key={key} {...rest}>
      <small>
        <b>
          {t(title)}
          {children}
        </b>
      </small>
    </th>
  );
};
