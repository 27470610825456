import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createItemFulfilled,
  generateCodeForSlice,
  getDefaultReducersForEntityCreate,
  getDefaultReducersForEntityEdit,
  SLICE_CODE_FOR
} from '../helpers';
import { formPassportAdd, formUtilityBillAdd } from 'actions/helpers';
import { createPassports, createUtilityBills } from 'actions/ubo.actions';
import * as investorOnboardingItemActions from 'actions/investor_onboarding_item.actions';
import { directorValidation } from 'actions/director.actions';
import { List } from 'immutable';
import { INVESTOR_CONTACTS_TYPES } from 'views/KYC/CirculationContacts/constants';
import { directorConstants } from 'constants/director.constants';
import { uboConstants } from 'constants/ubo.constants';
import { createItemInitialState, editItemInitialState } from '../constants';
import { utilityBillConstants } from 'constants/utility_bill.constants';
import { passportConstants } from 'constants/passport.constants';
import { CustomError } from 'views/helpers';
import api from 'components/api';

export const {
  rootName,
  generatedInitialState,
  generatedExtraReducers,
  loadCirculationContacts,
  deleteCirculationContact
} = generateCodeForSlice(
  {
    entityName: 'CirculationContact',
    possibleResponseUserErrors: [{ error: 'UNABLE_DEL_BR' }]
  },
  [SLICE_CODE_FOR.LOAD, SLICE_CODE_FOR.DELETE]
);

const addPassportInCreateForm = createAction('circulationContacts/addPassportInCreateForm');
export const addCirculationContactPassportInCreateForm = (
  orgId,
  passportNumber,
  country,
  issuedOn,
  validUntil,
  fileId,
  isPrimary,
  onSuccess,
  contactId
) => async dispatch => {
  await formPassportAdd(
    orgId,
    passportNumber,
    country,
    issuedOn,
    validUntil,
    fileId,
    isPrimary,
    contactId,
    onSuccess,
    dispatch,
    addPassportInCreateForm
  );
};

const addPassportInEditForm = createAction('circulationContacts/addPassportInEditForm');
export const addCirculationContactPassportInEditForm = (
  orgId,
  passportNumber,
  country,
  issuedOn,
  validUntil,
  fileId,
  isPrimary,
  onSuccess,
  contactId
) => async dispatch => {
  await formPassportAdd(
    orgId,
    passportNumber,
    country,
    issuedOn,
    validUntil,
    fileId,
    isPrimary,
    contactId,
    onSuccess,
    dispatch,
    addPassportInEditForm
  );
};

const addUtilityBillInCreateForm = createAction('circulationContacts/addUtilityBillInCreateForm');
export const addCirculationContactUtilityBillInCreateForm = (
  orgId,
  date,
  residenceAddress,
  type,
  fileId,
  isPrimary,
  onSuccess,
  contactId
) => async dispatch => {
  await formUtilityBillAdd(
    orgId,
    date,
    residenceAddress,
    type,
    fileId,
    isPrimary,
    onSuccess,
    contactId,
    dispatch,
    addUtilityBillInCreateForm
  );
};

const addUtilityBillInEditForm = createAction('circulationContacts/addUtilityBillInEditForm');
export const addCirculationContactUtilityBillInEditForm = (
  orgId,
  date,
  residenceAddress,
  type,
  fileId,
  isPrimary,
  onSuccess,
  contactId
) => async dispatch => {
  await formUtilityBillAdd(
    orgId,
    date,
    residenceAddress,
    type,
    fileId,
    isPrimary,
    onSuccess,
    contactId,
    dispatch,
    addUtilityBillInEditForm
  );
};

export const createCirculationContact = createAsyncThunk(
  'circulationContacts/create',
  async (
    {
      orgId,
      investorId,
      firstName,
      lastName,
      title,
      email,
      additionalEmail,
      sendNotices,
      mainRecipient,
      passports,
      utilityBills,
      cb
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await directorValidation(firstName, lastName, title, email);

      const createdPassports = await createPassports(
        passports,
        orgId,
        deleteCirculationContactPassportInCreateForm,
        dispatch
      );
      const createdUtilityBills = await createUtilityBills(
        utilityBills,
        orgId,
        deleteCirculationContactUtilityBillInCreateForm,
        dispatch
      );

      const res = await api.post(`/org/${orgId}/circulation_contact`, {
        investorId,
        firstName,
        lastName,
        title,
        email,
        additionalEmail,
        sendNotices,
        mainRecipient,
        passports: createdPassports.map(c => c.id),
        utility_bills: createdUtilityBills.map(c => c.id)
      });
      const response = res.data;

      dispatch(checkPreviousRecipient(response.previousRecipient));
      dispatch(investorOnboardingItemActions.listInvestorOnboardingItems(orgId));

      if (cb) {
        cb();
      }
      return response;
    } catch (error) {
      return rejectWithValue(
        new CustomError(error, [{ error: 'CIRCULATION_CONTACT_BR', formField: 'email' }])
      );
    }
  }
);

export const checkPreviousRecipient = previousRecipient => async dispatch => {
  if (previousRecipient) {
    if (previousRecipient.changed) {
      switch (previousRecipient.investorContactType) {
        case INVESTOR_CONTACTS_TYPES.CIRCULATION_CONTACT: {
          dispatch(resetCirculationContactMainRecipient(previousRecipient.investorContactId));
          break;
        }
        case INVESTOR_CONTACTS_TYPES.DIRECTOR: {
          dispatch({
            type: directorConstants.RESET_DIRECTOR_MAIN_RECIPIENT,
            payload: previousRecipient.investorContactId
          });
          break;
        }
        case INVESTOR_CONTACTS_TYPES.UBO: {
          dispatch({
            type: uboConstants.RESET_UBO_MAIN_RECIPIENT,
            payload: previousRecipient.investorContactId
          });
          break;
        }
      }
    }
  }
};

export const editCirculationContact = createAsyncThunk(
  'circulationContacts/edit',
  async (
    {
      orgId,
      contactId,
      investorId,
      firstName,
      lastName,
      title,
      email,
      additionalEmail,
      sendNotices,
      mainRecipient,
      passportsOld,
      passportsNew,
      utilityBillsOld,
      utilityBillsNew
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await directorValidation(firstName, lastName, title, email);

      const createdPassports = await createPassports(
        passportsNew,
        orgId,
        deleteCirculationContactPassportInEditForm,
        dispatch
      );
      const passports = List(createdPassports).concat(passportsOld);

      const createdUtilityBills = await createUtilityBills(
        utilityBillsNew,
        orgId,
        deleteCirculationContactUtilityBillInEditForm,
        dispatch
      );
      const utilityBills = List(createdUtilityBills).concat(utilityBillsOld);

      const res = await api.put(`/org/${orgId}/circulation_contact/${contactId}`, {
        investorId,
        firstName,
        lastName,
        title,
        email,
        additionalEmail,
        sendNotices,
        mainRecipient,
        passports: passports.map(c => c.id),
        utility_bills: utilityBills.map(c => c.id)
      });

      dispatch(checkPreviousRecipient(res.data.previousRecipient));
      dispatch(investorOnboardingItemActions.listInvestorOnboardingItems(orgId));
      return res.data;
    } catch (error) {
      return rejectWithValue(
        new CustomError(error, [
          { error: 'ADD_EMAIL_BR', formField: 'additionalEmail' },
          { error: 'UNABLE_DEL_BR' }
        ])
      );
    }
  }
);

export const copyCirculationContact = createAsyncThunk(
  'circulationContacts/copy',
  async ({ orgId, itemId, investorId }, { dispatch, rejectWithValue }) => {
    try {
      const res = await api.post(`/org/${orgId}/copy_circulation_contact`, {
        userInvestorId: itemId,
        investorId
      });

      const { utility_bills, passports } = res.data;
      for (const utilityBill of utility_bills) {
        dispatch({ type: utilityBillConstants.UTILITY_BILL_CREATE_SUCCESS, response: utilityBill });
      }
      for (const passport of passports) {
        dispatch({ type: passportConstants.PASSPORT_CREATE_SUCCESS, response: passport });
      }

      return {
        ...res.data,
        utility_bills: res.data.utility_bills.map(c => c.id),
        passports: res.data.passports.map(c => c.id)
      };
    } catch (error) {
      throw rejectWithValue(
        new CustomError(error, [
          { error: 'UTILITY_BILL_BR' },
          { error: 'PASSPORT_BR' },
          { error: 'CIRCULATION_CONTACT_BR' }
        ])
      );
    }
  }
);

const initialState = {
  ...generatedInitialState,
  ...createItemInitialState,
  ...editItemInitialState,
  createForm: {
    passports: [],
    utilityBills: []
  },
  editForm: {
    passports: [],
    utilityBills: []
  }
};

export const circulationContactsSlice = createSlice({
  name: rootName,
  initialState,
  reducers: {
    resetCirculationContactEditFormPassports: state => {
      state.editForm.passports = [];
    },
    resetCirculationContactEditFormUtilityBills: state => {
      state.editForm.utilityBills = [];
    },
    reloadCirculationContactError: state => {
      state.createError = null;
      state.editError = null;
    },
    deleteCirculationContactPassportInCreateForm: (state, payload) => {
      state.createForm.passports = state.createForm.passports.filter(p => p.id !== payload);
    },
    deleteCirculationContactPassportInEditForm: (state, payload) => {
      state.editForm.passports = state.editForm.passports.filter(p => p.id !== payload);
    },
    editCirculationContactPassportInCreateForm: (state, { payload }) => {
      state.createForm.passports = [
        ...state.createForm.passports.filter(p => p.id !== payload.id),
        payload
      ];
    },
    editCirculationContactPassportInEditForm: (state, { payload }) => {
      state.editForm.passports = [
        ...state.editForm.passports.filter(p => p.id !== payload.id),
        payload
      ];
    },
    deleteCirculationContactUtilityBillInCreateForm: (state, payload) => {
      state.createForm.utilityBills = state.createForm.utilityBills.filter(p => p.id !== payload);
    },
    deleteCirculationContactUtilityBillInEditForm: (state, payload) => {
      state.editForm.utilityBills = state.editForm.utilityBills.filter(p => p.id !== payload);
    },
    editCirculationContactUtilityBillInCreateForm: (state, { payload }) => {
      state.createForm.utilityBills = [
        ...state.createForm.utilityBills.filter(p => p.id !== payload.id),
        payload
      ];
    },
    editCirculationContactUtilityBillInEditForm: (state, { payload }) => {
      state.editForm.utilityBills = [
        ...state.editForm.utilityBills.filter(p => p.id !== payload.id),
        payload
      ];
    },
    resetCirculationContactMainRecipient: (state, { payload }) => {
      if (!state.items) return;

      const itemIndex = state.items.findIndex(val => val.id === payload);
      if (itemIndex === -1) return;

      const item = state.items[itemIndex];
      state.items.splice(itemIndex, 1, { ...item, mainRecipient: false });
    }
  },
  extraReducers: {
    ...generatedExtraReducers,
    ...getDefaultReducersForEntityCreate(createCirculationContact),
    ...getDefaultReducersForEntityEdit(editCirculationContact),
    // OTHERS
    [addPassportInCreateForm]: (state, action) => {
      state.createForm.passports.push({
        ...action.payload,
        contactId: action.payload.entityId
      });
    },
    [addPassportInEditForm]: (state, action) => {
      state.editForm.passports.push({
        ...action.payload,
        contactId: action.payload.entityId
      });
    },
    [addUtilityBillInCreateForm]: (state, action) => {
      state.createForm.utilityBills.push({
        ...action.payload,
        contactId: action.payload.entityId
      });
    },
    [addUtilityBillInEditForm]: (state, action) => {
      state.editForm.utilityBills.push({
        ...action.payload,
        contactId: action.payload.entityId
      });
    },
    [copyCirculationContact.fulfilled]: createItemFulfilled
  }
});

export const {
  resetCirculationContactEditFormPassports,
  resetCirculationContactEditFormUtilityBills,
  reloadCirculationContactError,
  deleteCirculationContactPassportInCreateForm,
  deleteCirculationContactPassportInEditForm,
  deleteCirculationContactUtilityBillInCreateForm,
  deleteCirculationContactUtilityBillInEditForm,
  editCirculationContactPassportInCreateForm,
  editCirculationContactPassportInEditForm,
  editCirculationContactUtilityBillInCreateForm,
  editCirculationContactUtilityBillInEditForm,
  resetCirculationContactMainRecipient
} = circulationContactsSlice.actions;

export default circulationContactsSlice.reducer;
