import { USER_INVESTOR_TYPES } from 'views/Admin/SettingsEmailNotifications/Recipients/UserInvestorsSection';

export const getFullName = ({ title, firstName, lastName }) =>
  `${title ? title + ' ' : ''}${firstName} ${lastName}`;

export const customizedTableRecipientsColumns = {
  getInvestorNameColumn: t => ({
    name: 'investorName',
    title: t('Investor'),
    dataType: 'generic',
    filterType: 'select'
  }),
  getUserInvestorTypeColumn: t => ({
    name: 'userInvestorType',
    title: t('Type'),
    dataType: 'generic',
    filterType: 'select',
    optionsInsideFilterSelector: [
      t(USER_INVESTOR_TYPES.UBO),
      t(USER_INVESTOR_TYPES.DIRECTOR),
      t(USER_INVESTOR_TYPES.CIRCULATION_CONTACT)
    ],
    displayCellFunc: value => t(value),
    getCellText: value => t(value)
  }),
  getNameColumn: t => ({
    name: 'name',
    title: t('UbosTable.Name'),
    dataType: 'generic',
    filterType: 'generic'
  }),
  getEmailColumn: t => ({
    name: 'email',
    title: t('UbosTable.Email'),
    dataType: 'generic',
    filterType: 'generic'
  }),
  getAdditionalEmailColumn: t => ({
    name: 'additionalEmail',
    title: t('AdditionalEmail'),
    dataType: 'generic',
    filterType: 'generic'
  })
};

export const NOTIFICATION_TYPES = {
  MARKETPLACE: 'MARKETPLACE'
};
