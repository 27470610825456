export const investmentTransactionConstants = {
  INVESTMENT_TRANSACTION_CREATE_LOADING: 'INVESTMENT_TRANSACTION_CREATE_LOADING',
  INVESTMENT_TRANSACTION_CREATE_SUCCESS: 'INVESTMENT_TRANSACTION_CREATE_SUCCESS',
  INVESTMENT_TRANSACTION_CREATE_FAILURE: 'INVESTMENT_TRANSACTION_CREATE_FAILURE',
  INVESTMENT_TRANSACTION_ERROR_RELOAD: 'INVESTMENT_TRANSACTION_ERROR_RELOAD',

  INVESTMENT_TRANSACTION_LIST_LOADING: 'INVESTMENT_TRANSACTION_LIST_LOADING',
  INVESTMENT_TRANSACTION_LIST_SUCCESS: 'INVESTMENT_TRANSACTION_LIST_SUCCESS',
  INVESTMENT_TRANSACTION_LIST_FAILURE: 'INVESTMENT_TRANSACTION_LIST_FAILURE',

  INVESTMENT_TRANSACTION_EDIT_LOADING: 'INVESTMENT_TRANSACTION_EDIT_LOADING',
  INVESTMENT_TRANSACTION_EDIT_SUCCESS: 'INVESTMENT_TRANSACTION_EDIT_SUCCESS',
  INVESTMENT_TRANSACTION_EDIT_FAILURE: 'INVESTMENT_TRANSACTION_EDIT_FAILURE',

  INVESTMENT_TRANSACTION_CHANGE_ROUND_OPTIMISTIC: 'INVESTMENT_TRANSACTION_CHANGE_ROUND_OPTIMISTIC',
  INVESTMENT_TRANSACTION_CHANGE_ROUND_SUCCESS: 'INVESTMENT_TRANSACTION_CHANGE_ROUND_SUCCESS',
  INVESTMENT_TRANSACTION_CHANGE_ROUND_FAILURE: 'INVESTMENT_TRANSACTION_CHANGE_ROUND_FAILURE',

  INVESTMENT_TRANSACTION_DELETE_LOADING: 'INVESTMENT_TRANSACTION_DELETE_LOADING',
  INVESTMENT_TRANSACTION_DELETE_SUCCESS: 'INVESTMENT_TRANSACTION_DELETE_SUCCESS',
  INVESTMENT_TRANSACTION_DELETE_FAILURE: 'INVESTMENT_TRANSACTION_DELETE_FAILURE'
};
