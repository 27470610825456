import { List } from 'immutable';
import { commitmentRecordConstants } from '../constants';

const INITIAL_STATE = {
  createError: []
};

export function commitment_records(state = INITIAL_STATE, action) {
  switch (action.type) {
    case commitmentRecordConstants.COMMITMENT_RECORD_LIST_LOADING:
      return {
        ...state,
        listLoading: true
      };
    case commitmentRecordConstants.COMMITMENT_RECORD_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case commitmentRecordConstants.COMMITMENT_RECORD_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: [],
        listError: action.error
      };
    case commitmentRecordConstants.COMMITMENT_RECORD_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case commitmentRecordConstants.COMMITMENT_RECORD_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      return {
        ...state,
        items: items.push(action.payload.response),
        createLoading: false,
        createError: state.createError.filter(error => error.commitmentId !== action.commitmentId)
      };
    }

    case commitmentRecordConstants.COMMITMENT_RECORD_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError:
          state.createError == null ? [action.error] : [...state.createError, action.error]
      };

    case commitmentRecordConstants.COMMITMENT_RECORD_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case commitmentRecordConstants.COMMITMENT_RECORD_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const commitmentRecordIndex = state.items.findIndex(val => val.id == action.response.id);

      if (commitmentRecordIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(commitmentRecordIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case commitmentRecordConstants.COMMITMENT_RECORD_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case commitmentRecordConstants.COMMITMENT_RECORD_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case commitmentRecordConstants.COMMITMENT_RECORD_DELETE_SUCCESS: {
      const { commitmentRecordId } = action;

      if (!state.items) {
        return state;
      }

      const commitmentRecordIndex = state.items.findIndex(val => val.id == commitmentRecordId);

      if (commitmentRecordIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(commitmentRecordIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case commitmentRecordConstants.COMMITMENT_RECORD_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };
    case commitmentRecordConstants.COMMITMENT_RECORD_ERROR_RELOAD:
      return {
        ...state,
        createError: action.commitmentId
          ? state.createError.filter(error => error.commitmentId !== action.commitmentId)
          : [],
        editError: null
      };

    default:
      return state;
  }
}
