export const fundDocConstants = {
  FUND_DOC_CREATE_LOADING: 'FUND_DOC_CREATE_LOADING',
  FUND_DOC_CREATE_SUCCESS: 'FUND_DOC_CREATE_SUCCESS',
  FUND_DOC_CREATE_FAILURE: 'FUND_DOC_CREATE_FAILURE',
  FUND_DOC_ERROR_RELOAD: 'FUND_DOC_ERROR_RELOAD',

  FUND_DOC_LIST_LOADING: 'FUND_DOC_LIST_LOADING',
  FUND_DOC_LIST_SUCCESS: 'FUND_DOC_LIST_SUCCESS',
  FUND_DOC_LIST_FAILURE: 'FUND_DOC_LIST_FAILURE',
  FUND_DOC_LIST_CLEAR: 'FUND_DOC_LIST_CLEAR',

  FUND_DOC_EDIT_LOADING: 'FUND_DOC_EDIT_LOADING',
  FUND_DOC_EDIT_SUCCESS: 'FUND_DOC_EDIT_SUCCESS',
  FUND_DOC_EDIT_FAILURE: 'FUND_DOC_EDIT_FAILURE',

  FUND_DOC_DELETE_LOADING: 'FUND_DOC_DELETE_LOADING',
  FUND_DOC_DELETE_SUCCESS: 'FUND_DOC_DELETE_SUCCESS',
  FUND_DOC_DELETE_FAILURE: 'FUND_DOC_DELETE_FAILURE'
};
