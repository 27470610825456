export const appConnectionConstants = {
  APP_CONNECTION_CREATE_LOADING: 'APP_CONNECTION_CREATE_LOADING',
  APP_CONNECTION_CREATE_SUCCESS: 'APP_CONNECTION_CREATE_SUCCESS',
  APP_CONNECTION_CREATE_FAILURE: 'APP_CONNECTION_CREATE_FAILURE',

  APP_CONNECTION_LIST_LOADING: 'APP_CONNECTION_LIST_LOADING',
  APP_CONNECTION_LIST_SUCCESS: 'APP_CONNECTION_LIST_SUCCESS',
  APP_CONNECTION_LIST_FAILURE: 'APP_CONNECTION_LIST_FAILURE',

  APP_CONNECTION_EDIT_LOADING: 'APP_CONNECTION_EDIT_LOADING',
  APP_CONNECTION_EDIT_SUCCESS: 'APP_CONNECTION_EDIT_SUCCESS',
  APP_CONNECTION_EDIT_FAILURE: 'APP_CONNECTION_EDIT_FAILURE',

  APP_CONNECTION_DELETE_LOADING: 'APP_CONNECTION_DELETE_LOADING',
  APP_CONNECTION_DELETE_SUCCESS: 'APP_CONNECTION_DELETE_SUCCESS',
  APP_CONNECTION_DELETE_FAILURE: 'APP_CONNECTION_DELETE_FAILURE',

  SET_APP_CONNECTION_PARAMETERS: 'SET_APP_CONNECTION_PARAMETERS'
};
