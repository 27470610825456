export const kycCompanyConstants = {
  KYC_COMPANY_CREATE_LOADING: 'KYC_COMPANY_CREATE_LOADING',
  KYC_COMPANY_CREATE_SUCCESS: 'KYC_COMPANY_CREATE_SUCCESS',
  KYC_COMPANY_CREATE_FAILURE: 'KYC_COMPANY_CREATE_FAILURE',
  KYC_COMPANY_ERROR_RELOAD: 'KYC_COMPANY_ERROR_RELOAD',

  KYC_COMPANY_LIST_LOADING: 'KYC_COMPANY_LIST_LOADING',
  KYC_COMPANY_LIST_SUCCESS: 'KYC_COMPANY_LIST_SUCCESS',
  KYC_COMPANY_LIST_FAILURE: 'KYC_COMPANY_LIST_FAILURE',

  KYC_COMPANY_EDIT_LOADING: 'KYC_COMPANY_EDIT_LOADING',
  KYC_COMPANY_EDIT_SUCCESS: 'KYC_COMPANY_EDIT_SUCCESS',
  KYC_COMPANY_EDIT_FAILURE: 'KYC_COMPANY_EDIT_FAILURE',

  KYC_COMPANY_DELETE_LOADING: 'KYC_COMPANY_DELETE_LOADING',
  KYC_COMPANY_DELETE_SUCCESS: 'KYC_COMPANY_DELETE_SUCCESS',
  KYC_COMPANY_DELETE_FAILURE: 'KYC_COMPANY_DELETE_FAILURE'
};
