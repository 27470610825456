import { tableFiltersValuesConstants } from '../constants/table_filters_values.constants';

const INITIAL_STATE = [];

export function tables_filters_values(state = INITIAL_STATE, action) {
  switch (action.type) {
    case tableFiltersValuesConstants.EDIT_FILTER_VALUE: {
      const tablesWithoutChanges = state.filter(t => t.tableName !== action.payload.tableName);
      return [...tablesWithoutChanges, action.payload];
    }

    default:
      return state;
  }
}
