export const bankAccountConstants = {
  BANK_ACCOUNT_CREATE_LOADING: 'BANK_ACCOUNT_CREATE_LOADING',
  BANK_ACCOUNT_CREATE_SUCCESS: 'BANK_ACCOUNT_CREATE_SUCCESS',
  BANK_ACCOUNT_CREATE_FAILURE: 'BANK_ACCOUNT_CREATE_FAILURE',
  BANK_ACCOUNT_ERROR_RELOAD: 'BANK_ACCOUNT_ERROR_RELOAD',

  BANK_ACCOUNT_LIST_LOADING: 'BANK_ACCOUNT_LIST_LOADING',
  BANK_ACCOUNT_LIST_SUCCESS: 'BANK_ACCOUNT_LIST_SUCCESS',
  BANK_ACCOUNT_LIST_FAILURE: 'BANK_ACCOUNT_LIST_FAILURE',

  BANK_ACCOUNT_EDIT_LOADING: 'BANK_ACCOUNT_EDIT_LOADING',
  BANK_ACCOUNT_EDIT_SUCCESS: 'BANK_ACCOUNT_EDIT_SUCCESS',
  BANK_ACCOUNT_EDIT_FAILURE: 'BANK_ACCOUNT_EDIT_FAILURE',

  BANK_ACCOUNT_DELETE_LOADING: 'BANK_ACCOUNT_DELETE_LOADING',
  BANK_ACCOUNT_DELETE_SUCCESS: 'BANK_ACCOUNT_DELETE_SUCCESS',
  BANK_ACCOUNT_DELETE_FAILURE: 'BANK_ACCOUNT_DELETE_FAILURE'
};
