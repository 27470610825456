export const loanReceivedConstants = {
  LOAN_RECEIVED_CREATE_LOADING: 'LOAN_RECEIVED_CREATE_LOADING',
  LOAN_RECEIVED_CREATE_SUCCESS: 'LOAN_RECEIVED_CREATE_SUCCESS',
  LOAN_RECEIVED_CREATE_FAILURE: 'LOAN_RECEIVED_CREATE_FAILURE',
  LOAN_RECEIVED_ERROR_RELOAD: 'LOAN_RECEIVED_ERROR_RELOAD',

  LOAN_RECEIVED_LIST_LOADING: 'LOAN_RECEIVED_LIST_LOADING',
  LOAN_RECEIVED_LIST_SUCCESS: 'LOAN_RECEIVED_LIST_SUCCESS',
  LOAN_RECEIVED_LIST_FAILURE: 'LOAN_RECEIVED_LIST_FAILURE',
  LOAN_RECEIVED_LIST_CLEAR: 'LOAN_RECEIVED_LIST_CLEAR',

  LOAN_RECEIVED_EDIT_LOADING: 'LOAN_RECEIVED_EDIT_LOADING',
  LOAN_RECEIVED_EDIT_SUCCESS: 'LOAN_RECEIVED_EDIT_SUCCESS',
  LOAN_RECEIVED_EDIT_FAILURE: 'LOAN_RECEIVED_EDIT_FAILURE',

  LOAN_RECEIVED_DELETE_LOADING: 'LOAN_RECEIVED_DELETE_LOADING',
  LOAN_RECEIVED_DELETE_SUCCESS: 'LOAN_RECEIVED_DELETE_SUCCESS',
  LOAN_RECEIVED_DELETE_FAILURE: 'LOAN_RECEIVED_DELETE_FAILURE'
};
