import { List } from 'immutable';
import { investmentTransactionConstants } from '../constants';

export function investment_transactions(state = {}, action) {
  switch (action.type) {
    case investmentTransactionConstants.INVESTMENT_TRANSACTION_LIST_LOADING:
      return {
        ...state,
        listLoading: true
      };
    case investmentTransactionConstants.INVESTMENT_TRANSACTION_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case investmentTransactionConstants.INVESTMENT_TRANSACTION_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: [],
        listError: action.error
      };
    case investmentTransactionConstants.INVESTMENT_TRANSACTION_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case investmentTransactionConstants.INVESTMENT_TRANSACTION_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      return {
        ...state,
        items: items.push(action.response),
        createLoading: false,
        createError: null
      };
    }
    case investmentTransactionConstants.INVESTMENT_TRANSACTION_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };
    case investmentTransactionConstants.INVESTMENT_TRANSACTION_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case investmentTransactionConstants.INVESTMENT_TRANSACTION_CHANGE_ROUND_OPTIMISTIC:
      return {
        ...state,
        items: state.items.map(item => {
          if (item.id === action.payload.investmentTransactionId) {
            return { ...item, roundId: action.payload.roundId };
          }
          return item;
        }),
        editLoading: true,
        oldRoundId: state.items.find(i => i.id === action.payload.investmentTransactionId).roundId
      };

    case investmentTransactionConstants.INVESTMENT_TRANSACTION_CHANGE_ROUND_SUCCESS:
      return {
        ...state,
        editLoading: false,
        oldRoundId: null
      };

    case investmentTransactionConstants.INVESTMENT_TRANSACTION_CHANGE_ROUND_FAILURE:
      return {
        ...state,
        items: state.items.map(item => {
          if (item.id === action.payload.investmentTransactionId) {
            return { ...item, roundId: state.oldRoundId };
          }
          return item;
        }),
        editError: action.error,
        editLoading: false,
        oldRoundId: null
      };

    case investmentTransactionConstants.INVESTMENT_TRANSACTION_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const investmentTransactionIndex = state.items.findIndex(val => val.id == action.response.id);

      if (investmentTransactionIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(investmentTransactionIndex, action.response),
        editLoading: false,
        editError: null
      };
    }

    case investmentTransactionConstants.INVESTMENT_TRANSACTION_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case investmentTransactionConstants.INVESTMENT_TRANSACTION_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case investmentTransactionConstants.INVESTMENT_TRANSACTION_DELETE_SUCCESS: {
      const { investmentTransactionId } = action;

      if (!state.items) {
        return state;
      }

      const investmentTransactionIndex = state.items.findIndex(
        val => val.id == investmentTransactionId
      );

      if (investmentTransactionIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(investmentTransactionIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case investmentTransactionConstants.INVESTMENT_TRANSACTION_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };
    case investmentTransactionConstants.INVESTMENT_TRANSACTION_ERROR_RELOAD:
      return {
        ...state,
        createError: null,
        editError: null
      };

    default:
      return state;
  }
}
