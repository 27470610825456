import { List } from 'immutable';
import { managerConstants } from '../constants';

export function managers(state = {}, action) {
  switch (action.type) {
    case managerConstants.MANAGER_LIST_LOADING:
      return {
        ...state,
        items: null,
        listLoading: true
      };
    case managerConstants.MANAGER_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case managerConstants.MANAGER_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: null,
        listError: action.error
      };
    case managerConstants.MANAGER_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case managerConstants.MANAGER_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      const isManagerAlreadyExist = items.find(m => m.id === action.response.id);
      return {
        ...state,
        items: isManagerAlreadyExist ? items : items.push(action.response),
        createLoading: false,
        createError: null
      };
    }

    case managerConstants.MANAGER_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };

    case managerConstants.MANAGER_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case managerConstants.MANAGER_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const managerIndex = state.items.findIndex(val => val.id == action.response.id);

      if (managerIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(managerIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case managerConstants.MANAGER_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case managerConstants.MANAGER_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case managerConstants.MANAGER_DELETE_SUCCESS: {
      const { managerId } = action;

      if (!state.items) {
        return state;
      }

      const managerIndex = state.items.findIndex(val => val.id == managerId);

      if (managerIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(managerIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case managerConstants.MANAGER_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };
    case managerConstants.MANAGER_ERROR_RELOAD:
      return {
        ...state,
        createError: null,
        editError: null
      };

    default:
      return state;
  }
}
