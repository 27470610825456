import React, { useEffect } from 'react';
import axios from 'axios';
import { history } from '../../history';
import { store } from '../../store';
import { logout } from 'actions/user.actions';

const TOO_MANY_COMPANIES_OR_INVESTORS_ERROR_MESSAGE =
  "We couldn't change your plan, too many companies or investors,in your portfolio, please contact your administrator";

export const axiosErrorHandler = Wrapped => {
  const AxiosErrorHandler = props => {
    useEffect(() => {
      axios.interceptors.response.use(
        response => response,
        error => {
          if (error.response) {
            const message = error.response.data && error.response.data.message;

            if (error.response.status === 401) {
              if (store && store.dispatch) {
                store.dispatch(logout(undefined, true));
              }
            }

            if (
              error.response.status === 403 &&
              message !== TOO_MANY_COMPANIES_OR_INVESTORS_ERROR_MESSAGE &&
              !error.response.data.path.includes('finmars/pl-report') &&
              !error.response.data.path.includes('finmars/balance-report')
            ) {
              history.push('/access_denied', history.location.pathname);
            }
          }
          return Promise.reject(error);
        }
      );
    });
    return <Wrapped {...props} />;
  };
  return AxiosErrorHandler;
};
