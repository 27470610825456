import { investorOnboardingItemConstants } from '../constants';

import * as investorOnboardingItemService from 'services/investor_onboarding_item.service';

export function listInvestorOnboardingItems(orgId) {
  return async dispatch => {
    try {
      dispatch({ type: investorOnboardingItemConstants.INVESTOR_ONBOARDING_ITEM_LIST_LOADING });

      const response = await investorOnboardingItemService.listInvestorOnboardingItems(orgId);

      dispatch({
        type: investorOnboardingItemConstants.INVESTOR_ONBOARDING_ITEM_LIST_SUCCESS,
        response
      });
    } catch (error) {
      dispatch({
        type: investorOnboardingItemConstants.INVESTOR_ONBOARDING_ITEM_LIST_FAILURE,
        error
      });
    }
  };
}

export function signInvestorOnboardingItem(orgId, onboardingItemId) {
  return async dispatch => {
    try {
      dispatch({ type: investorOnboardingItemConstants.INVESTOR_ONBOARDING_ITEM_SIGN_LOADING });

      const response = await investorOnboardingItemService.signInvestorOnboardingItem(
        orgId,
        onboardingItemId
      );

      dispatch({
        type: investorOnboardingItemConstants.INVESTOR_ONBOARDING_ITEM_SIGN_SUCCESS,
        response
      });
    } catch (error) {
      dispatch({
        type: investorOnboardingItemConstants.INVESTOR_ONBOARDING_ITEM_SIGN_FAILURE,
        error
      });
    }
  };
}
