import { List } from 'immutable';
import { xeroContactConstants } from '../constants';

export function xero_contacts(state = {}, action) {
  switch (action.type) {
    case xeroContactConstants.XERO_CONTACT_LIST_LOADING:
      return {
        ...state,
        listLoading: true
      };
    case xeroContactConstants.XERO_CONTACT_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case xeroContactConstants.XERO_CONTACT_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: [],
        listError: action.error
      };

    case xeroContactConstants.XERO_CONTACT_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case xeroContactConstants.XERO_CONTACT_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const xeroContactIndex = state.items.findIndex(val => val.id == action.response.id);

      if (xeroContactIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(xeroContactIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case xeroContactConstants.XERO_CONTACT_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    default:
      return state;
  }
}
