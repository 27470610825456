export const billingConstants = {
  BILLING_CARD_LOADING: 'BILLING_CARD_LOADING',
  BILLING_CARD_SUCCESS: 'BILLING_CARD_SUCCESS',
  BILLING_CARD_FAILURE: 'BILLING_CARD_FAILURE',
  BILLING_CARD_ERROR_RELOAD: 'BILLING_CARD_ERROR_RELOAD',

  BILLING_CARD_CREATE_LOADING: 'BILLING_CARD_CREATE_LOADING',
  BILLING_CARD_CREATE_SUCCESS: 'BILLING_CARD_CREATE_SUCCESS',
  BILLING_CARD_CREATE_FAILURE: 'BILLING_CARD_CREATE_FAILURE',
  BILLING_CARD_CREATE_ERROR_RELOAD: 'BILLING_CARD_CREATE_ERROR_RELOAD',

  BILLING_CARD_EDIT_LOADING: 'BILLING_CARD_EDIT_LOADING',
  BILLING_CARD_EDIT_SUCCESS: 'BILLING_CARD_EDIT_SUCCESS',
  BILLING_CARD_EDIT_FAILURE: 'BILLING_CARD_EDIT_FAILURE',

  BILLING_CARD_DELETE_LOADING: 'BILLING_CARD_DELETE_LOADING',
  BILLING_CARD_DELETE_SUCCESS: 'BILLING_CARD_DELETE_SUCCESS',
  BILLING_CARD_DELETE_FAILURE: 'BILLING_CARD_DELETE_FAILURE',

  BILLING_DETAILS_LOADING: 'BILLING_DETAILS_LOADING',
  BILLING_DETAILS_SUCCESS: 'BILLING_DETAILS_SUCCESS',
  BILLING_DETAILS_FAILURE: 'BILLING_DETAILS_FAILURE',

  BILLING_DETAILS_EDIT_LOADING: 'BILLING_DETAILS_EDIT_LOADING',
  BILLING_DETAILS_EDIT_SUCCESS: 'BILLING_DETAILS_EDIT_SUCCESS',
  BILLING_DETAILS_EDIT_FAILURE: 'BILLING_DETAILS_EDIT_FAILURE',
  BILLING_DETAILS_EDIT_ERROR_RELOAD: 'BILLING_DETAILS_EDIT_ERROR_RELOAD',

  INVOICES_LIST_LOADING: 'INVOICES_LIST_LOADING',
  INVOICES_LIST_SUCCESS: 'INVOICES_LIST_SUCCESS',
  INVOICES_LIST_FAILURE: 'INVOICES_LIST_FAILURE',

  SUBSCRIPTION_INFO_LOADING: 'SUBSCRIPTION_INFO_LOADING',
  SUBSCRIPTION_INFO_SUCCESS: 'SUBSCRIPTION_INFO_SUCCESS',
  SUBSCRIPTION_INFO_FAILURE: 'SUBSCRIPTION_INFO_FAILURE',

  BRAINTREE_CLIENT_TOKEN_LOADING: 'BRAINTREE_CLIENT_TOKEN_LOADING',
  BRAINTREE_CLIENT_TOKEN_SUCCESS: 'BRAINTREE_CLIENT_TOKEN_SUCCESS',
  BRAINTREE_CLIENT_TOKEN_FAILURE: 'BRAINTREE_CLIENT_TOKEN_FAILURE',

  BRAINTREE_NONCE_LOADING: 'BRAINTREE_NONCE_LOADING',
  BRAINTREE_NONCE_SUCCESS: 'BRAINTREE_NONCE_SUCCESS',
  BRAINTREE_NONCE_FAILURE: 'BRAINTREE_NONCE_FAILURE',

  BUY_SUBSCRIPTION_LOADING: 'BUY_SUBSCRIPTION_LOADING',
  BUY_SUBSCRIPTION_SUCCESS: 'BUY_SUBSCRIPTION_SUCCESS',
  BUY_SUBSCRIPTION_FAILURE: 'BUY_SUBSCRIPTION_FAILURE',

  AIRR_FREE_PLAN: 'AIRR_FREE_PLAN',
  AIRR_STANDARD_MONTHLY_PLAN: 'AIRR_STANDARD_MONTHLY_PLAN',
  AIRR_STANDARD_ANNUAL_PLAN: 'AIRR_STANDARD_ANNUAL_PLAN',

  CHARGEBEE_SUBSCRIPTION_SET_CLEAR: 'CHARGEBEE_SUBSCRIPTION_SET_CLEAR',
  CHARGEBEE_SUBSCRIPTION_SET_LOADING: 'CHARGEBEE_SUBSCRIPTION_SET_LOADING',
  CHARGEBEE_SUBSCRIPTION_SET_SUCCESS: 'CHARGEBEE_SUBSCRIPTION_SET_SUCCESS',
  CHARGEBEE_SUBSCRIPTION_SET_FAILURE: 'CHARGEBEE_SUBSCRIPTION_SET_FAILURE',

  INVOICE_PDF_LOADING: 'INVOICE_PDF_LOADING',
  INVOICE_PDF_SUCCESS: 'INVOICE_PDF_SUCCESS',
  INVOICE_PDF_FAILURE: 'INVOICE_PDF_FAILURE'
};
