import { List } from 'immutable';
import { onboardingItemConstants, onboardingProcessConstants } from '../constants';

export function onboarding_items(state = {}, action) {
  switch (action.type) {
    case onboardingItemConstants.ONBOARDING_ITEM_LIST_LOADING:
      return {
        ...state,
        items: null,
        listLoading: true
      };
    case onboardingItemConstants.ONBOARDING_ITEM_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case onboardingItemConstants.ONBOARDING_ITEM_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: null,
        listError: action.error
      };
    case onboardingItemConstants.ONBOARDING_ITEM_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case onboardingItemConstants.ONBOARDING_ITEM_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      return {
        ...state,
        items: items.push(action.response),
        createLoading: false,
        createError: null
      };
    }
    case onboardingItemConstants.ONBOARDING_ITEM_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };

    case onboardingItemConstants.ONBOARDING_ITEM_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case onboardingItemConstants.ONBOARDING_ITEM_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const onboardingItemIndex = state.items.findIndex(val => val.id == action.response.id);

      if (onboardingItemIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(onboardingItemIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case onboardingItemConstants.ONBOARDING_ITEM_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case onboardingItemConstants.ONBOARDING_ITEM_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case onboardingItemConstants.ONBOARDING_ITEM_DELETE_SUCCESS: {
      const { onboardingItemId } = action;

      if (!state.items) {
        return state;
      }

      const onboardingItemIndex = state.items.findIndex(val => val.id == onboardingItemId);

      if (onboardingItemIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(onboardingItemIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case onboardingProcessConstants.ONBOARDING_PROCESS_EDIT_SUCCESS:
    case onboardingProcessConstants.ONBOARDING_PROCESS_CREATE_SUCCESS: {
      let { items } = state;

      const { onboarding_items } = action.response;

      for (const onboarding_item of onboarding_items) {
        const onboardingItemIndex = state.items.findIndex(val => val.id === onboarding_item.id);

        if (onboardingItemIndex == -1) {
          continue;
        }

        const newOnboardingItem = {
          ...state.items.get(onboardingItemIndex),
          onboardingProcessId: action.response.id
        };

        items = items.set(onboardingItemIndex, newOnboardingItem);
      }

      return {
        ...state,
        items
      };
    }
    case onboardingItemConstants.ONBOARDING_ITEM_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };
    case onboardingItemConstants.ONBOARDING_ITEM_ERROR_RELOAD:
      return {
        ...state,
        createError: null,
        editError: null
      };

    default:
      return state;
  }
}
