export const itemsInitialState = {
  items: null,
  listLoading: false,
  listError: null
};

export const createItemInitialState = {
  createLoading: false,
  createError: null
};

export const editItemInitialState = {
  editLoading: false,
  editError: null
};

export const ERROR_TYPES = {
  USER_ERROR: 'USER_ERROR',
  SYSTEM_ERROR: 'SYSTEM_ERROR'
};
