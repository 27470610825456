export const shareholderConstants = {
  SHAREHOLDER_CREATE_LOADING: 'SHAREHOLDER_CREATE_LOADING',
  SHAREHOLDER_CREATE_SUCCESS: 'SHAREHOLDER_CREATE_SUCCESS',
  SHAREHOLDER_CREATE_FAILURE: 'SHAREHOLDER_CREATE_FAILURE',

  SHAREHOLDER_LIST_LOADING: 'SHAREHOLDER_LIST_LOADING',
  SHAREHOLDER_LIST_SUCCESS: 'SHAREHOLDER_LIST_SUCCESS',
  SHAREHOLDER_LIST_FAILURE: 'SHAREHOLDER_LIST_FAILURE',
  SHAREHOLDER_LIST_CLEAR: 'SHAREHOLDER_LIST_CLEAR',

  SHAREHOLDER_EDIT_LOADING: 'SHAREHOLDER_EDIT_LOADING',
  SHAREHOLDER_EDIT_SUCCESS: 'SHAREHOLDER_EDIT_SUCCESS',
  SHAREHOLDER_EDIT_FAILURE: 'SHAREHOLDER_EDIT_FAILURE',

  SHAREHOLDER_DELETE_LOADING: 'SHAREHOLDER_DELETE_LOADING',
  SHAREHOLDER_DELETE_SUCCESS: 'SHAREHOLDER_DELETE_SUCCESS',
  SHAREHOLDER_DELETE_FAILURE: 'SHAREHOLDER_DELETE_FAILURE',

  SHAREHOLDER_ERROR_RELOAD: 'SHAREHOLDER_ERROR_RELOAD'
};
