import React from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'reactstrap';

const CustomTabsNav = ({ activeTab, toggle, children }) => {
  return (
    <Nav tabs>
      {React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { activeTab, toggle });
        }
        return child;
      })}
    </Nav>
  );
};

CustomTabsNav.propTypes = {
  activeTab: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired
};

export default CustomTabsNav;
