import { List } from 'immutable';
import { leadConstants } from '../constants';
import { sortByAsc } from 'views/helpers';

const DEFAULT_STATE = {
  createForm: {
    persons: [],
    investors: [],
    errors: { persons: {}, investors: {} }
  },
  editForm: {
    persons: [],
    investors: [],
    errors: { persons: {}, investors: {} }
  }
};

export function leads(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case leadConstants.LEAD_LIST_LOADING:
      return {
        ...state,
        items: null,
        listLoading: true
      };
    case leadConstants.LEAD_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case leadConstants.LEAD_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: null,
        listError: action.error
      };

    case leadConstants.LEAD_LIST_CLEAR:
      return {
        ...state,
        items: null
      };

    case leadConstants.LEAD_TO_DECLINE_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case leadConstants.LEAD_TO_DECLINE_SUCCESS: {
      const leadId = action.lead['company.id'];

      if (!state.items) {
        return {
          state,
          editLoading: false
        };
      }

      const leadIndx = state.items.findIndex(val => val['company.id'] === leadId);

      if (leadIndx >= 0) {
        return {
          ...state,
          items: state.items.set(leadIndx, action.lead),
          editLoading: false
        };
      }

      return {
        ...state,
        editLoading: false
      };
    }

    case leadConstants.LEAD_TO_DECLINE_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case leadConstants.LEAD_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case leadConstants.LEAD_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      return {
        ...state,
        items: sortByAsc(items.push(action.response), 'company.name'),
        createLoading: false,
        createError: null
      };
    }
    case leadConstants.LEAD_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };

    case leadConstants.LEAD_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case leadConstants.LEAD_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      if (action.payload && action.payload.leadId) {
        const index = state.items.findIndex(val => val['company.id'] === action.payload.leadId);

        if (index === -1) {
          return state;
        }

        const itemIndex = state.items
          .get(index)
          [action.payload.type].findIndex(
            item =>
              item[`${[action.payload.type]}.id`] ===
              action.payload.item[`${[action.payload.type]}.id`]
          );
        const updatedItems = [...state.items.get(index)[action.payload.type]];
        updatedItems.splice(itemIndex, 1, action.payload.item);
        return {
          ...state,
          items: state.items.set(index, {
            ...state.items.get(index),
            [action.payload.type]: updatedItems
          }),

          editLoading: false,
          editError: null
        };
      }

      const leadIndex = state.items.findIndex(
        val => val['company.id'] === action.response['company.id']
      );

      if (leadIndex === -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(leadIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case leadConstants.LEAD_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case leadConstants.LEAD_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case leadConstants.LEAD_DELETE_SUCCESS: {
      const { leadId } = action;

      if (!state.items) {
        return state;
      }

      const leadIndex = state.items.findIndex(val => val['company.id'] === leadId);

      const leadIndexInPreview =
        state.previewLeads && state.previewLeads.items
          ? state.previewLeads.items.findIndex(val => val['company.id'] === leadId)
          : -1;
      if (leadIndex === -1) {
        return state;
      }

      let previewLeads = null;
      if (leadIndexInPreview >= 0) {
        previewLeads = state.previewLeads.items.set(leadIndexInPreview, {
          ...state.previewLeads.items.get(leadIndexInPreview),
          'company.id': null,
          'company.statusId': null,
          'company.statusType': null
        });
      }
      return {
        ...state,
        items: state.items.delete(leadIndex),
        deleteLoading: false,
        deleteError: null,
        previewLeads: {
          items: previewLeads
        }
      };
    }
    case leadConstants.LEAD_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };
    case leadConstants.LEAD_ERROR_RELOAD:
      return {
        ...state,
        createError: null,
        editError: null
      };

    // LEAD_SF_EXPORT
    case leadConstants.LEAD_SF_EXPORT_LOADING:
      return {
        ...state,
        sfExportLoading: true
      };
    case leadConstants.LEAD_SF_EXPORT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const leadIndex = state.items.findIndex(val => val.id == action.response.id);

      if (leadIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(leadIndex, action.response),
        sfExportLoading: false,
        sfExportError: null
      };
    }
    case leadConstants.LEAD_SF_EXPORT_FAILURE:
      return {
        ...state,
        sfExportLoading: false,
        sfExportError: action.error
      };

    // LEAD_SF_EXPORT_ALL
    case leadConstants.LEAD_SF_EXPORT_ALL_LOADING:
      return {
        ...state,
        sfExportAllLoading: true
      };
    case leadConstants.LEAD_SF_EXPORT_ALL_SUCCESS: {
      if (!state.items) {
        return state;
      }

      action.response.forEach(lead => {
        const leadIndex = state.items.findIndex(val => val.id == lead.id);

        if (leadIndex == -1) {
          state = { ...state, items: state.items.add(lead) };
        } else {
          state = { ...state, items: state.items.set(leadIndex, lead) };
        }
      });

      return {
        ...state,
        sfExportAllLoading: false,
        sfExportAllError: null
      };
    }
    case leadConstants.LEAD_SF_EXPORT_ALL_FAILURE:
      return {
        ...state,
        sfExportAllLoading: false,
        sfExportAllError: action.error
      };

    case leadConstants.PREVIEW_LEADS_LIST_LOADING:
      return {
        ...state,
        previewLeadsLoading: true
      };

    case leadConstants.PREVIEW_LEADS_LIST_SUCCESS:
      return {
        ...state,
        previewLeads: {
          items: List(action.response)
        },
        previewLeadsLoading: false
      };

    case leadConstants.PREVIEW_LEADS_LIST_FAILURE:
      return {
        ...state,
        previewLeadsLoading: false,
        previewError: action.error
      };

    case leadConstants.PREVIEW_LEADS_LIST_CLEAR:
      return {
        ...state,
        previewLeads: null
      };

    case leadConstants.PREVIEW_LEADS_LIST_EDIT: {
      const leadIdx = action.response['company.extIdCrunchbase']
        ? state.previewLeads.items.findIndex(
            item => item['company.extIdCrunchbase'] === action.response['company.extIdCrunchbase']
          )
        : state.previewLeads.items.findIndex(
            item => item['company.extIdParsers'] === action.response['company.extIdParsers']
          );
      return {
        ...state,
        previewLeadsLoading: false,
        previewLeads: {
          items: state.previewLeads.items.set(leadIdx, action.response)
        }
      };
    }

    // LEAD_PERSONS
    case leadConstants.LEAD_PERSON_ADD:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          persons: [...state[action.payload.type].persons, action.payload.data]
        }
      };
    case leadConstants.LEAD_PERSON_EDIT:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          persons: [
            ...state[action.payload.type].persons.filter(
              p => p['persons.id'] !== action.payload.data['persons.id']
            ),
            action.payload.data
          ]
        }
      };
    case leadConstants.LEAD_PERSON_DELETE:
      if (!state.items) {
        return state;
      }
      if (action.payload.isSavedPerson) {
        const leadIdx = state.items.findIndex(item => item['company.id'] === action.payload.leadId);

        const filteredPersons = [...state.items.get(leadIdx).persons].filter(
          person => person['persons.id'] !== action.payload.personId
        );
        return {
          ...state,
          items: state.items.set(leadIdx, {
            ...state.items.get(leadIdx),
            persons: filteredPersons
          })
        };
      }
      return {
        ...state,
        [action.payload.form]: {
          ...state[action.payload.form],
          persons: state[action.payload.form].persons.filter(
            p => p['persons.id'] !== action.payload.personId
          )
        }
      };
    case leadConstants.LEAD_PERSON_FAILURE:
      return {
        ...state,
        [action.payload.form]: {
          ...state[action.payload.form],
          errors: { ...state[action.payload.form].errors, persons: action.payload.error }
        }
      };

    case leadConstants.LEAD_PERSON_RELOAD_ERROR:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          errors: { ...state[action.payload].errors, persons: {} }
        }
      };
    case leadConstants.LEAD_CLEAR_DATA: {
      const { form, field } = action.payload;
      return {
        ...state,
        [form]: {
          ...state[form],
          [field]: []
        }
      };
    }
    case leadConstants.LEAD_ADD_OPP_ID: {
      const leadIndex = state.items.findIndex(val => val['company.id'] == action.payload.leadId);

      if (leadIndex === -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(leadIndex, {
          ...state.items.get(leadIndex),
          'company.opportunityId': action.payload.oppId
        })
      };
    }
    // LEAD_INVESTOR

    case leadConstants.LEAD_INVESTOR_ADD:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          investors: [...state[action.payload.type].investors, action.payload.data]
        }
      };

    case leadConstants.LEAD_INVESTOR_EDIT:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          investors: [
            ...state[action.payload.type].investors.filter(
              p => p['investors.id'] !== action.payload.data['investors.id']
            ),
            action.payload.data
          ]
        }
      };
    case leadConstants.LEAD_INVESTOR_RELOAD_ERROR:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          errors: { ...state[action.payload].errors, investors: {} }
        }
      };

    case leadConstants.LEAD_INVESTOR_DELETE:
      if (!state.items) {
        return state;
      }
      if (action.payload.isSavedInvestor) {
        const leadIdx = state.items.findIndex(item => item['company.id'] === action.payload.leadId);

        const filteredInvestors = [...state.items.get(leadIdx).investors].filter(
          investor => investor['investors.id'] !== action.payload.investorId
        );
        return {
          ...state,
          items: state.items.set(leadIdx, {
            ...state.items.get(leadIdx),
            investors: filteredInvestors
          })
        };
      }
      return {
        ...state,
        [action.payload.form]: {
          ...state[action.payload.form],
          investors: state[action.payload.form].investors.filter(
            p => p['investors.id'] !== action.payload.investorId
          )
        }
      };

    case leadConstants.LEAD_INVESTOR_FAILURE:
      return {
        ...state,
        [action.payload.form]: {
          ...state[action.payload.form],
          errors: { ...state[action.payload.form].errors, investors: action.payload.error }
        }
      };
    default:
      return state;
  }
}
