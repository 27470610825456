import variables from '../scss/_variables.scss';

export const PROD_LANDING_URL = 'https://theairr.com';
export const DEV_LANDING_URL = 'https://techinvest.vc';

export const PROD_APP_HOST = 'app.theairr.com';
export const DEV_APP_HOST = 'app.techinvest.vc';

export const availableColors = [
  variables.turquoise,
  variables.purple,
  variables.darkBlue,
  variables.blue,
  variables.yellow,
  variables.red,
  variables.black,
  variables.darkGrey,
  variables.grey
];

export const VISIBLE_FIELDS_KEYS = {
  LEADS: 'leads',
  ACTIVE_LEADS: 'activeLeads',
  CONVERTED_LEADS: 'convertedLeads',
  ON_HOLD_LEADS: 'onHoldLeads',
  DECLINED_LEADS: 'declinedLeads',
  LEAD_GENERATION_CRUNCHBASE: 'leadGenerationCrunchbase',
  LEAD_GENERATION_PARSERS: 'leadGenerationParsers'
};

export const NEED_SET_DEFAULT_VALUE = 'needSetDefaultValue';
export const DEFAULT_CURRENCY = 'USD';

export const HREF_DO_NOTHING = 'javascript:;';

export const USER_ROLES = {
  ORG_ADMIN: 'orgadmin',
  ORG_USER: 'orguser',
  INVESTOR_ADMIN: 'investoradmin',
  INVESTOR_TEAM: 'investorteam'
};
