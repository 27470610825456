export const leadConstants = {
  LEAD_CREATE_LOADING: 'LEAD_CREATE_LOADING',
  LEAD_CREATE_SUCCESS: 'LEAD_CREATE_SUCCESS',
  LEAD_CREATE_FAILURE: 'LEAD_CREATE_FAILURE',
  LEAD_ERROR_RELOAD: 'LEAD_ERROR_RELOAD',

  LEAD_LIST_LOADING: 'LEAD_LIST_LOADING',
  LEAD_LIST_SUCCESS: 'LEAD_LIST_SUCCESS',
  LEAD_LIST_FAILURE: 'LEAD_LIST_FAILURE',
  LEAD_LIST_CLEAR: 'LEAD_LIST_CLEAR',

  LEAD_EDIT_LOADING: 'LEAD_EDIT_LOADING',
  LEAD_EDIT_SUCCESS: 'LEAD_EDIT_SUCCESS',
  LEAD_EDIT_FAILURE: 'LEAD_EDIT_FAILURE',

  LEAD_DELETE_LOADING: 'LEAD_DELETE_LOADING',
  LEAD_DELETE_SUCCESS: 'LEAD_DELETE_SUCCESS',
  LEAD_DELETE_FAILURE: 'LEAD_DELETE_FAILURE',

  LEAD_SF_EXPORT_LOADING: 'LEAD_SF_EXPORT_LOADING',
  LEAD_SF_EXPORT_SUCCESS: 'LEAD_SF_EXPORT_SUCCESS',
  LEAD_SF_EXPORT_FAILURE: 'LEAD_SF_EXPORT_FAILURE',

  LEAD_SF_EXPORT_ALL_LOADING: 'LEAD_SF_EXPORT_ALL_LOADING',
  LEAD_SF_EXPORT_ALL_SUCCESS: 'LEAD_SF_EXPORT_ALL_SUCCESS',
  LEAD_SF_EXPORT_ALL_FAILURE: 'LEAD_SF_EXPORT_ALL_FAILURE',

  PREVIEW_LEADS_LIST_LOADING: 'PREVIEW_LEADS_LIST_LOADING',
  PREVIEW_LEADS_LIST_SUCCESS: 'PREVIEW_LEADS_LIST_SUCCESS',
  PREVIEW_LEADS_LIST_EDIT: 'PREVIEW_LEADS_LIST_EDIT',
  PREVIEW_LEADS_LIST_FAILURE: 'PREVIEW_LEADS_LIST_FAILURE',
  PREVIEW_LEADS_LIST_CLEAR: 'PREVIEW_LEADS_LIST_CLEAR',

  LEAD_PERSON_ADD: 'LEAD_PERSON_ADD',
  LEAD_PERSON_EDIT: 'LEAD_PERSON_EDIT',
  LEAD_PERSON_DELETE: 'LEAD_PERSON_DELETE',
  LEAD_PERSON_FAILURE: 'LEAD_PERSON_FAILURE',
  LEAD_CLEAR_DATA: 'LEAD_CLEAR_DATA',
  LEAD_PERSON_RELOAD_ERROR: 'LEAD_PERSON_RELOAD_ERROR',
  LEAD_ADD_OPP_ID: 'LEAD_ADD_OPP_ID',

  LEAD_INVESTOR_ADD: 'LEAD_INVESTOR_ADD',
  LEAD_INVESTOR_EDIT: 'LEAD_INVESTOR_EDIT',
  LEAD_INVESTOR_RELOAD_ERROR: 'LEAD_INVESTOR_RELOAD_ERROR',
  LEAD_INVESTOR_DELETE: 'LEAD_INVESTOR_DELETE',
  LEAD_INVESTOR_FAILURE: 'LEAD_INVESTOR_FAILURE',

  SAVE_ALL_TO_LEADS_LOADING: 'SAVE_ALL_TO_LEADS_LOADING',
  SAVE_ALL_TO_LEADS_SUCCESS: 'SAVE_ALL_TO_LEADS_SUCCESS',
  SAVE_ALL_TO_LEADS_FAILURE: 'SAVE_ALL_TO_LEADS_FAILURE',

  LEAD_TO_DECLINE_LOADING: 'LEAD_TO_DECLINE_LOADING',
  LEAD_TO_DECLINE_SUCCESS: 'LEAD_TO_DECLINE_SUCCESS',
  LEAD_TO_DECLINE_FAILURE: 'LEAD_TO_DECLINE_FAILURE'
};
