import React from 'react';
import { Table } from 'reactstrap';

const tableStyle = {
  background: '#fff'
};

export default ({ children, ...rest }) => (
  <Table {...rest} style={tableStyle}>
    {children}
  </Table>
);
