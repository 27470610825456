export const companyValuationConstants = {
  COMPANY_VALUATION_CREATE_LOADING: 'COMPANY_VALUATION_CREATE_LOADING',
  COMPANY_VALUATION_CREATE_SUCCESS: 'COMPANY_VALUATION_CREATE_SUCCESS',
  COMPANY_VALUATION_CREATE_FAILURE: 'COMPANY_VALUATION_CREATE_FAILURE',
  COMPANY_VALUATION_ERROR_RELOAD: 'COMPANY_VALUATION_ERROR_RELOAD',

  SYNC_WITH_CONN_ORGS_START: 'SYNC_WITH_CONN_ORGS_START',

  COMPANY_VALUATION_LIST_LOADING: 'COMPANY_VALUATION_LIST_LOADING',
  COMPANY_VALUATION_LIST_SUCCESS: 'COMPANY_VALUATION_LIST_SUCCESS',
  COMPANY_VALUATION_LIST_FAILURE: 'COMPANY_VALUATION_LIST_FAILURE',

  COMPANY_VALUATION_EDIT_LOADING: 'COMPANY_VALUATION_EDIT_LOADING',
  COMPANY_VALUATION_EDIT_SUCCESS: 'COMPANY_VALUATION_EDIT_SUCCESS',
  COMPANY_VALUATION_EDIT_FAILURE: 'COMPANY_VALUATION_EDIT_FAILURE',

  COMPANY_VALUATION_DELETE_LOADING: 'COMPANY_VALUATION_DELETE_LOADING',
  COMPANY_VALUATION_DELETE_SUCCESS: 'COMPANY_VALUATION_DELETE_SUCCESS',
  COMPANY_VALUATION_DELETE_FAILURE: 'COMPANY_VALUATION_DELETE_FAILURE'
};
