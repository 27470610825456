export const oppConstants = {
  OPP_CREATE_LOADING: 'OPP_CREATE_LOADING',
  OPP_CREATE_SUCCESS: 'OPP_CREATE_SUCCESS',
  OPP_CREATE_FAILURE: 'OPP_CREATE_FAILURE',
  OPP_ERROR_RELOAD: 'OPP_ERROR_RELOAD',

  OPP_SYNC_SALESFORCE_LOADING: 'OPP_SYNC_SALESFORCE_LOADING',
  OPP_SYNC_SALESFORCE_SUCCESS: 'OPP_SYNC_SALESFORCE_SUCCESS',
  OPP_SYNC_SALESFORCE_FAILURE: 'OPP_SYNC_SALESFORCE_FAILURE',

  OPP_SYNC_PIPEDRIVE_LOADING: 'OPP_SYNC_PIPEDRIVE_LOADING',
  OPP_SYNC_PIPEDRIVE_SUCCESS: 'OPP_SYNC_PIPEDRIVE_SUCCESS',
  OPP_SYNC_PIPEDRIVE_FAILURE: 'OPP_SYNC_PIPEDRIVE_FAILURE',

  OPP_SYNC_INSIGHTLY_LOADING: 'OPP_SYNC_INSIGHTLY_LOADING',
  OPP_SYNC_INSIGHTLY_SUCCESS: 'OPP_SYNC_INSIGHTLY_SUCCESS',
  OPP_SYNC_INSIGHTLY_FAILURE: 'OPP_SYNC_INSIGHTLY_FAILURE',

  OPP_LIST_LOADING: 'OPP_LIST_LOADING',
  OPP_LIST_SUCCESS: 'OPP_LIST_SUCCESS',
  OPP_LIST_FAILURE: 'OPP_LIST_FAILURE',
  OPP_LIST_CLEAR: 'OPP_LIST_CLEAR',

  OPP_EDIT_LOADING: 'OPP_EDIT_LOADING',
  OPP_EDIT_SUCCESS: 'OPP_EDIT_SUCCESS',
  OPP_EDIT_FAILURE: 'OPP_EDIT_FAILURE',

  OPP_DELETE_LOADING: 'OPP_DELETE_LOADING',
  OPP_DELETE_SUCCESS: 'OPP_DELETE_SUCCESS',
  OPP_DELETE_FAILURE: 'OPP_DELETE_FAILURE',

  OPP_ATTACH_SF_FILE_LOADING: 'OPP_ATTACH_SF_FILE_LOADING',
  OPP_ATTACH_SF_FILE_SUCCESS: 'OPP_ATTACH_SF_FILE_SUCCESS',
  OPP_ATTACH_SF_FILE_FAILURE: 'OPP_ATTACH_SF_FILE_FAILURE',

  OPP_DETACH_SF_FILE_LOADING: 'OPP_DETACH_SF_FILE_LOADING',
  OPP_DETACH_SF_FILE_SUCCESS: 'OPP_DETACH_SF_FILE_SUCCESS',
  OPP_DETACH_SF_FILE_FAILURE: 'OPP_DETACH_SF_FILE_FAILURE'
};
