import toastHelper from 'views/toastHelpers';
import logger from 'views/logger';

export const reorderListItems = (
  result,
  list,
  orderPropertyName,
  orgId,
  reorderAction,
  dispatch,
  beforeReorderAction,
  onSuccessReorderAction
) => {
  if (!result.destination) {
    return;
  }
  const startIndex = result.source.index;
  const endIndex = result.destination.index;

  if (startIndex !== endIndex) {
    const changedListItems = [];
    changedListItems.push({ ...list.items.get(startIndex), [orderPropertyName]: endIndex + 1 });

    const isRaiseElement = endIndex < startIndex;
    const whileUntilIndex = endIndex + (isRaiseElement ? -1 : 1);

    let currentIndex = startIndex + (isRaiseElement ? -1 : 1);
    while (currentIndex !== whileUntilIndex) {
      const currentFundItem = list.items.get(currentIndex);
      const newIndex = currentIndex + (isRaiseElement ? 1 : -1);
      currentIndex += isRaiseElement ? -1 : 1;
      changedListItems.push({ ...currentFundItem, [orderPropertyName]: newIndex + 1 });
    }

    const fundsWithoutChanges = list.items.filter(
      fund => !changedListItems.find(f => f.id === fund.id)
    );
    const newList = fundsWithoutChanges
      .concat(changedListItems)
      .sort((a, b) => a[orderPropertyName] - b[orderPropertyName]);
    beforeReorderAction(newList);
    dispatch(reorderAction(orgId, changedListItems, onSuccessReorderAction));
  }
};

export const deleteItemInsideReorderList = (
  orgId,
  list,
  itemId,
  deletedIndex,
  orderPropertyName,
  beforeDeleteAction,
  deleteItemAction,
  reorderAction,
  onSuccessReorderAction,
  dispatch,
  t
) => {
  const changedListItems = [];
  for (let i = deletedIndex + 1; i < list.size; i++) {
    const currentItem = list.get(i);
    changedListItems.push({
      ...currentItem,
      [orderPropertyName]: currentItem[orderPropertyName] - 1
    });
  }

  beforeDeleteAction();
  const afterDeleteAction = () => {
    dispatch(reorderAction(orgId, changedListItems, onSuccessReorderAction));
  };
  dispatch(deleteItemAction(orgId, itemId, afterDeleteAction, t));
};

export const getFieldKeyForDisplayFromConfig = (fieldKey, config) => {
  const filterObject = Object.values(config)
    .flat()
    .find(filterObject => filterObject.fieldKey === fieldKey);

  return filterObject && filterObject.fieldKeyForDisplay;
};

export const operatorParser = operator => {
  const arrayFromString = operator.split('');
  const firstCapitalLetterIndex = arrayFromString.findIndex(
    letter => letter === letter.toUpperCase()
  );
  if (firstCapitalLetterIndex >= 0) {
    arrayFromString.splice(firstCapitalLetterIndex, 0, ' ');
  }
  return arrayFromString.join('').toLowerCase();
};

export const getMultiSelectorValues = values => {
  return values && values[0] && values[0].value ? values.map(i => i.value) : values;
};

export const showToastError = error => {
  if (typeof error === 'string') {
    toastHelper.error(error);
  }
  logger.error(error);
};

export const getMessageFromError = error => (error.response ? error.response.data.message : error);

export const showErrorToastFromResponseError = (error, t) => {
  if (error.response && error.response.data.message) {
    return toastHelper.error(t(error.response.data.message));
  }
  logger.error(error);
};
