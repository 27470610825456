import { userConstants } from '../constants';

export function forgot(state = {}, action) {
  switch (action.type) {
    case userConstants.FORGOT_RECOVER_REQUEST:
      return {
        ...state,
        recover: {
          loading: true
        }
      };
    case userConstants.FORGOT_RECOVER_SUCCESS:
      return {
        ...state,
        recover: {
          success: true
        }
      };
    case userConstants.FORGOT_RECOVER_FAILURE:
      return {
        ...state,
        recover: {
          error: action.error
        }
      };
    case userConstants.FORGOT_RECOVER_CLEAR:
      return {
        ...state,
        recover: null
      };
    case userConstants.FORGOT_AUTH_REQUEST:
      return {
        ...state,
        auth: {
          loading: true
        }
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        recover: null,
        auth: null
      };
    case userConstants.FORGOT_AUTH_FAILURE:
      return {
        ...state,
        auth: {
          error: action.error
        }
      };
    default:
      return state;
  }
}
