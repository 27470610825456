export const fundConstants = {
  FUND_CREATE_LOADING: 'FUND_CREATE_LOADING',
  FUND_CREATE_SUCCESS: 'FUND_CREATE_SUCCESS',
  FUND_CREATE_FAILURE: 'FUND_CREATE_FAILURE',
  FUND_ERROR_RELOAD: 'FUND_ERROR_RELOAD',

  FUND_LIST_LOADING: 'FUND_LIST_LOADING',
  FUND_LIST_SUCCESS: 'FUND_LIST_SUCCESS',
  FUND_LIST_FAILURE: 'FUND_LIST_FAILURE',
  FUNDS_DATA_LOAD_SUCCESS: 'FUNDS_DATA_LOAD_SUCCESS',

  FUND_EDIT_LOADING: 'FUND_EDIT_LOADING',
  FUND_EDIT_SUCCESS: 'FUND_EDIT_SUCCESS',
  FUND_EDIT_FAILURE: 'FUND_EDIT_FAILURE',

  FUND_DELETE_LOADING: 'FUND_DELETE_LOADING',
  FUND_DELETE_SUCCESS: 'FUND_DELETE_SUCCESS',
  FUND_DELETE_FAILURE: 'FUND_DELETE_FAILURE',

  FUND_DOWNLOAD_PDF_LOADING: 'FUND_DOWNLOAD_PDF_LOADING',
  FUND_DOWNLOAD_PDF_SUCCESS: 'FUND_DOWNLOAD_PDF_SUCCESS',
  FUND_DOWNLOAD_PDF_FAILURE: 'FUND_DOWNLOAD_PDF_FAILURE'
};
