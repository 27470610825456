import { userConstants } from '../constants';

export function user_profile(state = {}, action) {
  switch (action.type) {
    case userConstants.PROFILE_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case userConstants.PROFILE_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const userIndex = state.items.findIndex(val => val.id == action.response.id);

      if (userIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(userIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case userConstants.PROFILE_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };
    case userConstants.USER_ERROR_RELOAD:
      return {
        ...state,
        createError: null,
        editError: null
      };

    default:
      return state;
  }
}
