export const investorDocTypeConstants = {
  INVESTOR_DOC_TYPE_CREATE_LOADING: 'INVESTOR_DOC_TYPE_CREATE_LOADING',
  INVESTOR_DOC_TYPE_CREATE_SUCCESS: 'INVESTOR_DOC_TYPE_CREATE_SUCCESS',
  INVESTOR_DOC_TYPE_CREATE_FAILURE: 'INVESTOR_DOC_TYPE_CREATE_FAILURE',
  INVESTOR_DOC_TYPE_ERROR_RELOAD: 'INVESTOR_DOC_TYPE_ERROR_RELOAD',

  INVESTOR_DOC_TYPE_LIST_LOADING: 'INVESTOR_DOC_TYPE_LIST_LOADING',
  INVESTOR_DOC_TYPE_LIST_SUCCESS: 'INVESTOR_DOC_TYPE_LIST_SUCCESS',
  INVESTOR_DOC_TYPE_LIST_FAILURE: 'INVESTOR_DOC_TYPE_LIST_FAILURE',

  INVESTOR_DOC_TYPE_EDIT_LOADING: 'INVESTOR_DOC_TYPE_EDIT_LOADING',
  INVESTOR_DOC_TYPE_EDIT_SUCCESS: 'INVESTOR_DOC_TYPE_EDIT_SUCCESS',
  INVESTOR_DOC_TYPE_EDIT_FAILURE: 'INVESTOR_DOC_TYPE_EDIT_FAILURE',

  INVESTOR_DOC_TYPE_DELETE_LOADING: 'INVESTOR_DOC_TYPE_DELETE_LOADING',
  INVESTOR_DOC_TYPE_DELETE_SUCCESS: 'INVESTOR_DOC_TYPE_DELETE_SUCCESS',
  INVESTOR_DOC_TYPE_DELETE_FAILURE: 'INVESTOR_DOC_TYPE_DELETE_FAILURE'
};
