import axios from 'axios';
import { axiosOptions } from './constants';

export async function create(
  orgId,
  passportNumber,
  country,
  issuedOn,
  validUntil,
  fileId,
  isPrimary
) {
  const request = {
    passportNumber,
    country,
    issuedOn,
    validUntil,
    fileId,
    isPrimary
  };

  const res = await axios.post(
    `${process.env.API_URL}/org/${orgId}/passport`,
    request,
    axiosOptions
  );

  return res.data;
}

export async function listPassports(orgId) {
  const request = null;

  const res = await axios.get(
    `${process.env.API_URL}/org/${orgId}/passport`,
    request,
    axiosOptions
  );

  return res.data;
}

export async function edit(
  orgId,
  passportId,
  passportNumber,
  country,
  issuedOn,
  validUntil,
  fileId,
  isPrimary
) {
  const request = {
    passportNumber,
    country,
    issuedOn,
    validUntil,
    fileId,
    isPrimary
  };

  const res = await axios.put(
    `${process.env.API_URL}/org/${orgId}/passport/${passportId}`,
    request,
    axiosOptions
  );

  return res.data;
}

export async function deletePassport(orgId, passportId) {
  const request = null;

  const res = await axios.delete(
    `${process.env.API_URL}/org/${orgId}/passport/${passportId}`,
    request,
    axiosOptions
  );

  return res.data;
}
