import { Trans as T } from 'react-i18next';
import React from 'react';
import { PROD_APP_HOST, USER_ROLES } from './constants';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import toastHelpers from 'views/toastHelpers';
import toastHelper from 'views/toastHelpers';
import logger from 'views/logger';

export const renderRoleName = rolename => {
  let roleTextElement;
  switch (rolename) {
    case USER_ROLES.INVESTOR_ADMIN:
      roleTextElement = <T i18nKey="UBO/Admin" />;
      break;
    case USER_ROLES.INVESTOR_TEAM:
      roleTextElement = <T i18nKey="InvestorTeam" />;
      break;
    default:
      roleTextElement = '';
  }
  return roleTextElement;
};

export const isProduction = () => window.location.host.includes(PROD_APP_HOST);

export const isQuickbooksConnected = org => org.quickbooks === 'Connected';

export const sortByDesc = (arr, sortPropertyName) =>
  arr.sort((a, b) => {
    if (!a[sortPropertyName] && b[sortPropertyName]) return 1;
    if (!b[sortPropertyName] && a[sortPropertyName]) return -1;
    return a[sortPropertyName] > b[sortPropertyName] ? -1 : 1;
  });
export const sortByAsc = (arr, sortPropertyName) =>
  arr.sort((a, b) => {
    if (!a[sortPropertyName] && b[sortPropertyName]) return -1;
    if (!b[sortPropertyName] && a[sortPropertyName]) return 1;
    return a[sortPropertyName] > b[sortPropertyName] ? 1 : -1;
  });

export const debounce = (func, wait, immediate) => {
  let timeout;

  return function executedFunction() {
    const context = this;
    const args = arguments;

    const later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
};

export const isArraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

export const getUniqueItemsOfArray = array => {
  return array.filter((item, index) => array.indexOf(item) === index);
};

export const checkIfEntitiesAreLoaded = (entities, withItemsCheck = false) => {
  return entities.every(entity => {
    if (withItemsCheck) {
      return entity && entity.items;
    }
    return entity;
  });
};

export const isEmptyObject = obj => Boolean(obj && Object.keys(obj).length === 0);

export const toLowerCaseIfEmail = (value, name) => (name === 'email' ? value.toLowerCase() : value);

export const assignFirstItemToEntity = (setEntity, propertyName) => items => {
  let getLength, getFirstItem;
  if (Object.getOwnPropertyNames(items).includes('size')) {
    getLength = i => i.size;
    getFirstItem = i => i.get(0);
  } else {
    getLength = i => i.length;
    getFirstItem = i => i[0];
  }

  setEntity(prevEntity => {
    return {
      ...prevEntity,
      [propertyName]: getLength(items) > 0 ? getFirstItem(items).id : null
    };
  });
};

export const CustomCoreUITooltip = context => {
  context._chart = context.chart;
  return CustomTooltips.call(context, context.tooltip);
};

export const getDefaultColumnWidthsFromDefaultColumns = (columns, defaultWidth) =>
  columns.map(columnName => ({ columnName, width: defaultWidth }));

export const isExistRoleInMemberships = (memberships, role) => {
  return memberships && memberships.some(membership => membership.roles.includes(role));
};

export const sliceAsyncThunkErrorHandler = errorText => {
  if (typeof errorText === 'string') {
    toastHelpers.error(errorText);
  }
};

export class CustomError extends Error {
  // possibleResponseUserErrors = [{ error: 'error', formField?: 'formFieldName' }] - Described in Swagger errors which we show to the user
  constructor(e, possibleResponseUserErrors = []) {
    let error;
    const validationError = e && e.type === 'validation' ? e : null;
    if (validationError) {
      error = new Error('Validation error');
    } else {
      error = e;
    }
    super(error);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomError);
    }

    let possibleUserErrorOccurred; // Error described in Swagger
    if (error.response) {
      possibleUserErrorOccurred = possibleResponseUserErrors.find(
        possibleError => possibleError.error === error.response.data.error
      );
    }

    const addBackendErrorTranslation = errorText => `BackendError.${errorText}`;

    if (validationError) {
      this.reduxPayload = validationError;
      this.toasterErrorText = null;
      this.consoleError = `Validation error: ${JSON.stringify(validationError.fields)}`;
    } else if (possibleUserErrorOccurred) {
      this.consoleError = error;
      if (possibleUserErrorOccurred.formField) {
        this.reduxPayload = {
          type: 'validation',
          fields: {
            [possibleUserErrorOccurred.formField]: addBackendErrorTranslation(
              possibleUserErrorOccurred.error
            )
          }
        };
        this.toasterErrorText = null;
      } else {
        this.reduxPayload = error.response.data.message;
        this.toasterErrorText = addBackendErrorTranslation(possibleUserErrorOccurred.error);
      }
    } else {
      this.reduxPayload = error;
      this.toasterErrorText = 'ContactUs';
      this.consoleError = error;
    }

    Object.setPrototypeOf(this, CustomError.prototype);
  }
}

export const ErrorHandler = async promiseFromDispatch => {
  return (promiseFromDispatch.unwrap ? promiseFromDispatch.unwrap() : promiseFromDispatch).catch(
    error => {
      if (error.toasterErrorText) {
        if (error.toasterErrorText === 'ContactUs') {
          const is401 =
            error.consoleError &&
            error.consoleError.response &&
            error.consoleError.response.status === 401;

          if (!is401) {
            toastHelper.systemError();
          }
        } else {
          toastHelper.error(error.toasterErrorText);
        }
      }
      logger.error(error.consoleError || error);

      throw error;
    }
  );
};

// export const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);
