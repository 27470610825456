export const capTableRecordConstants = {
  CAP_TABLE_RECORD_CREATE_LOADING: 'CAP_TABLE_RECORD_CREATE_LOADING',
  CAP_TABLE_RECORD_CREATE_SUCCESS: 'CAP_TABLE_RECORD_CREATE_SUCCESS',
  CAP_TABLE_RECORD_CREATE_FAILURE: 'CAP_TABLE_RECORD_CREATE_FAILURE',
  CAP_TABLE_RECORD_ERROR_RELOAD: 'CAP_TABLE_RECORD_ERROR_RELOAD',

  CAP_TABLE_RECORD_LIST_LOADING: 'CAP_TABLE_RECORD_LIST_LOADING',
  CAP_TABLE_RECORD_LIST_SUCCESS: 'CAP_TABLE_RECORD_LIST_SUCCESS',
  CAP_TABLE_RECORD_LIST_FAILURE: 'CAP_TABLE_RECORD_LIST_FAILURE',

  CAP_TABLE_RECORD_EDIT_LOADING: 'CAP_TABLE_RECORD_EDIT_LOADING',
  CAP_TABLE_RECORD_EDIT_SUCCESS: 'CAP_TABLE_RECORD_EDIT_SUCCESS',
  CAP_TABLE_RECORD_EDIT_FAILURE: 'CAP_TABLE_RECORD_EDIT_FAILURE',

  CAP_TABLE_RECORD_DELETE_LOADING: 'CAP_TABLE_RECORD_DELETE_LOADING',
  CAP_TABLE_RECORD_DELETE_SUCCESS: 'CAP_TABLE_RECORD_DELETE_SUCCESS',
  CAP_TABLE_RECORD_DELETE_FAILURE: 'CAP_TABLE_RECORD_DELETE_FAILURE',

  CAP_TABLE_RECORD_ATTACH_FILE_LOADING: 'CAP_TABLE_RECORD_ATTACH_FILE_LOADING',
  CAP_TABLE_RECORD_ATTACH_FILE_SUCCESS: 'CAP_TABLE_RECORD_ATTACH_FILE_SUCCESS',
  CAP_TABLE_RECORD_ATTACH_FILE_FAILURE: 'CAP_TABLE_RECORD_ATTACH_FILE_FAILURE'
};
