import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createEntityValidator,
  generateCodeForSlice,
  getDefaultReducersForEntityCreate,
  getDefaultReducersForEntityEdit,
  SLICE_CODE_FOR
} from '../helpers';

import { createItemInitialState, editItemInitialState } from '../constants';
import { dateValidator, nullValidator, numberValidator } from '../../validators/common';
import { CustomError } from 'views/helpers';
import api from 'components/api';

const drawdownDueValidation = createEntityValidator({
  amountConverted: [nullValidator(), numberValidator()],
  date: [nullValidator(), dateValidator()],
  name: [nullValidator()],
  investorId: [nullValidator()],
  fundId: [nullValidator()]
});

export const {
  rootName,
  generatedInitialState,
  generatedExtraReducers,
  loadDrawdownsDue,
  deleteDrawdownDue
} = generateCodeForSlice(
  {
    entityName: 'DrawdownDue',
    rootName: 'drawdownsDue',
    entityValidation: drawdownDueValidation
  },
  [SLICE_CODE_FOR.LOAD, SLICE_CODE_FOR.DELETE]
);

export const createDrawdownDue = createAsyncThunk(
  'drawdownsDue/create',
  async ({ entity, orgId }, { rejectWithValue }) => {
    try {
      await drawdownDueValidation(entity);

      const response = await api.post(`/org/${orgId}/drawdown_due`, {
        ...entity,
        amount: entity.amountConverted
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(new CustomError(error));
    }
  }
);

export const editDrawdownDue = createAsyncThunk(
  'drawdownsDue/edit',
  async ({ entity, orgId }, { rejectWithValue }) => {
    try {
      await drawdownDueValidation(entity);

      const response = await api.put(`/org/${orgId}/drawdown_due/${entity.id}`, {
        ...entity,
        amount: entity.amountConverted
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(new CustomError(error));
    }
  }
);

const drawdownsDueSlice = createSlice({
  name: rootName,
  initialState: { ...generatedInitialState, ...createItemInitialState, ...editItemInitialState },
  reducers: {
    reloadDrawdownDueError: state => {
      state.createError = null;
      state.editError = null;
    }
  },
  extraReducers: {
    ...generatedExtraReducers,
    ...getDefaultReducersForEntityCreate(createDrawdownDue),
    ...getDefaultReducersForEntityEdit(editDrawdownDue)
  }
});

export const { reloadDrawdownDueError } = drawdownsDueSlice.actions;

export default drawdownsDueSlice.reducer;
