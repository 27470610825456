import { List } from 'immutable';
import { uboConstants } from '../constants';
import {
  createFormPassportAdd,
  createFormPassportDelete,
  createFormPassportEdit,
  createFormUtilityBillAdd,
  createFormUtilityBillDelete,
  createFormUtilityBillEdit,
  editFormPassportAdd,
  editFormUtilityBillAdd
} from './helpers';

const DEFAULT_STATE = {
  createForm: {
    passports: [],
    utilityBills: []
  },
  editForm: {
    passports: [],
    utilityBills: []
  }
};

export function ubos(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case uboConstants.UBO_LIST_LOADING:
      return {
        ...state,
        items: null,
        listLoading: true
      };
    case uboConstants.UBO_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case uboConstants.UBO_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: null,
        listError: action.error
      };
    case uboConstants.UBO_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case uboConstants.UBO_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      return {
        ...state,
        items: items.push(action.response),
        createLoading: false,
        createError: null
      };
    }
    case uboConstants.UBO_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };

    case uboConstants.UBO_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case uboConstants.UBO_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const uboIndex = state.items.findIndex(val => val.id == action.response.id);

      if (uboIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(uboIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case uboConstants.UBO_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case uboConstants.RESET_UBO_MAIN_RECIPIENT: {
      if (!state.items) {
        return state;
      }

      const uboIndex = state.items.findIndex(val => val.id === action.payload);

      if (uboIndex === -1) {
        return state;
      }
      return {
        ...state,
        items: state.items.set(uboIndex, {
          ...state.items.get(uboIndex),
          mainRecipient: false
        })
      };
    }

    case uboConstants.UBO_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case uboConstants.UBO_DELETE_SUCCESS: {
      const { uboId } = action;

      if (!state.items) {
        return state;
      }

      const uboIndex = state.items.findIndex(val => val.id == uboId);

      if (uboIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(uboIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case uboConstants.UBO_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };
    case uboConstants.UBO_ERROR_RELOAD:
      return {
        ...state,
        createError: null,
        editError: null
      };

    case uboConstants.UBO_CREATE_FORM_PASSPORT_ADD:
      return createFormPassportAdd(state, action, 'uboId');

    case uboConstants.UBO_CREATE_FORM_PASSPORT_EDIT:
      return createFormPassportEdit(state, action);

    case uboConstants.UBO_CREATE_FORM_PASSPORT_DELETE:
      return createFormPassportDelete(state, action);

    case uboConstants.UBO_CREATE_FORM_UTILITY_BILL_ADD:
      return createFormUtilityBillAdd(state, action, 'uboId');

    case uboConstants.UBO_CREATE_FORM_UTILITY_BILL_EDIT:
      return createFormUtilityBillEdit(state, action);

    case uboConstants.UBO_CREATE_FORM_UTILITY_BILL_DELETE:
      return createFormUtilityBillDelete(state, action);

    case uboConstants.UBO_EDIT_FORM_PASSPORT_ADD:
      return editFormPassportAdd(state, action, 'uboId');

    case uboConstants.UBO_EDIT_FORM_PASSPORT_EDIT:
      return {
        ...state,
        editForm: {
          ...state.editForm,
          passports: [
            ...state.editForm.passports.filter(p => p.id !== action.payload.id),
            action.payload
          ]
        }
      };

    case uboConstants.UBO_EDIT_FORM_PASSPORT_DELETE:
      return {
        ...state,
        editForm: {
          ...state.editForm,
          passports: state.editForm.passports.filter(p => p.id !== action.payload)
        }
      };

    case uboConstants.UBO_EDIT_FORM_RESET_PASSPORTS:
      return {
        ...state,
        editForm: {
          ...state.editForm,
          passports: []
        }
      };

    case uboConstants.UBO_EDIT_FORM_UTILITY_BILL_ADD:
      return editFormUtilityBillAdd(state, action, 'uboId');

    case uboConstants.UBO_EDIT_FORM_UTILITY_BILL_EDIT:
      return {
        ...state,
        editForm: {
          ...state.editForm,
          utilityBills: [
            ...state.editForm.utilityBills.filter(p => p.id !== action.payload.id),
            action.payload
          ]
        }
      };

    case uboConstants.UBO_EDIT_FORM_UTILITY_BILL_DELETE:
      return {
        ...state,
        editForm: {
          ...state.editForm,
          utilityBills: state.editForm.utilityBills.filter(p => p.id !== action.payload)
        }
      };

    case uboConstants.UBO_EDIT_FORM_RESET_UTILITY_BILLS:
      return {
        ...state,
        editForm: {
          ...state.editForm,
          utilityBills: []
        }
      };

    default:
      return state;
  }
}
