import React from 'react';
import { TabContent } from 'reactstrap';
import PropTypes from 'prop-types';

const CustomTabContent = ({ activeTab, children }) => {
  return (
    <TabContent activeTab={activeTab}>
      {React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { activeTab });
        }
        return child;
      })}
    </TabContent>
  );
};

CustomTabContent.propTypes = {
  activeTab: PropTypes.string.isRequired
};

export default CustomTabContent;
