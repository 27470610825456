import { List } from 'immutable';
import { investmentConstants } from '../constants';

export function investments(state = {}, action) {
  switch (action.type) {
    case investmentConstants.INVESTMENT_LIST_BY_ORG_LOADING:
      return {
        ...state,
        listByOrgLoading: true
      };
    case investmentConstants.INVESTMENT_LIST_BY_ORG_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listByOrgLoading: false
      };
    case investmentConstants.INVESTMENT_LIST_BY_ORG_FAILURE:
      return {
        ...state,
        listByOrgLoading: false,
        items: List([]),
        listByOrgError: action.error
      };

    case investmentConstants.INVESTMENT_LIST_BY_ORG_CLEAR:
      return {
        ...state,
        items: null
      };

    case investmentConstants.INVESTMENT_LIST_BY_FUND_LOADING:
      return {
        ...state,
        listByFundLoading: true
      };
    case investmentConstants.INVESTMENT_LIST_BY_FUND_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listByFundLoading: false
      };
    case investmentConstants.INVESTMENT_LIST_BY_FUND_FAILURE:
      return {
        ...state,
        listByFundLoading: false,
        items: List([]),
        listByFundError: action.error
      };

    case investmentConstants.INVESTMENT_LIST_BY_COMPANY_LOADING:
      return {
        ...state,
        listByCompanyLoading: true
      };
    case investmentConstants.INVESTMENT_LIST_BY_COMPANY_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listByCompanyLoading: false
      };
    case investmentConstants.INVESTMENT_LIST_BY_COMPANY_FAILURE:
      return {
        ...state,
        listByCompanyLoading: false,
        items: List([]),
        listByCompanyError: action.error
      };

    case investmentConstants.INVESTMENT_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case investmentConstants.INVESTMENT_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      return {
        ...state,
        items: items.push(action.response),
        createLoading: false,
        createError: null
      };
    }
    case investmentConstants.INVESTMENT_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };

    case investmentConstants.INVESTMENT_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case investmentConstants.INVESTMENT_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const investmentIndex = state.items.findIndex(val => val.id == action.response.id);

      if (investmentIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(investmentIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case investmentConstants.INVESTMENT_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case investmentConstants.INVESTMENT_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case investmentConstants.INVESTMENT_DELETE_SUCCESS: {
      const { investmentId } = action;

      if (!state.items) {
        return state;
      }

      const investmentIndex = state.items.findIndex(val => val.id == investmentId);

      if (investmentIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(investmentIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case investmentConstants.INVESTMENT_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };
    case investmentConstants.INVESTMENT_ERROR_RELOAD:
      return {
        ...state,
        createError: null,
        editError: null
      };

    default:
      return state;
  }
}
