export const investmentRoundConstants = {
  INVESTMENT_ROUND_CREATE_LOADING: 'INVESTMENT_ROUND_CREATE_LOADING',
  INVESTMENT_ROUND_CREATE_SUCCESS: 'INVESTMENT_ROUND_CREATE_SUCCESS',
  INVESTMENT_ROUND_CREATE_FAILURE: 'INVESTMENT_ROUND_CREATE_FAILURE',
  INVESTMENT_ROUND_ERROR_RELOAD: 'INVESTMENT_ROUND_ERROR_RELOAD',

  INVESTMENT_ROUND_EDIT_LOADING: 'INVESTMENT_ROUND_EDIT_LOADING',
  INVESTMENT_ROUND_EDIT_SUCCESS: 'INVESTMENT_ROUND_EDIT_SUCCESS',
  INVESTMENT_ROUND_EDIT_FAILURE: 'INVESTMENT_ROUND_EDIT_FAILURE',

  INVESTMENT_ROUND_LIST_LOADING: 'INVESTMENT_ROUND_LIST_LOADING',
  INVESTMENT_ROUND_LIST_SUCCESS: 'INVESTMENT_ROUND_LIST_SUCCESS',
  INVESTMENT_ROUND_LIST_FAILURE: 'INVESTMENT_ROUND_LIST_FAILURE',
  INVESTMENT_ROUND_LIST_CLEAR: 'INVESTMENT_ROUND_LIST_CLEAR',

  INVESTMENT_ROUND_DELETE_LOADING: 'INVESTMENT_ROUND_DELETE_LOADING',
  INVESTMENT_ROUND_DELETE_SUCCESS: 'INVESTMENT_ROUND_DELETE_SUCCESS',
  INVESTMENT_ROUND_DELETE_FAILURE: 'INVESTMENT_ROUND_DELETE_FAILURE'
};
