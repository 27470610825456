import { passportConstants } from '../constants';
import * as passportService from 'services/passport.service';
import { nullValidator, validate } from '../validators/common';
import { redirectAfterPassportOrUtilityBillEdit } from './helpers';

export const passportValidation = async (passportNumber, issuedOn, validUntil) => {
  const validateObject = {
    passportNumber,
    issuedOn,
    validUntil
  };

  const validateConfig = {
    passportNumber: [nullValidator()],
    issuedOn: [nullValidator()],
    validUntil: [nullValidator()]
  };

  await validate(validateObject, validateConfig);
};

export function passportCreate(
  orgId,
  passportNumber,
  country,
  issuedOn,
  validUntil,
  fileId,
  isPrimary,
  onSuccess
) {
  return async dispatch => {
    try {
      await passportValidation(passportNumber, issuedOn, validUntil);

      dispatch({ type: passportConstants.PASSPORT_CREATE_LOADING });

      const response = await passportService.create(
        orgId,
        passportNumber,
        country,
        issuedOn,
        validUntil,
        fileId,
        isPrimary
      );

      dispatch({ type: passportConstants.PASSPORT_CREATE_SUCCESS, response });
      if (onSuccess) onSuccess(response);
    } catch (error) {
      dispatch({ type: passportConstants.PASSPORT_CREATE_FAILURE, error });
    }
  };
}

export function listPassports(orgId) {
  return async dispatch => {
    try {
      dispatch({ type: passportConstants.PASSPORT_LIST_LOADING });

      const response = await passportService.listPassports(orgId);

      dispatch({ type: passportConstants.PASSPORT_LIST_SUCCESS, response });
    } catch (error) {
      dispatch({ type: passportConstants.PASSPORT_LIST_FAILURE, error });
    }
  };
}

export function passportEdit(
  orgId,
  passportId,
  passportNumber,
  country,
  issuedOn,
  validUntil,
  fileId,
  isPrimary,
  uboId,
  directorId,
  contactId
) {
  return async dispatch => {
    try {
      await passportValidation(passportNumber, issuedOn, validUntil);

      dispatch({ type: passportConstants.PASSPORT_EDIT_LOADING });

      const response = await passportService.edit(
        orgId,
        passportId,
        passportNumber,
        country,
        issuedOn,
        validUntil,
        fileId,
        isPrimary
      );

      dispatch({ type: passportConstants.PASSPORT_EDIT_SUCCESS, response });
      redirectAfterPassportOrUtilityBillEdit(uboId, directorId, contactId);
    } catch (error) {
      dispatch({ type: passportConstants.PASSPORT_EDIT_FAILURE, error });
    }
  };
}

export function passportDelete(orgId, passportId) {
  return async dispatch => {
    try {
      dispatch({ type: passportConstants.PASSPORT_DELETE_LOADING });

      await passportService.deletePassport(orgId, passportId);

      dispatch({ type: passportConstants.PASSPORT_DELETE_SUCCESS, passportId });
    } catch (error) {
      dispatch({ type: passportConstants.PASSPORT_DELETE_FAILURE, error });
    }
  };
}

export function reloadPassportError() {
  return async dispatch => {
    dispatch({ type: passportConstants.PASSPORT_ERROR_RELOAD });
  };
}
