export const debtValuationConstants = {
  DEBT_VALUATION_CREATE_LOADING: 'DEBT_VALUATION_CREATE_LOADING',
  DEBT_VALUATION_CREATE_SUCCESS: 'DEBT_VALUATION_CREATE_SUCCESS',
  DEBT_VALUATION_CREATE_FAILURE: 'DEBT_VALUATION_CREATE_FAILURE',
  DEBT_VALUATION_ERROR_RELOAD: 'DEBT_VALUATION_ERROR_RELOAD',

  DEBT_VALUATION_LIST_LOADING: 'DEBT_VALUATION_LIST_LOADING',
  DEBT_VALUATION_LIST_SUCCESS: 'DEBT_VALUATION_LIST_SUCCESS',
  DEBT_VALUATION_LIST_FAILURE: 'DEBT_VALUATION_LIST_FAILURE',

  DEBT_VALUATION_EDIT_LOADING: 'DEBT_VALUATION_EDIT_LOADING',
  DEBT_VALUATION_EDIT_SUCCESS: 'DEBT_VALUATION_EDIT_SUCCESS',
  DEBT_VALUATION_EDIT_FAILURE: 'DEBT_VALUATION_EDIT_FAILURE',

  DEBT_VALUATION_DELETE_LOADING: 'DEBT_VALUATION_DELETE_LOADING',
  DEBT_VALUATION_DELETE_SUCCESS: 'DEBT_VALUATION_DELETE_SUCCESS',
  DEBT_VALUATION_DELETE_FAILURE: 'DEBT_VALUATION_DELETE_FAILURE'
};
