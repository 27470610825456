export const kycCompanyDocumentConstants = {
  KYC_COMPANY_DOCUMENT_CREATE_LOADING: 'KYC_COMPANY_DOCUMENT_CREATE_LOADING',
  KYC_COMPANY_DOCUMENT_CREATE_SUCCESS: 'KYC_COMPANY_DOCUMENT_CREATE_SUCCESS',
  KYC_COMPANY_DOCUMENT_CREATE_FAILURE: 'KYC_COMPANY_DOCUMENT_CREATE_FAILURE',
  KYC_COMPANY_DOCUMENT_ERROR_RELOAD: 'KYC_COMPANY_DOCUMENT_ERROR_RELOAD',

  KYC_COMPANY_DOCUMENT_LIST_LOADING: 'KYC_COMPANY_DOCUMENT_LIST_LOADING',
  KYC_COMPANY_DOCUMENT_LIST_SUCCESS: 'KYC_COMPANY_DOCUMENT_LIST_SUCCESS',
  KYC_COMPANY_DOCUMENT_LIST_FAILURE: 'KYC_COMPANY_DOCUMENT_LIST_FAILURE',

  KYC_COMPANY_DOCUMENT_EDIT_LOADING: 'KYC_COMPANY_DOCUMENT_EDIT_LOADING',
  KYC_COMPANY_DOCUMENT_EDIT_SUCCESS: 'KYC_COMPANY_DOCUMENT_EDIT_SUCCESS',
  KYC_COMPANY_DOCUMENT_EDIT_FAILURE: 'KYC_COMPANY_DOCUMENT_EDIT_FAILURE',

  KYC_COMPANY_DOCUMENT_DELETE_LOADING: 'KYC_COMPANY_DOCUMENT_DELETE_LOADING',
  KYC_COMPANY_DOCUMENT_DELETE_SUCCESS: 'KYC_COMPANY_DOCUMENT_DELETE_SUCCESS',
  KYC_COMPANY_DOCUMENT_DELETE_FAILURE: 'KYC_COMPANY_DOCUMENT_DELETE_FAILURE'
};
