import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'components/api';
import { CustomError } from 'views/helpers';

export const applyDeal = createAsyncThunk(
  'publishedDeals/apply',
  async ({ orgId, dealId }, { rejectWithValue }) => {
    try {
      const response = await api.post(`/org/${orgId}/marketplace/subscribe/${dealId}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(new CustomError(error));
    }
  }
);

export const unapplyDeal = createAsyncThunk(
  'publishedDeals/unapply',
  async ({ orgId, dealId }, { rejectWithValue }) => {
    try {
      const response = await api.delete(`/org/${orgId}/marketplace/subscribe/${dealId}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(new CustomError(error));
    }
  }
);
