export const industryConstants = {
  INDUSTRY_CREATE_LOADING: 'INDUSTRY_CREATE_LOADING',
  INDUSTRY_CREATE_SUCCESS: 'INDUSTRY_CREATE_SUCCESS',
  INDUSTRY_CREATE_FAILURE: 'INDUSTRY_CREATE_FAILURE',
  INDUSTRY_ERROR_RELOAD: 'INDUSTRY_ERROR_RELOAD',

  INDUSTRY_LIST_LOADING: 'INDUSTRY_LIST_LOADING',
  INDUSTRY_LIST_SUCCESS: 'INDUSTRY_LIST_SUCCESS',
  INDUSTRY_LIST_FAILURE: 'INDUSTRY_LIST_FAILURE',

  INDUSTRY_EDIT_LOADING: 'INDUSTRY_EDIT_LOADING',
  INDUSTRY_EDIT_SUCCESS: 'INDUSTRY_EDIT_SUCCESS',
  INDUSTRY_EDIT_FAILURE: 'INDUSTRY_EDIT_FAILURE',

  INDUSTRY_DELETE_LOADING: 'INDUSTRY_DELETE_LOADING',
  INDUSTRY_DELETE_SUCCESS: 'INDUSTRY_DELETE_SUCCESS',
  INDUSTRY_DELETE_FAILURE: 'INDUSTRY_DELETE_FAILURE'
};
