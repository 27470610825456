import i18n from '../i18n';
import { NEED_SET_DEFAULT_VALUE } from 'views/constants';

const defaultIntValidationConfig = {
  minValue: null,
  maxValue: null
};

export function numberValidator() {
  return async value => {
    const parsed = Number(value);
    if (isNaN(parsed)) throw i18n.t('Should be a numeric value');
    return value;
  };
}

// fails if value is not an integer
export function intValidator(userConfig) {
  return async value => {
    const config = {
      ...defaultIntValidationConfig,
      ...userConfig
    };

    const parsed = Number(value);
    const parsedAsInt = parseInt(value, 10);
    if (isNaN(parsed) || parsed !== parsedAsInt) {
      throw i18n.t('Should be an integer numeric value');
    }

    if (config.minValue !== null) {
      if (parsed < config.minValue) {
        throw `Should be >= ${config.minValue}`;
      }
    }

    if (config.maxValue !== null) {
      if (parsed > config.maxValue) {
        throw `Should be <= ${config.maxValue}`;
      }
    }

    return value;
  };
}

export function minValueValidator(minValue) {
  return async value => {
    const parsed = Number(value);

    if (minValue !== null) {
      if (parsed < minValue) {
        throw `Should be >= ${minValue}`;
      }
    }

    return value;
  };
}

export function greaterThanValidator(greaterThanValue) {
  return async value => {
    const parsed = Number(value);

    if (greaterThanValue !== null) {
      if (parsed <= greaterThanValue) {
        throw `Should be > ${greaterThanValue}`;
      }
    }

    return value;
  };
}

export function maxValueValidator(maxValue) {
  return async value => {
    const parsed = Number(value);

    if (maxValue !== null) {
      if (parsed > maxValue) {
        throw `Should be <= ${maxValue}`;
      }
    }

    return value;
  };
}

// fails if value is null
export function nullValidator() {
  return async value => {
    if (value === null || value === undefined || value === '') {
      throw i18n.t(`Should not be empty`);
    }

    return value;
  };
}

export function phoneNumberValidator() {
  return async value => {
    const regex = /^(?:\+?[0-9](?:[()\s-]*\d){6,15})$/;
    if (!regex.test(value)) {
      throw i18n.t('InvalidPhoneNumber');
    }

    return value;
  };
}

export function propertyWithDefaultValueValidator() {
  return async value => {
    if (value === null || value === undefined || value === '' || value === NEED_SET_DEFAULT_VALUE) {
      throw i18n.t(`Should not be empty`);
    }

    return value;
  };
}

export function notEmptyArray(object) {
  return async value => {
    if (value.length === 0) {
      throw i18n.t(`Should be at least one ${i18n.t(object)}`);
    }

    return value;
  };
}

export function checkboxValidator() {
  return async value => {
    if (!value) {
      throw i18n.t(`Should be checked`);
    }
    return value;
  };
}

export function emailValidator() {
  return async value => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!value.match(pattern)) throw i18n.t(`Invalid email address`);

    return value;
  };
}

export function websiteValidator() {
  return async value => {
    return value;
  };
}

export const textLengthValidator = (config = {}) => async value => {
  const { maxLength } = config;

  if (value && value.length > maxLength) {
    throw i18n.t('TextLengthLimitError', { limit: maxLength });
  }
  return value;
};

export function httpValidator() {
  return async value => {
    return value;
  };
}

export function alphabetValidator() {
  return async value => {
    const pattern = new RegExp('^([^0-9]*)$');

    if (!pattern.test(value)) {
      throw i18n.t(`Should be alphabet value`);
    }
    return value;
  };
}

export function persentValidator() {
  return async value => {
    const pattern = new RegExp('^[0-9][0-9]?$|^100$');
    if (!pattern.test(value)) {
      throw i18n.t(`Should_be_a_number_from_1_to_100`);
    }
    return value;
  };
}

export function urlValidator() {
  return async value => {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' +
        '((([a-z\\d]([a-z\\d@:-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
        '((\\d{1,3}\\.){3}\\d{1,3}))' +
        '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' +
        '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' +
        '(\\#[-a-z\\d_=]*)?$',
      'i'
    );

    if (!pattern.test(value)) {
      throw i18n.t(`Should be URL`);
    }
    return value;
  };
}

export function doubleValidator() {
  return async value => {
    var pattern = /^-{0,1}\d*\.{0,1}\d+$/;

    if (!value.match(pattern)) throw i18n.t(`Should be numeric value`);

    return value;
  };
}

export function dateValidator() {
  return async value => {
    const dateRegexp = new RegExp('^\\d{4}(-)(((0)[0-9])|((1)[0-2]))(-)([0-2][0-9]|(3)[0-1])$');

    if (!dateRegexp.test(value)) {
      throw i18n.t(`Should be Date`);
    }
    return value;
  };
}

export function positiveValidator() {
  return async value => {
    if (!value) {
      throw i18n.t(`Should be checked`);
    }
    return value;
  };
}

// does nothing, always passes validation
export function dummyValidator() {
  return async value => {
    return value;
  };
}

export async function validate(obj, config) {
  const errors = [];
  for (const prop in obj) {
    if (config[prop]) {
      try {
        for (const validator of config[prop]) {
          await validator(obj[prop]);
        }
      } catch (e) {
        errors.push({ prop, error: e });
      }
    }
  }

  if (errors.length == 0) {
    return obj;
  }
  const ret = { type: 'validation', fields: {} };

  for (const err of errors) {
    ret.fields[err.prop] = err.error;
  }

  throw ret;
}
