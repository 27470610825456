import React, { useEffect } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';
import './App.scss';
import './scss/colors.css';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Provider } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { store } from './store';
import { history } from './history';
import { axiosErrorHandler } from 'components/api/apiErrorHandler';
import ErrorBoundary from 'components/ErrorBoundary';

import 'chart.js/auto';

// Support css variables in EI
import cssVars from 'css-vars-ponyfill';
import { Loader } from 'components/Loader';
cssVars({});

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Invite = React.lazy(() => import('./views/Pages/Invite'));
const Forgot = React.lazy(() => import('./views/Pages/Forgot'));
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const RegisterFund = React.lazy(() => import('./views/Pages/RegisterFund'));
const ChooseOrg = React.lazy(() => import('./views/Pages/ChooseOrganisation'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <Loader />
  </div>
);

const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [pathname]);

  return children || null;
});

const App = () => {
  useEffect(() => {
    window.addEventListener('message', event => {
      const { key, response } = event.data;
      if (key === 'authData') {
        localStorage.setItem('user', response.user);
        localStorage.setItem('token', response.token);
        localStorage.setItem('orgId', response.orgId);
      }
    });
  }, []);

  return (
    <Provider store={store}>
      <Router history={history}>
        <ScrollToTop>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route
                exact
                path="/invite"
                name="Invite Page"
                render={props => (
                  <ErrorBoundary {...props}>
                    <Invite {...props} />
                  </ErrorBoundary>
                )}
              />
              <Route
                exact
                path="/forgot"
                name="Forgot Page"
                render={props => <Forgot {...props} />}
              />
              <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
              <Route
                exact
                path="/register"
                name="Register Page"
                render={props => <Register {...props} />}
              />
              <Route
                exact
                path="/register_fund"
                name="Register Fund"
                render={props => <RegisterFund {...props} />}
              />
              <Route
                exact
                path="/choose_org"
                name="Choose Organisation"
                render={props => <ChooseOrg {...props} />}
              />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
              <Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />
            </Switch>
            <iframe id="iframe-id" name="leadsFrame" title="auth" style={{ display: 'none' }} />
          </React.Suspense>
        </ScrollToTop>
      </Router>
    </Provider>
  );
};

export default withTranslation()(axiosErrorHandler(App));
