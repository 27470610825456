export const googleSheetsCompanyConnectionConstants = {
  GOOGLE_SHEETS_COMPANY_CONNECTION_CREATE_LOADING:
    'GOOGLE_SHEETS_COMPANY_CONNECTION_CREATE_LOADING',
  GOOGLE_SHEETS_COMPANY_CONNECTION_CREATE_SUCCESS:
    'GOOGLE_SHEETS_COMPANY_CONNECTION_CREATE_SUCCESS',
  GOOGLE_SHEETS_COMPANY_CONNECTION_CREATE_FAILURE:
    'GOOGLE_SHEETS_COMPANY_CONNECTION_CREATE_FAILURE',
  GOOGLE_SHEETS_COMPANY_CONNECTION_ERROR_RELOAD: 'GOOGLE_SHEETS_COMPANY_CONNECTION_ERROR_RELOAD',

  GOOGLE_SHEETS_COMPANY_CONNECTION_LIST_LOADING: 'GOOGLE_SHEETS_COMPANY_CONNECTION_LIST_LOADING',
  GOOGLE_SHEETS_COMPANY_CONNECTION_LIST_SUCCESS: 'GOOGLE_SHEETS_COMPANY_CONNECTION_LIST_SUCCESS',
  GOOGLE_SHEETS_COMPANY_CONNECTION_LIST_FAILURE: 'GOOGLE_SHEETS_COMPANY_CONNECTION_LIST_FAILURE',

  GOOGLE_SHEETS_COMPANY_CONNECTION_EDIT_LOADING: 'GOOGLE_SHEETS_COMPANY_CONNECTION_EDIT_LOADING',
  GOOGLE_SHEETS_COMPANY_CONNECTION_EDIT_SUCCESS: 'GOOGLE_SHEETS_COMPANY_CONNECTION_EDIT_SUCCESS',
  GOOGLE_SHEETS_COMPANY_CONNECTION_EDIT_FAILURE: 'GOOGLE_SHEETS_COMPANY_CONNECTION_EDIT_FAILURE',

  GOOGLE_SHEETS_COMPANY_CONNECTION_DELETE_LOADING:
    'GOOGLE_SHEETS_COMPANY_CONNECTION_DELETE_LOADING',
  GOOGLE_SHEETS_COMPANY_CONNECTION_DELETE_SUCCESS:
    'GOOGLE_SHEETS_COMPANY_CONNECTION_DELETE_SUCCESS',
  GOOGLE_SHEETS_COMPANY_CONNECTION_DELETE_FAILURE: 'GOOGLE_SHEETS_COMPANY_CONNECTION_DELETE_FAILURE'
};
