import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
// import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import axios from 'axios';
import rootReducer from './reducers';
import sagas from './sagas';

// const loggerMiddleware = createLogger();
const sagaMiddleware = createSagaMiddleware();

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.product = 'PORTAL';

function initState() {
  const token = localStorage.getItem('token');

  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  const storedOrg = localStorage.getItem('orgId');

  return {
    authentication: {
      token
    },
    org: {
      id: storedOrg
    }
  };
}

const composeEnhancers =
  (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store = createStore(
  rootReducer,
  initState(),
  composeEnhancers(
    applyMiddleware(
      thunkMiddleware,
      sagaMiddleware
      // loggerMiddleware
    )
  )
);

sagaMiddleware.run(sagas);
