export const passportConstants = {
  PASSPORT_CREATE_LOADING: 'PASSPORT_CREATE_LOADING',
  PASSPORT_CREATE_SUCCESS: 'PASSPORT_CREATE_SUCCESS',
  PASSPORT_CREATE_FAILURE: 'PASSPORT_CREATE_FAILURE',
  PASSPORT_ERROR_RELOAD: 'PASSPORT_ERROR_RELOAD',

  PASSPORT_LIST_LOADING: 'PASSPORT_LIST_LOADING',
  PASSPORT_LIST_SUCCESS: 'PASSPORT_LIST_SUCCESS',
  PASSPORT_LIST_FAILURE: 'PASSPORT_LIST_FAILURE',

  PASSPORT_EDIT_LOADING: 'PASSPORT_EDIT_LOADING',
  PASSPORT_EDIT_SUCCESS: 'PASSPORT_EDIT_SUCCESS',
  PASSPORT_EDIT_FAILURE: 'PASSPORT_EDIT_FAILURE',

  PASSPORT_DELETE_LOADING: 'PASSPORT_DELETE_LOADING',
  PASSPORT_DELETE_SUCCESS: 'PASSPORT_DELETE_SUCCESS',
  PASSPORT_DELETE_FAILURE: 'PASSPORT_DELETE_FAILURE'
};
