// import * from 'dotenv-webpack';
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
import * as Sentry from '@sentry/browser';

// import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import logger from 'views/logger';

if (!process.env.SENTRY_ENV || process.env.SENTRY_ENV !== 'DISABLED') {
  Sentry.init({
    dsn: 'https://d0565e0d56b94bedaffd55abf0c1f122@o374763.ingest.sentry.io/5193344',
    environment: process.env.SENTRY_ENV
  });
} else {
  logger.log('sentry disabled');
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
