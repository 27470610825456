import axios from 'axios';

import { axiosOptions } from './constants';

export async function listInvestorOnboardingItems(orgId) {
  const request = null;

  const res = await axios.get(
    `${process.env.API_URL}/org/${orgId}/investor_onboarding_item`,
    request,
    axiosOptions
  );

  return res.data;
}

export async function signInvestorOnboardingItem(orgId, investorOnboardingItemId, signerIds) {
  const request = {
    signerIds
  };
  const res = await axios.post(
    `${process.env.API_URL}/org/${orgId}/investor_onboarding_item/${investorOnboardingItemId}/sign`,
    request,
    axiosOptions
  );

  return res.data;
}

export async function voidInvestorOnboardingItem(orgId, investorOnboardingItemId) {
  const request = {};
  const res = await axios.post(
    `${process.env.API_URL}/org/${orgId}/investor_onboarding_item/${investorOnboardingItemId}/void`,
    request,
    axiosOptions
  );

  return res.data;
}

export async function updateStatusForInvestorOnboardingItem(orgId, investorOnboardingItemId) {
  const request = null;

  const res = await axios.get(
    `${process.env.API_URL}/org/${orgId}/investor_onboarding_item/${investorOnboardingItemId}/status`,
    request,
    axiosOptions
  );

  return res.data;
}
