export const investorConstants = {
  INVESTOR_CREATE_LOADING: 'INVESTOR_CREATE_LOADING',
  INVESTOR_CREATE_SUCCESS: 'INVESTOR_CREATE_SUCCESS',
  INVESTOR_CREATE_FAILURE: 'INVESTOR_CREATE_FAILURE',
  INVESTOR_ERROR_RELOAD: 'INVESTOR_ERROR_RELOAD',

  INVESTOR_LIST_LOADING: 'INVESTOR_LIST_LOADING',
  INVESTOR_LIST_SUCCESS: 'INVESTOR_LIST_SUCCESS',
  INVESTOR_LIST_FAILURE: 'INVESTOR_LIST_FAILURE',

  INVESTOR_EDIT_LOADING: 'INVESTOR_EDIT_LOADING',
  INVESTOR_EDIT_SUCCESS: 'INVESTOR_EDIT_SUCCESS',
  INVESTOR_EDIT_FAILURE: 'INVESTOR_EDIT_FAILURE',

  INVESTOR_DELETE_LOADING: 'INVESTOR_DELETE_LOADING',
  INVESTOR_DELETE_SUCCESS: 'INVESTOR_DELETE_SUCCESS',
  INVESTOR_DELETE_FAILURE: 'INVESTOR_DELETE_FAILURE'
};
