export const companyConstants = {
  COMPANY_CREATE_LOADING: 'COMPANY_CREATE_LOADING',
  COMPANY_CREATE_SUCCESS: 'COMPANY_CREATE_SUCCESS',
  COMPANY_CREATE_FAILURE: 'COMPANY_CREATE_FAILURE',
  COMPANY_ERROR_RELOAD: 'COMPANY_ERROR_RELOAD',

  COMPANY_LIST_LOADING: 'COMPANY_LIST_LOADING',
  COMPANY_LIST_SUCCESS: 'COMPANY_LIST_SUCCESS',
  COMPANY_LIST_FAILURE: 'COMPANY_LIST_FAILURE',

  COMPANY_EDIT_LOADING: 'COMPANY_EDIT_LOADING',
  COMPANY_EDIT_SUCCESS: 'COMPANY_EDIT_SUCCESS',
  COMPANY_EDIT_FAILURE: 'COMPANY_EDIT_FAILURE',

  COMPANY_SETTINGS_EDIT_LOADING: 'COMPANY_SETTINGS_EDIT_LOADING',
  COMPANY_SETTINGS_EDIT_SUCCESS: 'COMPANY_SETTINGS_EDIT_SUCCESS',
  COMPANY_SETTINGS_EDIT_FAILURE: 'COMPANY_SETTINGS_EDIT_FAILURE',

  COMPANY_DELETE_LOADING: 'COMPANY_DELETE_LOADING',
  COMPANY_DELETE_SUCCESS: 'COMPANY_DELETE_SUCCESS',
  COMPANY_DELETE_FAILURE: 'COMPANY_DELETE_FAILURE'
};
