export const managerConstants = {
  MANAGER_CREATE_LOADING: 'MANAGER_CREATE_LOADING',
  MANAGER_CREATE_SUCCESS: 'MANAGER_CREATE_SUCCESS',
  MANAGER_CREATE_FAILURE: 'MANAGER_CREATE_FAILURE',
  MANAGER_ERROR_RELOAD: 'MANAGER_ERROR_RELOAD',

  MANAGER_LIST_LOADING: 'MANAGER_LIST_LOADING',
  MANAGER_LIST_SUCCESS: 'MANAGER_LIST_SUCCESS',
  MANAGER_LIST_FAILURE: 'MANAGER_LIST_FAILURE',

  MANAGER_EDIT_LOADING: 'MANAGER_EDIT_LOADING',
  MANAGER_EDIT_SUCCESS: 'MANAGER_EDIT_SUCCESS',
  MANAGER_EDIT_FAILURE: 'MANAGER_EDIT_FAILURE',

  MANAGER_DELETE_LOADING: 'MANAGER_DELETE_LOADING',
  MANAGER_DELETE_SUCCESS: 'MANAGER_DELETE_SUCCESS',
  MANAGER_DELETE_FAILURE: 'MANAGER_DELETE_FAILURE'
};
