export const investorDocConstants = {
  INVESTOR_DOC_CREATE_LOADING: 'INVESTOR_DOC_CREATE_LOADING',
  INVESTOR_DOC_CREATE_SUCCESS: 'INVESTOR_DOC_CREATE_SUCCESS',
  INVESTOR_DOC_CREATE_FAILURE: 'INVESTOR_DOC_CREATE_FAILURE',
  INVESTOR_DOC_ERROR_RELOAD: 'INVESTOR_DOC_ERROR_RELOAD',

  INVESTOR_DOC_LIST_LOADING: 'INVESTOR_DOC_LIST_LOADING',
  INVESTOR_DOC_LIST_SUCCESS: 'INVESTOR_DOC_LIST_SUCCESS',
  INVESTOR_DOC_LIST_FAILURE: 'INVESTOR_DOC_LIST_FAILURE',

  INVESTOR_DOC_EDIT_LOADING: 'INVESTOR_DOC_EDIT_LOADING',
  INVESTOR_DOC_EDIT_SUCCESS: 'INVESTOR_DOC_EDIT_SUCCESS',
  INVESTOR_DOC_EDIT_FAILURE: 'INVESTOR_DOC_EDIT_FAILURE',

  INVESTOR_DOC_DELETE_LOADING: 'INVESTOR_DOC_DELETE_LOADING',
  INVESTOR_DOC_DELETE_SUCCESS: 'INVESTOR_DOC_DELETE_SUCCESS',
  INVESTOR_DOC_DELETE_FAILURE: 'INVESTOR_DOC_DELETE_FAILURE'
};
