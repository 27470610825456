import { List } from 'immutable';
import { oppConstants } from '../constants';

export function opps(state = {}, action) {
  switch (action.type) {
    case oppConstants.OPP_LIST_LOADING:
      return {
        ...state,
        listLoading: true
      };
    case oppConstants.OPP_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case oppConstants.OPP_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: [],
        listError: action.error
      };

    case oppConstants.OPP_LIST_CLEAR:
      return {
        ...state,
        items: null
      };

    case oppConstants.OPP_SYNC_SALESFORCE_LOADING:
      return {
        ...state,
        syncSalesforceLoading: true
      };
    case oppConstants.OPP_SYNC_SALESFORCE_SUCCESS:
      return {
        ...state,
        syncSalesforceLoading: false,
        syncSalesforceError: null
      };
    case oppConstants.OPP_SYNC_SALESFORCE_FAILURE:
      return {
        ...state,
        syncSalesforceLoading: false,
        syncSalesforceError: action.error
      };

    case oppConstants.OPP_SYNC_PIPEDRIVE_LOADING:
      return {
        ...state,
        syncPipedriveLoading: true
      };
    case oppConstants.OPP_SYNC_PIPEDRIVE_SUCCESS:
      return {
        ...state,
        syncPipedriveLoading: false,
        syncPipedriveError: null
      };
    case oppConstants.OPP_SYNC_PIPEDRIVE_FAILURE:
      return {
        ...state,
        syncPipedriveLoading: false,
        syncPipedriveError: action.error
      };
    case oppConstants.OPP_SYNC_INSIGHTLY_LOADING:
      return {
        ...state,
        syncInsightlyLoading: true
      };
    case oppConstants.OPP_SYNC_INSIGHTLY_SUCCESS:
      return {
        ...state,
        syncInsightlyLoading: false,
        syncInsightlyError: null
      };
    case oppConstants.OPP_SYNC_INSIGHTLY_FAILURE:
      return {
        ...state,
        syncInsightlyLoading: false,
        syncInsightlyError: action.error
      };
    case oppConstants.OPP_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case oppConstants.OPP_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      return {
        ...state,
        items: items.push(action.response),
        createLoading: false,
        createError: null
      };
    }
    case oppConstants.OPP_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };

    case oppConstants.OPP_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case oppConstants.OPP_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const oppIndex = state.items.findIndex(val => val.id == action.response.id);

      if (oppIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(oppIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case oppConstants.OPP_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case oppConstants.OPP_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case oppConstants.OPP_DELETE_SUCCESS: {
      const { oppId } = action;

      if (!state.items) {
        return state;
      }

      const oppIndex = state.items.findIndex(val => val.id == oppId);

      if (oppIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(oppIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case oppConstants.OPP_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };
    case oppConstants.OPP_ERROR_RELOAD:
      return {
        ...state,
        createError: null,
        editError: null
      };
    case oppConstants.OPP_ATTACH_SF_FILE_LOADING: {
      const index = state.items.findIndex(val => val.id == action.id);

      return {
        ...state,
        items: state.items.set(index, { ...state.items.get(index), loading: true })
      };
    }
    case oppConstants.OPP_ATTACH_SF_FILE_SUCCESS: {
      const index = state.items.findIndex(val => val.id == action.id);

      return {
        ...state,
        items: state.items.set(index, {
          ...state.items.get(index),
          fileId: action.fileId,
          downloadLink: action.downloadLink,
          loading: false
        })
      };
    }
    case oppConstants.OPP_ATTACH_SF_FILE_FAILURE: {
      const index = state.items.findIndex(val => val.id == action.id);

      return {
        ...state,
        items: state.items.set(index, {
          ...state.items.get(index),
          loading: false,
          uploadError: action.err
        })
      };
    }

    case oppConstants.OPP_DETACH_SF_FILE_SUCCESS: {
      const index = state.items.findIndex(val => val.id == action.id);

      return {
        ...state,
        items: state.items.set(index, {
          ...state.items.get(index),
          fileId: null,
          downloadLink: null,
          loading: false
        })
      };
    }
    default:
      return state;
  }
}
