import React from 'react';
import { NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const CustomNavItem = ({ tab, activeTab, label, labelElement, toggle }) => {
  const { t } = useTranslation();

  return (
    <NavItem>
      <NavLink active={tab === activeTab} onClick={() => toggle(tab)}>
        {label && t(label)}
        {labelElement && React.cloneElement(labelElement, { tab })}
      </NavLink>
    </NavItem>
  );
};

CustomNavItem.propTypes = {
  tab: PropTypes.string.isRequired,
  activeTab: PropTypes.string,
  label: PropTypes.string,
  labelElement: PropTypes.node,
  toggle: PropTypes.func
};

export default CustomNavItem;
