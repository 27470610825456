import { List } from 'immutable';
import { leadStatusConstants } from '../constants';

export function lead_statuses(state = {}, action) {
  switch (action.type) {
    case leadStatusConstants.LEAD_STATUS_LIST_LOADING:
      return {
        ...state,
        listLoading: true
      };
    case leadStatusConstants.LEAD_STATUS_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case leadStatusConstants.LEAD_STATUS_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: null,
        listError: action.error
      };
    case leadStatusConstants.LEAD_STATUS_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case leadStatusConstants.LEAD_STATUS_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      const leadStatuses = items.map(leadStatus => ({
        ...leadStatus,
        isDefault: action.response.isDefault ? false : leadStatus.isDefault
      }));
      return {
        ...state,
        items: leadStatuses.push(action.response),
        createLoading: false,
        createError: null
      };
    }
    case leadStatusConstants.LEAD_STATUS_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };

    case leadStatusConstants.LEAD_STATUS_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case leadStatusConstants.LEAD_STATUS_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const leadStatusIndex = state.items.findIndex(val => val.id == action.response.id);

      if (leadStatusIndex == -1) {
        return state;
      }

      const statuses = state.items.map(leadStatus => ({
        ...leadStatus,
        isDefault: action.response.isDefault ? false : leadStatus.isDefault
      }));
      return {
        ...state,
        items: statuses.set(leadStatusIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case leadStatusConstants.LEAD_STATUS_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case leadStatusConstants.LEAD_STATUS_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case leadStatusConstants.LEAD_STATUS_DELETE_SUCCESS: {
      const { leadStatusId } = action;

      if (!state.items) {
        return state;
      }

      const leadStatusIndex = state.items.findIndex(val => val.id == leadStatusId);

      if (leadStatusIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(leadStatusIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case leadStatusConstants.LEAD_STATUS_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };
    case leadStatusConstants.LEAD_STATUS_ERROR_RELOAD:
      return {
        ...state,
        createError: null,
        editError: null
      };

    default:
      return state;
  }
}
