import { List } from 'immutable';
import { investmentRoundConstants } from '../constants';

export function investment_rounds(state = {}, action) {
  switch (action.type) {
    case investmentRoundConstants.INVESTMENT_ROUND_LIST_LOADING:
      return {
        ...state,
        listLoading: true
      };
    case investmentRoundConstants.INVESTMENT_ROUND_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case investmentRoundConstants.INVESTMENT_ROUND_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: [],
        listError: action.error
      };

    case investmentRoundConstants.INVESTMENT_ROUND_LIST_CLEAR:
      return {
        ...state,
        items: null
      };

    case investmentRoundConstants.INVESTMENT_ROUND_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case investmentRoundConstants.INVESTMENT_ROUND_CREATE_SUCCESS: {
      const { response, isDebt } = action.payload;
      const items = state.items ? state.items : List();
      return {
        ...state,
        items: items.push(response),
        createLoading: false,
        [isDebt ? 'debtCreateError' : 'equityCreateError']: null
      };
    }

    case investmentRoundConstants.INVESTMENT_ROUND_CREATE_FAILURE: {
      const { error, isDebt } = action.payload;
      return {
        ...state,
        createLoading: false,
        [isDebt ? 'debtCreateError' : 'equityCreateError']: error
      };
    }

    case investmentRoundConstants.INVESTMENT_ROUND_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case investmentRoundConstants.INVESTMENT_ROUND_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const investmentRoundIndex = state.items.findIndex(val => val.id == action.response.id);

      if (investmentRoundIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(investmentRoundIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case investmentRoundConstants.INVESTMENT_ROUND_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case investmentRoundConstants.INVESTMENT_ROUND_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case investmentRoundConstants.INVESTMENT_ROUND_DELETE_SUCCESS: {
      const { investmentRoundId } = action;

      if (!state.items) {
        return state;
      }

      const investmentRoundIndex = state.items.findIndex(val => val.id == investmentRoundId);

      if (investmentRoundIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(investmentRoundIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case investmentRoundConstants.INVESTMENT_ROUND_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };
    case investmentRoundConstants.INVESTMENT_ROUND_ERROR_RELOAD:
      return {
        ...state,
        debtCreateError: null,
        equityCreateError: null,
        editError: null
      };

    default:
      return state;
  }
}
