import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createItemPending,
  createItemRejected,
  generateCodeForSlice,
  SLICE_CODE_FOR
} from '../helpers';
import api from 'components/api';
import { CustomError } from 'views/helpers';
import { createItemInitialState } from '../constants';
import { getFullName } from 'views/Admin/SettingsEmailNotifications/Recipients/common';

export const {
  rootName,
  generatedInitialState,
  generatedExtraReducers,
  deleteNotificationRecipient
} = generateCodeForSlice({ entityName: 'NotificationRecipient' }, [
  SLICE_CODE_FOR.LOAD,
  SLICE_CODE_FOR.DELETE,
  SLICE_CODE_FOR.EDIT
]);

export const loadNotificationRecipients = createAsyncThunk(
  `${rootName}/load`,
  async (orgId, { rejectWithValue }) => {
    try {
      const response = await api.get(`/org/${orgId}/notification_recipient`);

      await new Promise(resolve => {
        setTimeout(resolve, 5000);
      });

      return response.data.map(item => {
        item.userInvestor.name = getFullName(item.userInvestor);
        return item;
      });
    } catch (error) {
      return rejectWithValue(new CustomError(error));
    }
  }
);

export const editNotificationRecipient = createAsyncThunk(
  `${rootName}/edit`,
  async ({ entity, orgId }, { rejectWithValue }) => {
    try {
      const response = await api.put(`/org/${orgId}/notification_recipient/${entity.id}`, entity);
      response.data.userInvestor.name = getFullName(response.data.userInvestor);

      return response.data;
    } catch (error) {
      return rejectWithValue(new CustomError(error));
    }
  }
);

const combineSeveralErrorsAtOnce = error => {
  return error.response && error.response.data.length
    ? {
        ...error,
        response: {
          ...error.response,
          data: {
            ...error.response.data[0],
            message: error.response.data.reduce((acc, e) => `${acc}${e.message}. `, '')
          }
        }
      }
    : error;
};

export const createNotificationRecipients = createAsyncThunk(
  `${rootName}/create`,
  async ({ notificationRecipients, orgId }, { rejectWithValue }) => {
    try {
      if (notificationRecipients.length === 0) {
        throw {
          type: 'validation',
          fields: {
            formError: 'Select at least one recipient using checkboxes'
          }
        };
      }

      const response = await api.post(
        `/org/${orgId}/notification_recipient`,
        notificationRecipients
      );
      return response.data.map(item => {
        item.userInvestor.name = getFullName(item.userInvestor);
        return item;
      });
    } catch (error) {
      return rejectWithValue(new CustomError(combineSeveralErrorsAtOnce(error)));
    }
  }
);

export const notificationRecipientsSlice = createSlice({
  name: rootName,
  initialState: { ...generatedInitialState, createItemInitialState },
  reducers: {},
  extraReducers: {
    ...generatedExtraReducers,
    [createNotificationRecipients.pending]: createItemPending,
    [createNotificationRecipients.fulfilled]: (state, { payload }) => {
      state.createLoading = false;
      if (state.items) {
        state.items = state.items.concat(payload);
      } else {
        state.items = payload;
      }
      state.createError = null;
    },
    [createNotificationRecipients.rejected]: createItemRejected
  }
});

export const notificationRecipientsSelector = state => state[rootName];
export default notificationRecipientsSlice.reducer;
