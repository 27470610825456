export const onboardingProcessConstants = {
  ONBOARDING_PROCESS_CREATE_LOADING: 'ONBOARDING_PROCESS_CREATE_LOADING',
  ONBOARDING_PROCESS_CREATE_SUCCESS: 'ONBOARDING_PROCESS_CREATE_SUCCESS',
  ONBOARDING_PROCESS_CREATE_FAILURE: 'ONBOARDING_PROCESS_CREATE_FAILURE',
  ONBOARDING_PROCESS_ERROR_RELOAD: 'ONBOARDING_PROCESS_ERROR_RELOAD',

  ONBOARDING_PROCESS_LIST_LOADING: 'ONBOARDING_PROCESS_LIST_LOADING',
  ONBOARDING_PROCESS_LIST_SUCCESS: 'ONBOARDING_PROCESS_LIST_SUCCESS',
  ONBOARDING_PROCESS_LIST_FAILURE: 'ONBOARDING_PROCESS_LIST_FAILURE',
  ONBOARDING_PROCESS_LIST_CLEAR: 'ONBOARDING_PROCESS_LIST_CLEAR',

  ONBOARDING_PROCESS_EDIT_LOADING: 'ONBOARDING_PROCESS_EDIT_LOADING',
  ONBOARDING_PROCESS_EDIT_SUCCESS: 'ONBOARDING_PROCESS_EDIT_SUCCESS',
  ONBOARDING_PROCESS_EDIT_FAILURE: 'ONBOARDING_PROCESS_EDIT_FAILURE',

  ONBOARDING_PROCESS_DELETE_LOADING: 'ONBOARDING_PROCESS_DELETE_LOADING',
  ONBOARDING_PROCESS_DELETE_SUCCESS: 'ONBOARDING_PROCESS_DELETE_SUCCESS',
  ONBOARDING_PROCESS_DELETE_FAILURE: 'ONBOARDING_PROCESS_DELETE_FAILURE'
};
