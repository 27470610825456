import { generateSlice, SLICE_CODE_FOR } from '../helpers';
import { sendEmailNotification } from './emailNotificationsSlice';
import { getFullName } from 'views/Admin/SettingsEmailNotifications/Recipients/common';

export const { emailHistorySlice, emailHistorySelector, loadEmailHistory } = generateSlice(
  { entityName: 'EmailHistory', rootName: 'emailHistory' },
  [SLICE_CODE_FOR.LOAD]
)({
  extraReducers: {
    [sendEmailNotification.fulfilled]: (state, { payload }) => {
      state.items = state.items.concat(
        payload.recipients.map(recipient => ({
          downloadLink: payload.downloadLink,
          fileId: payload.fileId,
          fileName: payload.fileName,
          id: recipient.id + payload.fileId,
          notificationType: payload.notificationType,
          sentTime: new Date().toISOString(),
          userInvestor: {
            email: recipient.useAdditionalEmail
              ? recipient.userInvestor.additionalEmail
              : recipient.userInvestor.email,
            fullName: getFullName(recipient.userInvestor),
            id: recipient.userInvestor.id,
            investorName: recipient.userInvestor.investorName,
            type: recipient.userInvestor.type
          }
        }))
      );
    }
  }
});

export default emailHistorySlice.reducer;
