import { List } from 'immutable';
import { orgConstants, userConstants } from '../constants';

const initialState = {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        user: null,
        error: null
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.response.token,
        user: action.response.user,
        error: null
      };
    case userConstants.MEMBERSHIPS_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          memberships: action.memberships
        }
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        user: null,
        error: action.error
      };
    case orgConstants.ORG_CREATE_SUCCESS: {
      const memberships = List(state.user.memberships);

      const newMembership = action.response.memberships.find(
        m => m.userId == state.user.public_profile.id && m.orgId == action.response.id
      );

      if (newMembership == null) {
        return state;
      }

      return {
        ...state,
        user: {
          ...state.user,
          memberships: memberships.push(newMembership)
        }
      };
    }
    case userConstants.VALIDATE_FAILURE:
      return {};

    case userConstants.LOGOUT:
      return {};

    case userConstants.PROFILE_EDIT_SUCCESS:
      return {
        ...state,
        user: action.response
      };
    case userConstants.AUTHENTICATION_ERROR_RELOAD:
      return {
        ...state,
        error: null
      };

    case userConstants.VISIBLE_FIELDS_EDIT_SUCCESS: {
      const { visibleField, orgId, userId } = action.payload;
      const visibleFieldsWithoutChanged = state.user.fieldsVisibility.filter(
        vf => !(vf.key === visibleField.key && vf.orgId === orgId && vf.userId === userId)
      );
      const userData = state.user || {};

      return {
        ...state,
        user: {
          ...userData,
          fieldsVisibility: [...visibleFieldsWithoutChanged, { ...visibleField, orgId, userId }]
        }
      };
    }

    default:
      return state;
  }
}
