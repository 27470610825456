export const boardMemberConstants = {
  BOARD_MEMBER_CREATE_LOADING: 'BOARD_MEMBER_CREATE_LOADING',
  BOARD_MEMBER_CREATE_SUCCESS: 'BOARD_MEMBER_CREATE_SUCCESS',
  BOARD_MEMBER_CREATE_FAILURE: 'BOARD_MEMBER_CREATE_FAILURE',
  BOARD_MEMBER_ERROR_RELOAD: 'BOARD_MEMBER_ERROR_RELOAD',

  BOARD_MEMBER_LIST_LOADING: 'BOARD_MEMBER_LIST_LOADING',
  BOARD_MEMBER_LIST_SUCCESS: 'BOARD_MEMBER_LIST_SUCCESS',
  BOARD_MEMBER_LIST_FAILURE: 'BOARD_MEMBER_LIST_FAILURE',

  BOARD_MEMBER_EDIT_LOADING: 'BOARD_MEMBER_EDIT_LOADING',
  BOARD_MEMBER_EDIT_SUCCESS: 'BOARD_MEMBER_EDIT_SUCCESS',
  BOARD_MEMBER_EDIT_FAILURE: 'BOARD_MEMBER_EDIT_FAILURE',

  BOARD_MEMBER_DELETE_LOADING: 'BOARD_MEMBER_DELETE_LOADING',
  BOARD_MEMBER_DELETE_SUCCESS: 'BOARD_MEMBER_DELETE_SUCCESS',
  BOARD_MEMBER_DELETE_FAILURE: 'BOARD_MEMBER_DELETE_FAILURE'
};
