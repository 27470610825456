import { List } from 'immutable';
import { teamConstants } from '../constants';

export function team(state = {}, action) {
  switch (action.type) {
    case teamConstants.TEAM_LIST_LOADING:
      return {
        ...state,
        listLoading: true
      };
    case teamConstants.TEAM_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case teamConstants.TEAM_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: null,
        listError: action.error
      };
    case teamConstants.TEAM_ORG_LIST_LOADING:
      return {
        ...state,
        listOrgLoading: true
      };
    case teamConstants.TEAM_ORG_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listOrgLoading: false
      };
    case teamConstants.TEAM_ORG_LIST_FAILURE:
      return {
        ...state,
        listOrgLoading: false,
        items: null,
        listOrgError: action.error
      };
    case teamConstants.TEAM_INVESTORS_LIST_LOADING:
      return {
        ...state,
        listInvestorsLoading: true
      };
    case teamConstants.TEAM_INVESTORS_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listInvestorsLoading: false
      };
    case teamConstants.TEAM_INVESTORS_LIST_FAILURE:
      return {
        ...state,
        listInvestorsLoading: false,
        items: null,
        listInvestorsError: action.error
      };
    case teamConstants.TEAM_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case teamConstants.TEAM_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      const members = items.map(member => ({
        ...member,
        isBillingAdmin: action.response.isBillingAdmin ? false : member.isBillingAdmin
      }));

      return {
        ...state,
        items: members.push(action.response),
        createLoading: false,
        createError: null
      };
    }
    case teamConstants.TEAM_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: {
          ...state.createError,
          ...action.error
        }
      };

    case teamConstants.TEAM_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case teamConstants.TEAM_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const idx = state.items.findIndex(val => val.id == action.response.id);

      if (idx == -1) {
        return state;
      }

      const members = state.items.map(member => ({
        ...member,
        isBillingAdmin: action.response.isBillingAdmin ? false : member.isBillingAdmin
      }));

      return {
        ...state,
        items: members.set(idx, action.response),
        editLoading: false,
        editError: null
      };
    }
    case teamConstants.TEAM_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case teamConstants.TEAM_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case teamConstants.TEAM_DELETE_SUCCESS: {
      const { memberId } = action;

      if (!state.items) {
        return state;
      }

      const idx = state.items.findIndex(val => val.id == memberId);

      if (idx == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(idx),
        deleteLoading: false,
        deleteError: null
      };
    }
    case teamConstants.TEAM_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };

    case teamConstants.TEAM_RELOAD_ERROR: {
      let newCreateError = { ...state.createError };
      delete newCreateError[action.key];
      return {
        ...state,
        createError: newCreateError
      };
    }

    default:
      return state;
  }
}
