export const investmentConstants = {
  INVESTMENT_CREATE_LOADING: 'INVESTMENT_CREATE_LOADING',
  INVESTMENT_CREATE_SUCCESS: 'INVESTMENT_CREATE_SUCCESS',
  INVESTMENT_CREATE_FAILURE: 'INVESTMENT_CREATE_FAILURE',
  INVESTMENT_ERROR_RELOAD: 'INVESTMENT_ERROR_RELOAD',

  INVESTMENT_EDIT_LOADING: 'INVESTMENT_EDIT_LOADING',
  INVESTMENT_EDIT_SUCCESS: 'INVESTMENT_EDIT_SUCCESS',
  INVESTMENT_EDIT_FAILURE: 'INVESTMENT_EDIT_FAILURE',

  INVESTMENT_LIST_BY_ORG_LOADING: 'INVESTMENT_LIST_BY_ORG_LOADING',
  INVESTMENT_LIST_BY_ORG_SUCCESS: 'INVESTMENT_LIST_BY_ORG_SUCCESS',
  INVESTMENT_LIST_BY_ORG_FAILURE: 'INVESTMENT_LIST_BY_ORG_FAILURE',
  INVESTMENT_LIST_BY_ORG_CLEAR: 'INVESTMENT_LIST_BY_ORG_CLEAR',

  INVESTMENT_LIST_BY_FUND_LOADING: 'INVESTMENT_LIST_BY_FUND_LOADING',
  INVESTMENT_LIST_BY_FUND_SUCCESS: 'INVESTMENT_LIST_BY_FUND_SUCCESS',
  INVESTMENT_LIST_BY_FUND_FAILURE: 'INVESTMENT_LIST_BY_FUND_FAILURE',

  INVESTMENT_LIST_BY_COMPANY_LOADING: 'INVESTMENT_LIST_BY_COMPANY_LOADING',
  INVESTMENT_LIST_BY_COMPANY_SUCCESS: 'INVESTMENT_LIST_BY_COMPANY_SUCCESS',
  INVESTMENT_LIST_BY_COMPANY_FAILURE: 'INVESTMENT_LIST_BY_COMPANY_FAILURE',

  INVESTMENT_DELETE_LOADING: 'INVESTMENT_DELETE_LOADING',
  INVESTMENT_DELETE_SUCCESS: 'INVESTMENT_DELETE_SUCCESS',
  INVESTMENT_DELETE_FAILURE: 'INVESTMENT_DELETE_FAILURE'
};
