export const serviceProviderAgreementConstants = {
  SERVICE_PROVIDER_AGREEMENT_CREATE_LOADING: 'SERVICE_PROVIDER_AGREEMENT_CREATE_LOADING',
  SERVICE_PROVIDER_AGREEMENT_CREATE_SUCCESS: 'SERVICE_PROVIDER_AGREEMENT_CREATE_SUCCESS',
  SERVICE_PROVIDER_AGREEMENT_CREATE_FAILURE: 'SERVICE_PROVIDER_AGREEMENT_CREATE_FAILURE',
  SERVICE_PROVIDER_AGREEMENT_ERROR_RELOAD: 'SERVICE_PROVIDER_AGREEMENT_ERROR_RELOAD',

  SERVICE_PROVIDER_AGREEMENT_EDIT_LOADING: 'SERVICE_PROVIDER_AGREEMENT_EDIT_LOADING',
  SERVICE_PROVIDER_AGREEMENT_EDIT_SUCCESS: 'SERVICE_PROVIDER_AGREEMENT_EDIT_SUCCESS',
  SERVICE_PROVIDER_AGREEMENT_EDIT_FAILURE: 'SERVICE_PROVIDER_AGREEMENT_EDIT_FAILURE',

  SERVICE_PROVIDER_AGREEMENT_LIST_LOADING: 'SERVICE_PROVIDER_AGREEMENT_LIST_LOADING',
  SERVICE_PROVIDER_AGREEMENT_LIST_SUCCESS: 'SERVICE_PROVIDER_AGREEMENT_LIST_SUCCESS',
  SERVICE_PROVIDER_AGREEMENT_LIST_FAILURE: 'SERVICE_PROVIDER_AGREEMENT_LIST_FAILURE',

  SERVICE_PROVIDER_AGREEMENT_DELETE_LOADING: 'SERVICE_PROVIDER_AGREEMENT_DELETE_LOADING',
  SERVICE_PROVIDER_AGREEMENT_DELETE_SUCCESS: 'SERVICE_PROVIDER_AGREEMENT_DELETE_SUCCESS',
  SERVICE_PROVIDER_AGREEMENT_DELETE_FAILURE: 'SERVICE_PROVIDER_AGREEMENT_DELETE_FAILURE'
};
