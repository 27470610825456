import { call, put, takeEvery } from 'redux-saga/effects';
import {
  updateStatusForInvestorOnboardingItem,
  listInvestorOnboardingItems
} from 'services/investor_onboarding_item.service';

function* refreshOnboardingItems({ payload: { orgId, itemId } }) {
  try {
    yield call(updateStatusForInvestorOnboardingItem, orgId, itemId);
    yield call(listInvestorOnboardingItems, orgId);
  } catch (e) {
    yield put({ type: 'SAGA_REFRESH_ITEMS_ERROR', message: e.message });
  }
}

export function* watchRefresh() {
  yield takeEvery('REFRESH_ITEMS', refreshOnboardingItems);
}
