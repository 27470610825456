import { List } from 'immutable';
import { sourceOfWealthProofConstants, uboConstants } from '../constants';

export function source_of_wealth_proofs(state = {}, action) {
  switch (action.type) {
    case sourceOfWealthProofConstants.SOURCE_OF_WEALTH_PROOF_LIST_LOADING:
      return {
        ...state,
        listLoading: true
      };
    case sourceOfWealthProofConstants.SOURCE_OF_WEALTH_PROOF_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case sourceOfWealthProofConstants.SOURCE_OF_WEALTH_PROOF_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: null,
        listError: action.error
      };
    case sourceOfWealthProofConstants.SOURCE_OF_WEALTH_PROOF_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case sourceOfWealthProofConstants.SOURCE_OF_WEALTH_PROOF_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      return {
        ...state,
        items: items.push(action.response),
        createLoading: false,
        createError: null
      };
    }
    case sourceOfWealthProofConstants.SOURCE_OF_WEALTH_PROOF_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };

    case sourceOfWealthProofConstants.SOURCE_OF_WEALTH_PROOF_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case sourceOfWealthProofConstants.SOURCE_OF_WEALTH_PROOF_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const sourceOfWealthProofIndex = state.items.findIndex(val => val.id == action.response.id);

      if (sourceOfWealthProofIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(sourceOfWealthProofIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case sourceOfWealthProofConstants.SOURCE_OF_WEALTH_PROOF_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case sourceOfWealthProofConstants.SOURCE_OF_WEALTH_PROOF_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case sourceOfWealthProofConstants.SOURCE_OF_WEALTH_PROOF_DELETE_SUCCESS: {
      const { sourceOfWealthProofId } = action;

      if (!state.items) {
        return state;
      }

      const sourceOfWealthProofIndex = state.items.findIndex(
        val => val.id == sourceOfWealthProofId
      );

      if (sourceOfWealthProofIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(sourceOfWealthProofIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case uboConstants.UBO_EDIT_SUCCESS:
    case uboConstants.UBO_CREATE_SUCCESS: {
      let changedItems = List([...state.items]);

      const { source_of_wealth_proofs } = action.response;

      for (const source_of_wealth_proof of source_of_wealth_proofs) {
        const sourceOfWealthProofIndex = changedItems.findIndex(
          val => val.id === source_of_wealth_proof.id
        );
        if (sourceOfWealthProofIndex === -1) {
          continue;
        }

        const newSourceOfWealthProof = {
          ...changedItems.get(sourceOfWealthProofIndex),
          uboId: action.response.id
        };

        changedItems = changedItems.set(sourceOfWealthProofIndex, newSourceOfWealthProof);
      }

      return {
        ...state,
        items: changedItems
      };
    }
    case sourceOfWealthProofConstants.SOURCE_OF_WEALTH_PROOF_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };
    case sourceOfWealthProofConstants.SOURCE_OF_WEALTH_PROOF_ERROR_RELOAD:
      return {
        ...state,
        createError: null,
        editError: null
      };

    default:
      return state;
  }
}
