import { createAsyncThunk } from '@reduxjs/toolkit';
import { editItemFulfilled, generateSlice, SLICE_CODE_FOR } from '../helpers';
import axios from 'axios';
import { axiosOptions } from 'services/constants';
import { editMarketplaceDeal } from './userDealsSlice';
import { CustomError } from 'views/helpers';

export const sendNotificationAboutUserInterestToDeal = createAsyncThunk(
  `lastPublishedDeals/userInterest`,
  async ({ orgId, dealId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.API_URL}/org/${orgId}/marketplace/interest/${dealId}`,
        axiosOptions
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(new CustomError(error));
    }
  }
);

export const { lastPublishedDealsSlice, loadLastPublishedDeals } = generateSlice(
  { entityName: 'LastPublishedDeal', baseUrl: '/marketplace/deals/last' },
  [SLICE_CODE_FOR.LOAD]
)({
  extraReducers: {
    [editMarketplaceDeal.fulfilled]: editItemFulfilled
  }
});

export default lastPublishedDealsSlice.reducer;
