export const investorTypeConstants = {
  INVESTOR_TYPE_CREATE_LOADING: 'INVESTOR_TYPE_CREATE_LOADING',
  INVESTOR_TYPE_CREATE_SUCCESS: 'INVESTOR_TYPE_CREATE_SUCCESS',
  INVESTOR_TYPE_CREATE_FAILURE: 'INVESTOR_TYPE_CREATE_FAILURE',
  INVESTOR_TYPE_RELOAD_ERROR: 'INVESTOR_TYPE_RELOAD_ERROR',

  INVESTOR_TYPE_LIST_LOADING: 'INVESTOR_TYPE_LIST_LOADING',
  INVESTOR_TYPE_LIST_SUCCESS: 'INVESTOR_TYPE_LIST_SUCCESS',
  INVESTOR_TYPE_LIST_FAILURE: 'INVESTOR_TYPE_LIST_FAILURE',

  INVESTOR_TYPE_EDIT_LOADING: 'INVESTOR_TYPE_EDIT_LOADING',
  INVESTOR_TYPE_EDIT_SUCCESS: 'INVESTOR_TYPE_EDIT_SUCCESS',
  INVESTOR_TYPE_EDIT_FAILURE: 'INVESTOR_TYPE_EDIT_FAILURE',

  INVESTOR_TYPE_DELETE_LOADING: 'INVESTOR_TYPE_DELETE_LOADING',
  INVESTOR_TYPE_DELETE_SUCCESS: 'INVESTOR_TYPE_DELETE_SUCCESS',
  INVESTOR_TYPE_DELETE_FAILURE: 'INVESTOR_TYPE_DELETE_FAILURE'
};
