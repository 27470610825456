import React from 'react';
import styled from 'styled-components';
import { GridLoader } from 'react-spinners';

export const SpinnerWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: ${props => (props.noVerticalCentering ? 'auto' : '50%')};
  transform: translate(-50%, -50%);
`;

export const Loader = ({ noVerticalCentering = false }) => (
  <SpinnerWrapper noVerticalCentering={noVerticalCentering}>
    <GridLoader size={7} color="#2463F6" />
  </SpinnerWrapper>
);

export const TabLoader = () => (
  <div className="p-2">
    <Loader noVerticalCentering />
  </div>
);

export const TableLoader = () => (
  <div className="p-2">
    <Loader noVerticalCentering />
  </div>
);

export const FormLoader = () => (
  <div className="p-3">
    <Loader noVerticalCentering />
  </div>
);

export const KYCLoader = () => (
  <div className="p-2 position-relative">
    <Loader noVerticalCentering />
  </div>
);

export const PageLoader = () => (
  <div className="p-2 position-relative">
    <Loader />
  </div>
);

export const EditPageLoader = () => <Loader noVerticalCentering />;
