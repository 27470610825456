export const applyDealFulfilled = (state, { payload }) => {
  if (state.editLoading) state.editLoading = false;
  if (!state.items) return;

  const itemIndex = state.items.findIndex(val => val.id === payload.deal);
  if (itemIndex === -1) return;
  state.items[itemIndex] = {
    ...state.items[itemIndex],
    applied: true,
    appliedDate: payload.subscribeDate
  };
};

export const unapplyDealFulfilled = (state, { payload }) => {
  if (state.editLoading) state.editLoading = false;
  if (!state.items) return;

  const itemIndex = state.items.findIndex(val => val.id === payload.deal);
  if (itemIndex === -1) return;
  state.items[itemIndex] = {
    ...state.items[itemIndex],
    applied: false,
    appliedDate: null
  };
};
