import { dateValidator, nullValidator, numberValidator } from '../../validators/common';
import { createEntityValidator, generateSlice, SLICE_CODE_FOR } from '../helpers';
import { generateCommonCodeForAccountingEntity } from './common';

const drawdownValidation = createEntityValidator({
  amount: [nullValidator(), numberValidator()],
  date: [nullValidator(), dateValidator()],
  name: [nullValidator()],
  investorId: [nullValidator()],
  fundId: [nullValidator()]
});

export const {
  refreshDrawdowns,
  syncDrawdownsWithXero,
  syncDrawdownsWithQuickbooks,
  exportDrawdowns,
  importDrawdowns,
  generatedExtraReducers
} = generateCommonCodeForAccountingEntity('Drawdown');

export const {
  drawdownsSlice,
  loadDrawdowns,
  createDrawdown,
  editDrawdown,
  deleteDrawdown,
  reloadDrawdownsError
} = generateSlice({ entityName: 'Drawdown', entityValidation: drawdownValidation }, [
  SLICE_CODE_FOR.LOAD,
  SLICE_CODE_FOR.CREATE,
  SLICE_CODE_FOR.EDIT,
  SLICE_CODE_FOR.DELETE,
  SLICE_CODE_FOR.RELOAD_ERROR
])({ extraReducers: generatedExtraReducers });

export default drawdownsSlice.reducer;
