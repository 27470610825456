import { utilityBillConstants } from '../constants';
import * as utilityBillService from 'services/utility_bill.service';
import { nullValidator, validate } from '../validators/common';
import { redirectAfterPassportOrUtilityBillEdit } from './helpers';

export const utilityBillValidation = async (date, residenceAddress) => {
  const validateObject = {
    date,
    residenceAddress
  };

  const validateConfig = {
    date: [nullValidator()],
    residenceAddress: [nullValidator()]
  };

  await validate(validateObject, validateConfig);
};

export function utilityBillCreate(
  orgId,
  date,
  residenceAddress,
  type,
  fileId,
  isPrimary,
  onSuccess
) {
  return async dispatch => {
    try {
      await utilityBillValidation(date, residenceAddress);

      dispatch({ type: utilityBillConstants.UTILITY_BILL_CREATE_LOADING });

      const response = await utilityBillService.create(
        orgId,
        date,
        residenceAddress,
        type,
        fileId,
        isPrimary
      );

      dispatch({ type: utilityBillConstants.UTILITY_BILL_CREATE_SUCCESS, response });
      if (onSuccess) onSuccess(response);
    } catch (error) {
      dispatch({ type: utilityBillConstants.UTILITY_BILL_CREATE_FAILURE, error });
    }
  };
}

export function listUtilityBills(orgId) {
  return async dispatch => {
    try {
      dispatch({ type: utilityBillConstants.UTILITY_BILL_LIST_LOADING });

      const response = await utilityBillService.listUtilityBills(orgId);

      dispatch({ type: utilityBillConstants.UTILITY_BILL_LIST_SUCCESS, response });
    } catch (error) {
      dispatch({ type: utilityBillConstants.UTILITY_BILL_LIST_FAILURE, error });
    }
  };
}

export function utilityBillEdit(
  orgId,
  utilityBillId,
  date,
  residenceAddress,
  type,
  fileId,
  isPrimary,
  uboId,
  directorId,
  contactId
) {
  return async dispatch => {
    try {
      await utilityBillValidation(date, residenceAddress);

      dispatch({ type: utilityBillConstants.UTILITY_BILL_EDIT_LOADING });

      const response = await utilityBillService.edit(
        orgId,
        utilityBillId,
        date,
        residenceAddress,
        type,
        fileId,
        isPrimary
      );

      dispatch({ type: utilityBillConstants.UTILITY_BILL_EDIT_SUCCESS, response });
      redirectAfterPassportOrUtilityBillEdit(uboId, directorId, contactId);
    } catch (error) {
      dispatch({ type: utilityBillConstants.UTILITY_BILL_EDIT_FAILURE, error });
    }
  };
}

export function utilityBillDelete(orgId, utilityBillId) {
  return async dispatch => {
    try {
      dispatch({ type: utilityBillConstants.UTILITY_BILL_DELETE_LOADING });

      await utilityBillService.deleteUtilityBill(orgId, utilityBillId);

      dispatch({ type: utilityBillConstants.UTILITY_BILL_DELETE_SUCCESS, utilityBillId });
    } catch (error) {
      dispatch({ type: utilityBillConstants.UTILITY_BILL_DELETE_FAILURE, error });
    }
  };
}

export function reloadUtilityBillError() {
  return async dispatch => {
    dispatch({ type: utilityBillConstants.UTILITY_BILL_ERROR_RELOAD });
  };
}
