import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteItemFulfilled, editItemFulfilled, generateSlice, SLICE_CODE_FOR } from '../helpers';
import { deleteMarketplaceDeal, editMarketplaceDeal } from './userDealsSlice';
import api from 'components/api';
import { CustomError } from 'views/helpers';

export const publishDeal = createAsyncThunk(
  'unapprovedDeals/publish',
  async ({ orgId, dealId }, { rejectWithValue }) => {
    try {
      const response = await api.put(`/org/${orgId}/marketplace/publish/${dealId}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(new CustomError(error));
    }
  }
);

export const { unapprovedDealsSlice, loadUnapprovedDeals } = generateSlice(
  { entityName: 'UnapprovedDeal', baseUrl: '/marketplace/deals/approve' },
  [SLICE_CODE_FOR.LOAD]
)({
  extraReducers: {
    // EDIT DEAL
    [editMarketplaceDeal.fulfilled]: editItemFulfilled,
    // DELETE DEAL
    [deleteMarketplaceDeal.fulfilled]: deleteItemFulfilled,
    // PUBLISH DEAL
    [publishDeal.fulfilled]: deleteItemFulfilled
  }
});

export default unapprovedDealsSlice.reducer;
