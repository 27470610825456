export const secondaryDealConstants = {
  SECONDARY_DEAL_CREATE_LOADING: 'SECONDARY_DEAL_CREATE_LOADING',
  SECONDARY_DEAL_CREATE_SUCCESS: 'SECONDARY_DEAL_CREATE_SUCCESS',
  SECONDARY_DEAL_CREATE_FAILURE: 'SECONDARY_DEAL_CREATE_FAILURE',
  SECONDARY_DEAL_ERROR_RELOAD: 'SECONDARY_DEAL_ERROR_RELOAD',

  SECONDARY_DEAL_LIST_LOADING: 'SECONDARY_DEAL_LIST_LOADING',
  SECONDARY_DEAL_LIST_SUCCESS: 'SECONDARY_DEAL_LIST_SUCCESS',
  SECONDARY_DEAL_LIST_FAILURE: 'SECONDARY_DEAL_LIST_FAILURE',

  SECONDARY_DEAL_EDIT_LOADING: 'SECONDARY_DEAL_EDIT_LOADING',
  SECONDARY_DEAL_EDIT_SUCCESS: 'SECONDARY_DEAL_EDIT_SUCCESS',
  SECONDARY_DEAL_EDIT_FAILURE: 'SECONDARY_DEAL_EDIT_FAILURE',

  SECONDARY_DEAL_DELETE_LOADING: 'SECONDARY_DEAL_DELETE_LOADING',
  SECONDARY_DEAL_DELETE_SUCCESS: 'SECONDARY_DEAL_DELETE_SUCCESS',
  SECONDARY_DEAL_DELETE_FAILURE: 'SECONDARY_DEAL_DELETE_FAILURE'
};
