export const escrowConstants = {
  ESCROW_CREATE_LOADING: 'ESCROW_CREATE_LOADING',
  ESCROW_CREATE_SUCCESS: 'ESCROW_CREATE_SUCCESS',
  ESCROW_CREATE_FAILURE: 'ESCROW_CREATE_FAILURE',
  ESCROW_ERROR_RELOAD: 'ESCROW_ERROR_RELOAD',

  ESCROW_LIST_LOADING: 'ESCROW_LIST_LOADING',
  ESCROW_LIST_SUCCESS: 'ESCROW_LIST_SUCCESS',
  ESCROW_LIST_FAILURE: 'ESCROW_LIST_FAILURE',

  ESCROW_EDIT_LOADING: 'ESCROW_EDIT_LOADING',
  ESCROW_EDIT_SUCCESS: 'ESCROW_EDIT_SUCCESS',
  ESCROW_EDIT_FAILURE: 'ESCROW_EDIT_FAILURE',

  ESCROW_DELETE_LOADING: 'ESCROW_DELETE_LOADING',
  ESCROW_DELETE_SUCCESS: 'ESCROW_DELETE_SUCCESS',
  ESCROW_DELETE_FAILURE: 'ESCROW_DELETE_FAILURE'
};
