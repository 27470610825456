import { List } from 'immutable';
import { appConstants } from '../constants';

export function apps(state = {}, action) {
  switch (action.type) {
    case appConstants.APP_LIST_LOADING:
      return {
        ...state,
        items: null,
        listLoading: true
      };
    case appConstants.APP_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case appConstants.APP_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: null,
        listError: action.error
      };

    default:
      return state;
  }
}
