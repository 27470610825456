import { dateValidator, nullValidator, numberValidator } from '../../validators/common';
import { createEntityValidator, generateSlice, SLICE_CODE_FOR } from '../helpers';
import { generateCommonCodeForAccountingEntity } from './common';
import api from 'components/api';

const distributionValidation = createEntityValidator({
  amount: [nullValidator(), numberValidator()],
  date: [nullValidator(), dateValidator()],
  name: [nullValidator()],
  investorId: [nullValidator()],
  fundId: [nullValidator()]
});

export const generateNoticeReports = async ({
  orgId,
  fundId,
  fileId,
  templateId,
  reportDate,
  distributionDate,
  distributionNumber,
  distributionCurrency
}) => {
  const res = await api.post(`/org/${orgId}/download_notice_reports_docx`, {
    fundId,
    fileId,
    templateId,
    reportDate,
    distributionDate,
    distributionNumber,
    distributionCurrency
  });

  return res.data;
};

export const {
  refreshDistributions,
  syncDistributionsWithXero,
  syncDistributionsWithQuickbooks,
  exportDistributions,
  importDistributions,
  generatedExtraReducers
} = generateCommonCodeForAccountingEntity('Distribution');

export const {
  distributionsSlice,
  loadDistributions,
  createDistribution,
  editDistribution,
  deleteDistribution,
  reloadDistributionsError
} = generateSlice({ entityName: 'Distribution', entityValidation: distributionValidation }, [
  SLICE_CODE_FOR.LOAD,
  SLICE_CODE_FOR.CREATE,
  SLICE_CODE_FOR.EDIT,
  SLICE_CODE_FOR.DELETE,
  SLICE_CODE_FOR.RELOAD_ERROR
])({
  extraReducers: generatedExtraReducers
});

export default distributionsSlice.reducer;
