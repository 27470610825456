export const fundDirectorConstants = {
  FUND_DIRECTOR_CREATE_LOADING: 'FUND_DIRECTOR_CREATE_LOADING',
  FUND_DIRECTOR_CREATE_SUCCESS: 'FUND_DIRECTOR_CREATE_SUCCESS',
  FUND_DIRECTOR_CREATE_FAILURE: 'FUND_DIRECTOR_CREATE_FAILURE',
  FUND_DIRECTOR_ERROR_RELOAD: 'FUND_DIRECTOR_ERROR_RELOAD',

  FUND_DIRECTOR_LIST_LOADING: 'FUND_DIRECTOR_LIST_LOADING',
  FUND_DIRECTOR_LIST_SUCCESS: 'FUND_DIRECTOR_LIST_SUCCESS',
  FUND_DIRECTOR_LIST_FAILURE: 'FUND_DIRECTOR_LIST_FAILURE',

  FUND_DIRECTOR_EDIT_LOADING: 'FUND_DIRECTOR_EDIT_LOADING',
  FUND_DIRECTOR_EDIT_SUCCESS: 'FUND_DIRECTOR_EDIT_SUCCESS',
  FUND_DIRECTOR_EDIT_FAILURE: 'FUND_DIRECTOR_EDIT_FAILURE',

  FUND_DIRECTOR_DELETE_LOADING: 'FUND_DIRECTOR_DELETE_LOADING',
  FUND_DIRECTOR_DELETE_SUCCESS: 'FUND_DIRECTOR_DELETE_SUCCESS',
  FUND_DIRECTOR_DELETE_FAILURE: 'FUND_DIRECTOR_DELETE_FAILURE'
};
