import { call, put, throttle } from 'redux-saga/effects';
import {
  signInvestorOnboardingItem,
  listInvestorOnboardingItems
} from 'services/investor_onboarding_item.service';

function* signItemCall({ payload: { orgId, itemId, signersIds } }) {
  try {
    yield put({ type: 'SIGN_ITEM_LOADING_TRUE' });
    yield call(signInvestorOnboardingItem, orgId, itemId, signersIds);
    yield call(listInvestorOnboardingItems, orgId);
    yield put({ type: 'SIGN_ITEM_LOADING_FALSE' });
  } catch (e) {
    yield put({ type: 'SAGA_SIGN_ITEM_LOADING_ERROR', message: e.message });
  }
}

export function* watchSign() {
  yield throttle(3000, 'SIGN_ITEM', signItemCall);
}
