import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getDefaultReducersForEntityItemsLoad } from '../helpers';
import { combineReducers } from 'redux';
import { itemsInitialState } from '../constants';
import api from 'components/api';
import { CustomError } from 'views/helpers';

const filtersOptionsInitialState = {
  filtersOptions: null,
  filtersOptionsLoading: false
};

const filtersOptionsFulfilled = (state, action) => {
  const filtersOptions = {};
  Object.keys(action.payload).forEach(key => {
    filtersOptions[key] = action.payload[key].filter(option => option);
  });
  state.filtersOptions = filtersOptions;
  state.filtersOptionsLoading = false;
};
const filtersOptionsRejected = state => {
  state.filtersOptions = null;
  state.filtersOptionsLoading = false;
};

export const loadUserSessionsHistory = createAsyncThunk(
  'userSessionsHistory/load',
  async ({ pagination, filters }, { rejectWithValue }) => {
    try {
      const response = await api.post('/profile/history', filters, pagination);

      return response.data;
    } catch (error) {
      return rejectWithValue(new CustomError(error));
    }
  }
);

export const loadUserSessionsHistoryFiltersOptions = createAsyncThunk(
  'userSessionsHistory/loadFiltersOptions',
  async (orgId, { rejectWithValue }) => {
    try {
      const response = await api.get('/profile/history/filter/options');

      return response.data;
    } catch (error) {
      return rejectWithValue(new CustomError(error));
    }
  }
);

export const userSessionsHistorySlice = createSlice({
  name: 'userSessionsHistory',
  initialState: {
    ...itemsInitialState,
    ...filtersOptionsInitialState
  },
  reducers: {},
  extraReducers: {
    // LOAD
    ...getDefaultReducersForEntityItemsLoad(loadUserSessionsHistory),
    // LOAD filters options
    [loadUserSessionsHistoryFiltersOptions.fulfilled]: filtersOptionsFulfilled,
    [loadUserSessionsHistoryFiltersOptions.rejected]: filtersOptionsRejected
  }
});

export const loadOrgSessionsHistory = createAsyncThunk(
  'orgSessionsHistory/load',
  async ({ orgId, pagination, filters }, { rejectWithValue }) => {
    try {
      const response = await api.post(`/org/${orgId}/history`, filters, pagination);

      return response.data;
    } catch (error) {
      return rejectWithValue(new CustomError(error));
    }
  }
);

export const loadOrgSessionsHistoryFiltersOptions = createAsyncThunk(
  'orgSessionsHistory/loadFiltersOptions',
  async ({ orgId }, { rejectWithValue }) => {
    try {
      const response = await api.get(`/org/${orgId}/history/filter/options`);

      return response.data;
    } catch (error) {
      return rejectWithValue(new CustomError(error));
    }
  }
);

export const orgSessionsHistorySlice = createSlice({
  name: 'orgSessionsHistory',
  initialState: {
    ...itemsInitialState,
    ...filtersOptionsInitialState
  },
  reducers: {},
  extraReducers: {
    // LOAD items
    ...getDefaultReducersForEntityItemsLoad(loadOrgSessionsHistory),
    // LOAD filters options
    [loadOrgSessionsHistoryFiltersOptions.fulfilled]: filtersOptionsFulfilled,
    [loadOrgSessionsHistoryFiltersOptions.rejected]: filtersOptionsRejected
  }
});

export default combineReducers({
  userSessionsHistory: userSessionsHistorySlice.reducer,
  orgSessionsHistory: orgSessionsHistorySlice.reducer
});
