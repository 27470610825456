import React, { useEffect, useState } from 'react';
import { Col, Row, TabPane } from 'reactstrap';
import CustomTabsNav from 'views/Admin/Common/CustomTabsNav';
import CustomNavItem from 'views/Admin/Common/CustomNavItem';
import CustomTabContent from 'views/Admin/Common/CustomTabContent';
import { useTranslation } from 'react-i18next';
import Checkbox from 'views/Common/Inputs/Checkbox';
import { TableComponent } from 'views/OnboardingItem/KYCPopup/TableComponent';
import { getFullName } from 'views/Admin/SettingsEmailNotifications/Recipients/common';

export const USER_INVESTOR_TYPES = {
  UBO: 'UBO',
  DIRECTOR: 'Director',
  CIRCULATION_CONTACT: 'CirculationContact'
};

const TABS = {
  UBOS: 'ubos',
  DIRECTORS: 'directors',
  CIRCULATION_CONTACTS: 'circulation_contacts'
};

const UserInvestorsSection = ({
  currentInvestorId,
  selectedUserInvestors,
  setSelectedUserInvestors,
  ubos,
  directors,
  circulationContacts,
  recipients,
  isDisplayingDirectorsTab
}) => {
  const [activeTab, setActiveTab] = useState(TABS.UBOS);

  useEffect(() => {
    if (!isDisplayingDirectorsTab && activeTab === TABS.DIRECTORS) {
      setActiveTab(TABS.UBOS);
    }
  }, [isDisplayingDirectorsTab]);

  return (
    <div className="animated fadeIn relative-position">
      <Row>
        <Col xs="12" md="12" className="mb-4">
          <CustomTabsNav activeTab={activeTab} toggle={setActiveTab}>
            <CustomNavItem tab={TABS.UBOS} label="KYCTabs.UBOs" />
            {isDisplayingDirectorsTab ? (
              <CustomNavItem tab={TABS.DIRECTORS} label="KYCTabs.Directors" />
            ) : null}
            <CustomNavItem tab={TABS.CIRCULATION_CONTACTS} label="KYCTabs.CirculationContacts" />
          </CustomTabsNav>
          <CustomTabContent activeTab={activeTab}>
            <TabPane tabId={TABS.UBOS}>
              <UserInvestorsTable
                currentInvestorId={currentInvestorId}
                selectedUserInvestors={selectedUserInvestors}
                setSelectedUserInvestors={setSelectedUserInvestors}
                userInvestors={ubos}
                recipients={recipients}
                userInvestorType={USER_INVESTOR_TYPES.UBO}
              />
            </TabPane>

            {isDisplayingDirectorsTab ? (
              <TabPane tabId={TABS.DIRECTORS}>
                <UserInvestorsTable
                  currentInvestorId={currentInvestorId}
                  selectedUserInvestors={selectedUserInvestors}
                  setSelectedUserInvestors={setSelectedUserInvestors}
                  userInvestors={directors}
                  recipients={recipients}
                  userInvestorType={USER_INVESTOR_TYPES.DIRECTOR}
                />
              </TabPane>
            ) : null}

            <TabPane tabId={TABS.CIRCULATION_CONTACTS}>
              <UserInvestorsTable
                currentInvestorId={currentInvestorId}
                selectedUserInvestors={selectedUserInvestors}
                setSelectedUserInvestors={setSelectedUserInvestors}
                userInvestors={circulationContacts}
                recipients={recipients}
                userInvestorType={USER_INVESTOR_TYPES.CIRCULATION_CONTACT}
              />
            </TabPane>
          </CustomTabContent>
        </Col>
      </Row>
    </div>
  );
};

const UserInvestorsTable = ({
  currentInvestorId,
  selectedUserInvestors,
  setSelectedUserInvestors,
  userInvestors,
  recipients,
  userInvestorType
}) => {
  const [userInvestorsOfCurrentInvestor, setUserInvestorsOfCurrentInvestor] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (currentInvestorId && userInvestors) {
      setUserInvestorsOfCurrentInvestor(
        userInvestors.filter(userInvestor => userInvestor.investorId === currentInvestorId)
      );
    }
  }, [currentInvestorId, userInvestors]);

  const changeUseAdditionalEmailProperty = (userInvestorId, newValue) => {
    setSelectedUserInvestors(prevState =>
      prevState.map(selectedUserInvestor => {
        if (selectedUserInvestor.id === userInvestorId) {
          return { ...selectedUserInvestor, useAdditionalEmail: newValue };
        }
        return selectedUserInvestor;
      })
    );
  };

  const cols = [
    {
      key: 'selector',
      CellComponent: userInvestor => {
        const alreadyCreatedNotificationRecipient = recipients.find(
          r => r.userInvestor.id === userInvestor.id
        );

        return (
          <Checkbox
            value={
              alreadyCreatedNotificationRecipient ||
              selectedUserInvestors.find(({ id }) => id === userInvestor.id)
            }
            handleChange={event => {
              if (event.target.checked) {
                setSelectedUserInvestors(prevState => [
                  ...prevState,
                  {
                    ...userInvestor,
                    name: getFullName(userInvestor),
                    useAdditionalEmail: false,
                    userInvestorType
                  }
                ]);
              } else {
                setSelectedUserInvestors(prevState =>
                  prevState.filter(({ id }) => id !== userInvestor.id)
                );
              }
            }}
            disabled={alreadyCreatedNotificationRecipient}
          />
        );
      }
    },
    {
      key: 'name',
      title: 'UbosTable.Name',
      CellComponent: ({ title, firstName, lastName }) => `${title || ''} ${firstName} ${lastName}`
    },
    {
      key: 'email',
      title: 'UbosTable.Email'
    },
    {
      key: 'useAdditionalEmail',
      title: 'EmailNotifications.UseAdditionalEmail',
      CellComponent: userInvestor => {
        const selectedUserInvestor = selectedUserInvestors.find(({ id }) => id === userInvestor.id);
        return userInvestor.additionalEmail ? (
          <Checkbox
            value={selectedUserInvestor && selectedUserInvestor.useAdditionalEmail}
            disabled={!selectedUserInvestor}
            handleChange={event => {
              if (event.target.checked) {
                changeUseAdditionalEmailProperty(userInvestor.id, true);
              } else {
                changeUseAdditionalEmailProperty(userInvestor.id, false);
              }
            }}
          />
        ) : null;
      }
    },
    {
      key: 'additionalEmail',
      title: 'AdditionalEmail'
    }
  ];

  return <TableComponent cols={cols} items={userInvestorsOfCurrentInvestor} t={t} />;
};

export default UserInvestorsSection;
