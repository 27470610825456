import { billingConstants } from '../constants';

const DEFAULT_STATE = {
  card: null,
  cardLoading: false,
  cardError: null,
  createCardLoading: false,
  createCardError: null,
  deleteCardLoading: false,
  deleteCardError: null,

  editCardLoading: false,
  editCardError: null,

  clientToken: null,
  clientTokenLoading: false,
  clientTokenError: null,

  details: null,
  detailsLoading: false,
  detailsError: null,

  editDetailsLoading: null,
  editDetailsError: null,

  nonce: null,
  nonceLoading: false,
  nonceError: null,

  buySubscriptionLoading: false,
  buySubscriptionError: null,

  invoices: null,
  invoicesLoading: false,
  invoicesError: null,

  subscriptionSetError: null
};

export const billing = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case billingConstants.BILLING_CARD_LOADING: {
      return {
        ...state,
        cardLoading: true
      };
    }

    case billingConstants.BILLING_CARD_SUCCESS: {
      return {
        ...state,
        card: action.response,
        cardLoading: false
      };
    }

    case billingConstants.BILLING_CARD_FAILURE: {
      return {
        ...state,
        card: null,
        createCardLoading: false,
        cardError: action.e
      };
    }

    case billingConstants.BILLING_CARD_ERROR_RELOAD: {
      return {
        ...state,
        cardError: null
      };
    }

    case billingConstants.BILLING_CARD_CREATE_LOADING: {
      return {
        ...state,
        createCardLoading: true
      };
    }

    case billingConstants.BILLING_CARD_CREATE_SUCCESS: {
      return {
        ...state,
        card: [action.payload],
        createCardLoading: false
      };
    }

    case billingConstants.BILLING_CARD_CREATE_FAILURE: {
      return {
        ...state,
        card: null,
        createCardLoading: false,
        createCardError: action.error
      };
    }

    case billingConstants.BILLING_CARD_CREATE_ERROR_RELOAD: {
      return {
        ...state,
        createCardError: null
      };
    }

    case billingConstants.BILLING_CARD_DELETE_LOADING: {
      return {
        ...state,
        deleteCardLoading: true
      };
    }

    case billingConstants.BILLING_CARD_DELETE_SUCCESS: {
      return {
        ...state,
        card: null,
        deleteCardLoading: false
      };
    }

    case billingConstants.BILLING_CARD_DELETE_FAILURE: {
      return {
        ...state,
        deleteCardLoading: false,
        deleteCardError: action.e
      };
    }

    case billingConstants.BILLING_CARD_EDIT_LOADING: {
      return {
        ...state,
        editCardLoading: true
      };
    }

    case billingConstants.BILLING_CARD_EDIT_SUCCESS: {
      return {
        ...state,
        card: action.response,
        editCardLoading: false
      };
    }

    case billingConstants.BILLING_CARD_EDIT_FAILURE: {
      return {
        ...state,
        editCardLoading: false,
        editCardError: action.e
      };
    }

    case billingConstants.SUBSCRIPTION_INFO_LOADING: {
      return {
        ...state,
        subscriptionListLoading: state.subscriptionListLoading || true
      };
    }

    case billingConstants.SUBSCRIPTION_INFO_SUCCESS: {
      return {
        ...state,
        subscriptionListLoading: false,
        subscriptionList: action.response.data
      };
    }

    case billingConstants.SUBSCRIPTION_INFO_FAILURE: {
      return {
        ...state,
        subscriptionListLoading: false,
        subscriptionListError: action.error
      };
    }

    case billingConstants.CHARGEBEE_SUBSCRIPTION_SET_CLEAR: {
      return {
        ...state,
        subscriptionList: null
      };
    }

    case billingConstants.CHARGEBEE_SUBSCRIPTION_SET_LOADING: {
      return {
        ...state,
        subscriptionListLoading: action.payload
      };
    }

    case billingConstants.CHARGEBEE_SUBSCRIPTION_SET_SUCCESS: {
      return {
        ...state,
        subscriptionList: [...state.subscriptionList, action.payload],
        subscriptionListLoading: false
      };
    }

    case billingConstants.CHARGEBEE_SUBSCRIPTION_SET_FAILURE: {
      return {
        ...state,
        subscriptionListLoading: false,
        subscriptionSetError: action.error
      };
    }
    case billingConstants.BRAINTREE_CLIENT_TOKEN_LOADING: {
      return {
        ...state,
        clientTokenLoading: true
      };
    }

    case billingConstants.BRAINTREE_CLIENT_TOKEN_SUCCESS: {
      return {
        ...state,
        clientTokenLoading: false,
        clientToken: action.clientToken
      };
    }

    case billingConstants.BRAINTREE_CLIENT_TOKEN_FAILURE: {
      return {
        ...state,
        clientTokenLoading: false,
        clientTokenError: action.e,
        clientToken: null
      };
    }

    case billingConstants.BILLING_DETAILS_LOADING: {
      return {
        ...state,
        detailsLoading: true
      };
    }

    case billingConstants.BILLING_DETAILS_SUCCESS: {
      return {
        ...state,
        details: action.response,
        detailsLoading: false,
        detailsError: null
      };
    }

    case billingConstants.BILLING_DETAILS_FAILURE: {
      return {
        ...state,
        details: null,
        detailsLoading: false,
        detailsError: action.e
      };
    }

    case billingConstants.BILLING_DETAILS_EDIT_LOADING: {
      return {
        ...state,
        editDetailsLoading: true
      };
    }

    case billingConstants.BILLING_DETAILS_EDIT_SUCCESS: {
      return {
        ...state,
        details: action.response,
        editDetailsLoading: false,
        editDetailsError: null
      };
    }

    case billingConstants.BILLING_DETAILS_EDIT_FAILURE: {
      return {
        ...state,
        editDetailsLoading: false,
        editDetailsError: action.e
      };
    }

    case billingConstants.BILLING_DETAILS_EDIT_ERROR_RELOAD: {
      return {
        ...state,
        editDetailsError: null
      };
    }

    case billingConstants.BRAINTREE_NONCE_LOADING: {
      return {
        ...state,
        nonceLoading: true
      };
    }

    case billingConstants.BRAINTREE_NONCE_SUCCESS: {
      return {
        ...state,
        nonce: action.nonce,
        nonceLoading: false,
        nonceError: null
      };
    }

    case billingConstants.BRAINTREE_NONCE_FAILURE: {
      return {
        ...state,
        nonce: null,
        nonceLoading: false,
        nonceError: action.e
      };
    }

    case billingConstants.BUY_SUBSCRIPTION_LOADING: {
      return {
        ...state,
        buySubscriptionLoading: true
      };
    }

    case billingConstants.BUY_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        buySubscriptionLoading: false
      };
    }

    case billingConstants.BUY_SUBSCRIPTION_FAILURE: {
      return {
        ...state,
        buySubscriptionLoading: false,
        buySubscriptionError: action.e
      };
    }

    case billingConstants.INVOICES_LIST_LOADING: {
      return {
        ...state,
        invoicesLoading: true
      };
    }

    case billingConstants.INVOICES_LIST_SUCCESS: {
      return {
        ...state,
        invoices: action.response,
        invoicesLoading: false,
        invoicesError: null
      };
    }

    case billingConstants.INVOICES_LIST_FAILURE: {
      return {
        ...state,
        invoices: null,
        invoicesLoading: false,
        invoicesError: action.e
      };
    }

    case billingConstants.INVOICE_PDF_LOADING: {
      return {
        ...state,
        invoicePDFLoading: action.payload
      };
    }

    case billingConstants.INVOICE_PDF_SUCCESS: {
      return {
        ...state,
        invoicePDFDownloadLink: action.payload,
        invoicePDFLoading: false
      };
    }
    case billingConstants.INVOICE_PDF_FAILURE: {
      return {
        ...state,
        invoicePDFLoading: false,
        invoicePDFError: action.e
      };
    }
    default:
      return state;
  }
};
