import { List } from 'immutable';
import { expenseConstants } from '../constants';

export function expenses(state = {}, action) {
  switch (action.type) {
    case expenseConstants.EXPENSE_LIST_LOADING:
      return {
        ...state,
        listLoading: true
      };
    case expenseConstants.EXPENSE_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case expenseConstants.EXPENSE_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: List([]),
        listError: action.error
      };

    case expenseConstants.EXPENSE_LIST_CLEAR:
      return {
        ...state,
        items: null
      };

    case expenseConstants.EXPENSE_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case expenseConstants.EXPENSE_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      return {
        ...state,
        items: items.push(action.response),
        createLoading: false,
        createError: null
      };
    }
    case expenseConstants.EXPENSE_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };

    case expenseConstants.EXPENSE_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case expenseConstants.EXPENSE_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const expenseIndex = state.items.findIndex(val => val.id == action.response.id);

      if (expenseIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(expenseIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case expenseConstants.EXPENSE_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case expenseConstants.EXPENSE_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case expenseConstants.EXPENSE_DELETE_SUCCESS: {
      const { expenseId } = action;

      if (!state.items) {
        return state;
      }

      const expenseIndex = state.items.findIndex(val => val.id == expenseId);

      if (expenseIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(expenseIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case expenseConstants.EXPENSE_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };
    case expenseConstants.EXPENSE_ERROR_RELOAD:
      return {
        ...state,
        createError: null,
        editError: null
      };

    default:
      return state;
  }
}
