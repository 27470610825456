export const investorGroupConstants = {
  INVESTOR_GROUP_CREATE_LOADING: 'INVESTOR_GROUP_CREATE_LOADING',
  INVESTOR_GROUP_CREATE_SUCCESS: 'INVESTOR_GROUP_CREATE_SUCCESS',
  INVESTOR_GROUP_CREATE_FAILURE: 'INVESTOR_GROUP_CREATE_FAILURE',
  INVESTOR_GROUP_ERROR_RELOAD: 'INVESTOR_GROUP_ERROR_RELOAD',

  INVESTOR_GROUP_LIST_LOADING: 'INVESTOR_GROUP_LIST_LOADING',
  INVESTOR_GROUP_LIST_SUCCESS: 'INVESTOR_GROUP_LIST_SUCCESS',
  INVESTOR_GROUP_LIST_FAILURE: 'INVESTOR_GROUP_LIST_FAILURE',
  INVESTOR_GROUP_LIST_CLEAR: 'INVESTOR_GROUP_LIST_CLEAR',

  INVESTOR_GROUP_EDIT_LOADING: 'INVESTOR_GROUP_EDIT_LOADING',
  INVESTOR_GROUP_EDIT_SUCCESS: 'INVESTOR_GROUP_EDIT_SUCCESS',
  INVESTOR_GROUP_EDIT_FAILURE: 'INVESTOR_GROUP_EDIT_FAILURE',

  INVESTOR_GROUP_DELETE_LOADING: 'INVESTOR_GROUP_DELETE_LOADING',
  INVESTOR_GROUP_DELETE_SUCCESS: 'INVESTOR_GROUP_DELETE_SUCCESS',
  INVESTOR_GROUP_DELETE_FAILURE: 'INVESTOR_GROUP_DELETE_FAILURE'
};
