import { List } from 'immutable';
import { investorOnboardingItemConstants } from '../constants';

export function investor_onboarding_items(state = {}, action) {
  switch (action.type) {
    case investorOnboardingItemConstants.INVESTOR_ONBOARDING_ITEM_LIST_LOADING:
      return {
        ...state,
        items: null,
        listLoading: true
      };
    case investorOnboardingItemConstants.VOID_ENVELOPE_LOADING_TRUE:
      return {
        ...state,
        voidEnvelopeLoading: true
      };
    case investorOnboardingItemConstants.VOID_ENVELOPE_LOADING_FALSE:
      return {
        ...state,
        voidEnvelopeLoading: false
      };
    case investorOnboardingItemConstants.SIGN_ITEM_LOADING_TRUE:
      return {
        ...state,
        signProcessLoading: true
      };
    case investorOnboardingItemConstants.SIGN_ITEM_LOADING_FALSE:
      return {
        ...state,
        signProcessLoading: false
      };
    case investorOnboardingItemConstants.INVESTOR_ONBOARDING_ITEM_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case investorOnboardingItemConstants.INVESTOR_ONBOARDING_ITEM_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: null,
        listError: action.error
      };

    case investorOnboardingItemConstants.INVESTOR_ONBOARDING_ITEM_SIGN_LOADING:
      return {
        ...state,
        items: null,
        signLoading: true
      };
    case investorOnboardingItemConstants.INVESTOR_ONBOARDING_ITEM_SIGN_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        signLoading: false
      };
    case investorOnboardingItemConstants.INVESTOR_ONBOARDING_ITEM_SIGN_FAILURE:
      return {
        ...state,
        signLoading: false,
        items: null,
        signError: action.error
      };

    default:
      return state;
  }
}
