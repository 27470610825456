export const onboardingItemConstants = {
  ONBOARDING_ITEM_CREATE_LOADING: 'ONBOARDING_ITEM_CREATE_LOADING',
  ONBOARDING_ITEM_CREATE_SUCCESS: 'ONBOARDING_ITEM_CREATE_SUCCESS',
  ONBOARDING_ITEM_CREATE_FAILURE: 'ONBOARDING_ITEM_CREATE_FAILURE',
  ONBOARDING_ITEM_ERROR_RELOAD: 'ONBOARDING_ITEM_ERROR_RELOAD',

  ONBOARDING_ITEM_LIST_LOADING: 'ONBOARDING_ITEM_LIST_LOADING',
  ONBOARDING_ITEM_LIST_SUCCESS: 'ONBOARDING_ITEM_LIST_SUCCESS',
  ONBOARDING_ITEM_LIST_FAILURE: 'ONBOARDING_ITEM_LIST_FAILURE',

  ONBOARDING_ITEM_EDIT_LOADING: 'ONBOARDING_ITEM_EDIT_LOADING',
  ONBOARDING_ITEM_EDIT_SUCCESS: 'ONBOARDING_ITEM_EDIT_SUCCESS',
  ONBOARDING_ITEM_EDIT_FAILURE: 'ONBOARDING_ITEM_EDIT_FAILURE',

  ONBOARDING_ITEM_DELETE_LOADING: 'ONBOARDING_ITEM_DELETE_LOADING',
  ONBOARDING_ITEM_DELETE_SUCCESS: 'ONBOARDING_ITEM_DELETE_SUCCESS',
  ONBOARDING_ITEM_DELETE_FAILURE: 'ONBOARDING_ITEM_DELETE_FAILURE'
};
