import { List } from 'immutable';
import { escrowConstants } from 'constants/escrow.constants';

const INITIAL_STATE = {
  items: null,
  listLoading: false,
  listError: null,
  createLoading: false,
  createError: null,
  editLoading: false,
  editError: null,
  deleteLoading: false,
  deleteError: null
};

export function escrows(state = INITIAL_STATE, action) {
  switch (action.type) {
    case escrowConstants.ESCROW_LIST_LOADING:
      return {
        ...state,
        listLoading: true
      };
    case escrowConstants.ESCROW_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case escrowConstants.ESCROW_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: [],
        listError: action.error
      };
    case escrowConstants.ESCROW_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case escrowConstants.ESCROW_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      return {
        ...state,
        items: items.push(action.response),
        createLoading: false,
        createError: null
      };
    }
    case escrowConstants.ESCROW_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };
    case escrowConstants.ESCROW_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case escrowConstants.ESCROW_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const escrowIndex = state.items.findIndex(val => val.id === action.response.id);

      if (escrowIndex === -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(escrowIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case escrowConstants.ESCROW_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case escrowConstants.ESCROW_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case escrowConstants.ESCROW_DELETE_SUCCESS: {
      const { escrowId } = action;

      if (!state.items) {
        return state;
      }

      const escrowIndex = state.items.findIndex(val => val.id === escrowId);

      if (escrowIndex === -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(escrowIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case escrowConstants.ESCROW_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };

    case escrowConstants.ESCROW_ERROR_RELOAD:
      return {
        ...state,
        createError: null,
        editError: null
      };
    default:
      return state;
  }
}
