import { List } from 'immutable';
import { investorDocTypeConstants } from '../constants/investor_doc_type.constants';

const initialState = {
  items: null,
  listLoading: false,
  listError: null,
  createLoading: false,
  createError: null,
  editLoading: false,
  editError: null,
  deleteLoading: false,
  deleteError: null
};

export function investor_doc_types(state = initialState, action) {
  switch (action.type) {
    case investorDocTypeConstants.INVESTOR_DOC_TYPE_LIST_LOADING:
      return {
        ...state,
        items: null,
        listLoading: true
      };

    case investorDocTypeConstants.INVESTOR_DOC_TYPE_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };

    case investorDocTypeConstants.INVESTOR_DOC_TYPE_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: null,
        listError: action.error
      };

    case investorDocTypeConstants.INVESTOR_DOC_TYPE_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };

    case investorDocTypeConstants.INVESTOR_DOC_TYPE_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      return {
        ...state,
        items: items.push(action.response),
        createLoading: false,
        createError: null
      };
    }

    case investorDocTypeConstants.INVESTOR_DOC_TYPE_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };

    case investorDocTypeConstants.INVESTOR_DOC_TYPE_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };

    case investorDocTypeConstants.INVESTOR_DOC_TYPE_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const investorDocTypeIndex = state.items.findIndex(val => val.id == action.response.id);

      if (investorDocTypeIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(investorDocTypeIndex, action.response),
        editLoading: false,
        editError: null
      };
    }

    case investorDocTypeConstants.INVESTOR_DOC_TYPE_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case investorDocTypeConstants.INVESTOR_DOC_TYPE_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };

    case investorDocTypeConstants.INVESTOR_DOC_TYPE_DELETE_SUCCESS: {
      const { investorDocTypeId } = action;

      if (!state.items) {
        return state;
      }

      const investorDocTypeIndex = state.items.findIndex(val => val.id == investorDocTypeId);

      if (investorDocTypeIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(investorDocTypeIndex),
        deleteLoading: false,
        deleteError: null
      };
    }

    case investorDocTypeConstants.INVESTOR_DOC_TYPE_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };

    case investorDocTypeConstants.INVESTOR_DOC_TYPE_ERROR_RELOAD:
      return {
        ...state,
        createError: null,
        editError: null
      };

    default:
      return state;
  }
}
