export const comparableCompanyConstants = {
  COMPARABLE_COMPANY_CREATE_LOADING: 'COMPARABLE_COMPANY_CREATE_LOADING',
  COMPARABLE_COMPANY_CREATE_SUCCESS: 'COMPARABLE_COMPANY_CREATE_SUCCESS',
  COMPARABLE_COMPANY_CREATE_FAILURE: 'COMPARABLE_COMPANY_CREATE_FAILURE',
  COMPARABLE_COMPANY_ERROR_RELOAD: 'COMPARABLE_COMPANY_ERROR_RELOAD',

  COMPARABLE_COMPANY_LIST_LOADING: 'COMPARABLE_COMPANY_LIST_LOADING',
  COMPARABLE_COMPANY_LIST_SUCCESS: 'COMPARABLE_COMPANY_LIST_SUCCESS',
  COMPARABLE_COMPANY_LIST_FAILURE: 'COMPARABLE_COMPANY_LIST_FAILURE',

  COMPARABLE_COMPANY_EDIT_LOADING: 'COMPARABLE_COMPANY_EDIT_LOADING',
  COMPARABLE_COMPANY_EDIT_SUCCESS: 'COMPARABLE_COMPANY_EDIT_SUCCESS',
  COMPARABLE_COMPANY_EDIT_FAILURE: 'COMPARABLE_COMPANY_EDIT_FAILURE',

  COMPARABLE_COMPANY_DELETE_LOADING: 'COMPARABLE_COMPANY_DELETE_LOADING',
  COMPARABLE_COMPANY_DELETE_SUCCESS: 'COMPARABLE_COMPANY_DELETE_SUCCESS',
  COMPARABLE_COMPANY_DELETE_FAILURE: 'COMPARABLE_COMPANY_DELETE_FAILURE'
};
