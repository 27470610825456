import { List } from 'immutable';
import { capTableConstants } from '../constants/cap_table.constants';

export function cap_tables(state = {}, action) {
  switch (action.type) {
    case capTableConstants.CAP_TABLE_LIST_LOADING:
      return {
        ...state,
        listLoading: true
      };
    case capTableConstants.CAP_TABLE_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case capTableConstants.CAP_TABLE_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: [],
        listError: action.error
      };
    case capTableConstants.CAP_TABLE_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case capTableConstants.CAP_TABLE_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      return {
        ...state,
        items: items.push(action.response),
        createLoading: false,
        createError: null
      };
    }
    case capTableConstants.CAP_TABLE_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };

    case capTableConstants.CAP_TABLE_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case capTableConstants.CAP_TABLE_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const capTableIndex = state.items.findIndex(val => val.id === action.response.id);

      if (capTableIndex === -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(capTableIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case capTableConstants.CAP_TABLE_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case capTableConstants.CAP_TABLE_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case capTableConstants.CAP_TABLE_DELETE_SUCCESS: {
      const { capTableId } = action;

      if (!state.items) {
        return state;
      }

      const capTableIndex = state.items.findIndex(val => val.id === capTableId);

      if (capTableIndex === -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(capTableIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case capTableConstants.CAP_TABLE_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };
    case capTableConstants.CAP_TABLE_ERROR_RELOAD:
      return {
        ...state,
        createError: null,
        editError: null,
        deleteError: null
      };

    case capTableConstants.CAP_TABLE_ATTACH_FILE_LOADING: {
      const index = state.items.findIndex(val => val.id === action.capTableId);

      return {
        ...state,
        items: state.items.set(index, { ...state.items.get(index), loading: true })
      };
    }

    case capTableConstants.CAP_TABLE_ATTACH_FILE_SUCCESS: {
      const index = state.items.findIndex(val => val.id === action.capTableId);

      return {
        ...state,
        items: state.items.set(index, {
          ...state.items.get(index),
          fileId: action.fileId,
          downloadLink: action.downloadLink,
          loading: false
        })
      };
    }

    case capTableConstants.CAP_TABLE_ATTACH_FILE_FAILURE: {
      const index = state.items.findIndex(val => val.id === action.capTableId);

      return {
        ...state,
        items: state.items.set(index, {
          ...state.items.get(index),
          loading: false,
          uploadError: action.err
        })
      };
    }

    case capTableConstants.CAP_TABLE_CLONE_LOADING:
      return {
        ...state,
        cloneLoading: true
      };

    case capTableConstants.CAP_TABLE_CLONE_SUCCESS:
      return {
        ...state,
        items: state.items.push(action.response),
        cloneLoading: false,
        cloneError: null
      };

    case capTableConstants.CAP_TABLE_CLONE_FAILURE:
      return {
        ...state,
        cloneLoading: false,
        cloneError: action.error
      };

    default:
      return state;
  }
}
