import * as Sentry from '@sentry/browser';
import { userConstants } from '../constants';
import logger from 'views/logger';

export function sentry(state = {}, action) {
  switch (action.type) {
    case userConstants.LOGIN_SUCCESS: {
      if (action.response.user.public_profile) {
        const username = `${action.response.user.public_profile.firstName} ${action.response.user.public_profile.lastName}`;

        logger.log('attaching sentry context');
        Sentry.configureScope(function(scope) {
          scope.setUser({
            email: action.response.user.email,
            id: action.response.user.public_profile.id,
            username
          });
          scope.setTag('user', username);
          scope.setTag('email', action.response.user.email);
        });

        return {
          email: action.response.user.email,
          userId: action.response.user.public_profile.id,
          username
        };
      }
      return state;
    }
    default:
      return state;
  }
}
