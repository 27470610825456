import { crunchbaseConstants } from '../constants';

export function crunchbase(state = {}, action) {
  switch (action.type) {
    case crunchbaseConstants.CRUNCHBASE_LIST_ORGANIZATIONS_LOADING:
      return {
        ...state,
        loading: true
      };
    case crunchbaseConstants.CRUNCHBASE_LIST_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case crunchbaseConstants.CRUNCHBASE_LIST_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return state;
  }
}
