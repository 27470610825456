import { List } from 'immutable';
import { boardMemberConstants } from '../constants';

export function board_members(state = {}, action) {
  switch (action.type) {
    case boardMemberConstants.BOARD_MEMBER_LIST_LOADING:
      return {
        ...state,
        items: null,
        listLoading: true
      };
    case boardMemberConstants.BOARD_MEMBER_LIST_SUCCESS:
      return {
        ...state,
        items: List(action.response),
        listLoading: false
      };
    case boardMemberConstants.BOARD_MEMBER_LIST_FAILURE:
      return {
        ...state,
        listLoading: false,
        items: null,
        listError: action.error
      };
    case boardMemberConstants.BOARD_MEMBER_CREATE_LOADING:
      return {
        ...state,
        createLoading: true
      };
    case boardMemberConstants.BOARD_MEMBER_CREATE_SUCCESS: {
      const items = state.items ? state.items : List();
      return {
        ...state,
        items: items.push(action.response),
        createLoading: false,
        createError: null
      };
    }
    case boardMemberConstants.BOARD_MEMBER_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.error
      };

    case boardMemberConstants.BOARD_MEMBER_EDIT_LOADING:
      return {
        ...state,
        editLoading: true
      };
    case boardMemberConstants.BOARD_MEMBER_EDIT_SUCCESS: {
      if (!state.items) {
        return state;
      }

      const boardMemberIndex = state.items.findIndex(val => val.id == action.response.id);

      if (boardMemberIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.set(boardMemberIndex, action.response),
        editLoading: false,
        editError: null
      };
    }
    case boardMemberConstants.BOARD_MEMBER_EDIT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error
      };

    case boardMemberConstants.BOARD_MEMBER_DELETE_LOADING:
      return {
        ...state,
        deleteLoading: true
      };
    case boardMemberConstants.BOARD_MEMBER_DELETE_SUCCESS: {
      const { boardMemberId } = action;

      if (!state.items) {
        return state;
      }

      const boardMemberIndex = state.items.findIndex(val => val.id == boardMemberId);

      if (boardMemberIndex == -1) {
        return state;
      }

      return {
        ...state,
        items: state.items.delete(boardMemberIndex),
        deleteLoading: false,
        deleteError: null
      };
    }
    case boardMemberConstants.BOARD_MEMBER_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      };
    case boardMemberConstants.BOARD_MEMBER_ERROR_RELOAD:
      return {
        ...state,
        createError: null,
        editError: null
      };

    default:
      return state;
  }
}
