import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { eventId: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.configureScope(scope => {
      scope.setUser({ email: this.props.authentication.user.email });
      scope.setTag('language', localStorage.getItem('lang'));
      if (this.props.orgs && this.props.orgs.items && this.props.org) {
        const currentOrg = this.props.orgs.items.find(o => o.id === this.props.org.id);
        scope.setTag('organization', currentOrg.name);
      }
      if (this.props.authentication.user && this.props.authentication.user.public_profile) {
        const username = `${this.props.authentication.user.public_profile.firstName} ${this.props.authentication.user.public_profile.lastName}`;
        scope.setTag('nameSurname', username);
      }
    });

    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      if (this.props.authentication.user && this.props.authentication.user.public_profile) {
        scope.setExtra('userId', this.props.authentication.user.public_profile.id);
      }
      if (this.props.org) {
        scope.setExtra('orgId', this.props.org.id);
      }
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) {
      // render fallback UI
      return (
        <button
          type="button"
          onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}
        >
          Report feedback
        </button>
      );
    }

    // when there's not an error, render children untouched
    return this.props.children;
  }
}

export default ErrorBoundary;
