import { toast } from 'react-toastify';
import { Trans as T } from 'react-i18next';
import React from 'react';
import { Link } from 'react-router-dom';
import i18n from '../i18n';

const toastHelper = {
  success: (text, autoClose = 5000) => {
    return toast.info(
      <h6>
        <T i18nKey={text} />
      </h6>,
      { type: toast.TYPE.SUCCESS, autoClose }
    );
  },
  error: (text = 'UnknownError', autoClose = 5000) => {
    return toast.info(
      <h6>
        <T i18nKey={text} />
      </h6>,
      { type: toast.TYPE.ERROR, autoClose }
    );
  },
  limitError: text => {
    return toast.info(
      <h6>
        <T i18nKey={text} />
        <Link to="/billing" style={{ color: 'black' }}>
          <T i18nKey="ChooseAnotherPlan" />
        </Link>
      </h6>,
      { type: toast.TYPE.ERROR, autoClose: 13000 }
    );
  },
  systemError: () => {
    return toast.info(
      <h6>
        {`${i18n.t('SystemError')} `}
        <a href="mailto:team@theairr.com" style={{ color: 'black' }}>
          team@theairr.com
        </a>
      </h6>,
      { type: toast.TYPE.ERROR, autoClose: false }
    );
  },
  dataSynchronization: {
    loading: () => {
      return toast.info(
        <h6>
          <T i18nKey="Notifications.DataSynchronization.Loading" />
          ...
        </h6>,
        { autoClose: false }
      );
    },
    success: toastId => {
      toast.update(toastId, {
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
        render: (
          <h6>
            <T i18nKey="Notifications.DataSynchronization.Success" />
          </h6>
        )
      });
    },
    failure: toastId => {
      toast.update(toastId, {
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        render: (
          <h6>
            <T i18nKey="Notifications.DataSynchronization.Failure" />
          </h6>
        )
      });
    }
  }
};

export default toastHelper;
