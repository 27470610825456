export const klipConstants = {
  KLIP_CREATE_LOADING: 'KLIP_CREATE_LOADING',
  KLIP_CREATE_SUCCESS: 'KLIP_CREATE_SUCCESS',
  KLIP_CREATE_FAILURE: 'KLIP_CREATE_FAILURE',
  KLIP_ERROR_RELOAD: 'KLIP_ERROR_RELOAD',

  KLIP_LIST_LOADING: 'KLIP_LIST_LOADING',
  KLIP_LIST_SUCCESS: 'KLIP_LIST_SUCCESS',
  KLIP_LIST_FAILURE: 'KLIP_LIST_FAILURE',

  KLIP_EDIT_LOADING: 'KLIP_EDIT_LOADING',
  KLIP_EDIT_SUCCESS: 'KLIP_EDIT_SUCCESS',
  KLIP_EDIT_FAILURE: 'KLIP_EDIT_FAILURE',

  KLIP_DELETE_LOADING: 'KLIP_DELETE_LOADING',
  KLIP_DELETE_SUCCESS: 'KLIP_DELETE_SUCCESS',
  KLIP_DELETE_FAILURE: 'KLIP_DELETE_FAILURE'
};
