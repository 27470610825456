export const leadFilterGroupConstants = {
  LEAD_FILTER_GROUP_CREATE_LOADING: 'LEAD_FILTER_GROUP_CREATE_LOADING',
  LEAD_FILTER_GROUP_CREATE_FAILURE: 'LEAD_FILTER_GROUP_CREATE_FAILURE',
  LEAD_FILTER_GROUP_CREATE_SUCCESS: 'LEAD_FILTER_GROUP_CREATE_SUCCESS',
  LEAD_FILTER_GROUP_ERROR_RELOAD: 'LEAD_FILTER_GROUP_ERROR_RELOAD',

  LEAD_FILTER_GROUP_LIST_LOADING: 'LEAD_FILTER_GROUP_LIST_LOADING',
  LEAD_FILTER_GROUP_LIST_SUCCESS: 'LEAD_FILTER_GROUP_LIST_SUCCESS',
  LEAD_FILTER_GROUP_LIST_FAILURE: 'LEAD_FILTER_GROUP_LIST_FAILURE',

  LEAD_FILTER_GROUP_EDIT_LOADING: 'LEAD_FILTER_GROUP_EDIT_LOADING',
  LEAD_FILTER_GROUP_EDIT_SUCCESS: 'LEAD_FILTER_GROUP_EDIT_SUCCESS',
  LEAD_FILTER_GROUP_EDIT_FAILURE: 'LEAD_FILTER_GROUP_EDIT_FAILURE',

  LEAD_FILTER_GROUP_DELETE_LOADING: 'LEAD_FILTER_GROUP_DELETE_LOADING',
  LEAD_FILTER_GROUP_DELETE_SUCCESS: 'LEAD_FILTER_GROUP_DELETE_SUCCESS',
  LEAD_FILTER_GROUP_DELETE_FAILURE: 'LEAD_FILTER_GROUP_DELETE_FAILURE'
};
