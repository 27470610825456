import axios from 'axios';
import { nullValidator, validate, emailValidator, checkboxValidator } from '../validators/common';
import { axiosOptions } from './constants';
import api from 'components/api';

function handleAuthResponse(res) {
  const { user } = res.data;
  localStorage.setItem('user', JSON.stringify(user));
  localStorage.setItem('token', res.data.token);

  axios.defaults.headers.common.Authorization = `Bearer ${res.data.token}`;

  return {
    user,
    token: res.data.token
  };
}

export async function forgotRequest(email) {
  const validateObject = {
    email
  };

  const validateConfig = {
    email: [nullValidator()]
  };

  await validate(validateObject, validateConfig);

  const request = {
    email
  };

  const res = await axios.post(`${process.env.API_URL}/auth/forgot_request`, request, axiosOptions);

  return res.data;
}

export async function changePassword(currentPassword, newPassword) {
  const res = await api.put('/profile/password', {
    password: currentPassword,
    newPassword
  });

  return res.data;
}

export async function inviteValidate(token) {
  const request = null;

  const res = await axios.post(
    `${process.env.API_URL}/auth/invite_validate?token=${token}`,
    request,
    axiosOptions
  );

  const ret = {
    orgId: res.data.orgId,
    profile: res.data.profile,
    message: res.data.message
  };

  if (res.data.auth) {
    const stdRes = {
      data: {
        user: res.data.profile,
        token: res.data.auth.token
      }
    };

    ret.auth = handleAuthResponse(stdRes);
  }

  return ret;
}

export async function inviteSignup(token, user) {
  const validateObject = {
    email: user.email,
    password: user.password,
    firstName: user.firstName,
    lastName: user.lastName
  };

  const validateConfig = {
    email: [nullValidator()],
    password: [nullValidator()],
    firstName: [nullValidator()],
    lastName: [nullValidator()]
  };

  await validate(validateObject, validateConfig);

  const request = {
    email: user.email,
    password: user.password,
    firstName: user.firstName,
    lastName: user.lastName
  };

  const res = await axios.post(
    `${process.env.API_URL}/auth/invite_signup?token=${token}`,
    request,
    axiosOptions
  );

  return handleAuthResponse(res);
}

export async function register(user) {
  const validateObject = {
    email: user.email,
    password: user.password,
    firstName: user.firstName,
    lastName: user.lastName,
    isAgree: user.isAgree
  };

  const validateConfig = {
    email: [nullValidator(), emailValidator()],
    password: [nullValidator()],
    firstName: [nullValidator()],
    lastName: [nullValidator()],
    isAgree: [checkboxValidator()]
  };

  await validate(validateObject, validateConfig);

  const request = {
    email: user.email,
    password: user.password,
    firstName: user.firstName,
    lastName: user.lastName,
    language: user.language
  };

  const res = await axios.post(`${process.env.API_URL}/auth/signup`, request, axiosOptions);

  return handleAuthResponse(res);
}

export async function loginForgot(token, password) {
  const validateObject = {
    password
  };

  const validateConfig = {
    password: [nullValidator()]
  };

  await validate(validateObject, validateConfig);

  const request = {
    forgotToken: token,
    newPassword: password
  };

  const res = await axios.post(`${process.env.API_URL}/auth/forgot`, request, axiosOptions);

  return handleAuthResponse(res);
}

export async function validateToken(token) {
  const headers = {
    ...axiosOptions.headers,
    Authorization: `Bearer ${token}`
  };

  const res = await axios.get(`${process.env.API_URL}/profile`, null, { headers });

  const stdRes = {
    data: {
      user: res.data,
      token
    }
  };

  return handleAuthResponse(stdRes);
}

export async function loginByCreds(username, password) {
  const request = {
    username,
    password
  };

  const res = await axios.post(`${process.env.API_URL}/auth/login_password`, request, axiosOptions);

  return handleAuthResponse(res);
}

export async function logout(withoutRequestForAllLogout) {
  if (!withoutRequestForAllLogout) {
    await axios.delete(`${process.env.API_URL}/logout/all`, axiosOptions);
  }

  // remove user from local storage to log user out
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('orgId');

  delete axios.defaults.headers.common.Authorization;
}

export async function editProfile(
  firstName,
  lastName,
  phone,
  linkedin,
  fileId,
  downloadLink,
  language
) {
  const request = {
    first_name: firstName,
    last_name: lastName,
    phone,
    linkedin,
    fileId,
    downloadLink,
    language
  };

  const res = await axios.put(`${process.env.API_URL}/profile`, request, axiosOptions);
  return res.data;
}
