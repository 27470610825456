import React from 'react';
import { Trans as T } from 'react-i18next';
import { TableFooter, TCell } from '.';

export default ({ colSpan = 1 }) => (
  <TableFooter>
    <tr>
      <TCell colSpan={colSpan}>
        <div className="alert alert-warning">
          <T i18nKey="Loading" />
          ...
        </div>
      </TCell>
    </tr>
  </TableFooter>
);
