export const founderConstants = {
  FOUNDER_CREATE_LOADING: 'FOUNDER_CREATE_LOADING',
  FOUNDER_CREATE_SUCCESS: 'FOUNDER_CREATE_SUCCESS',
  FOUNDER_CREATE_FAILURE: 'FOUNDER_CREATE_FAILURE',
  FOUNDER_ERROR_RELOAD: 'FOUNDER_ERROR_RELOAD',

  FOUNDER_LIST_LOADING: 'FOUNDER_LIST_LOADING',
  FOUNDER_LIST_SUCCESS: 'FOUNDER_LIST_SUCCESS',
  FOUNDER_LIST_FAILURE: 'FOUNDER_LIST_FAILURE',

  FOUNDER_EDIT_LOADING: 'FOUNDER_EDIT_LOADING',
  FOUNDER_EDIT_SUCCESS: 'FOUNDER_EDIT_SUCCESS',
  FOUNDER_EDIT_FAILURE: 'FOUNDER_EDIT_FAILURE',

  FOUNDER_DELETE_LOADING: 'FOUNDER_DELETE_LOADING',
  FOUNDER_DELETE_SUCCESS: 'FOUNDER_DELETE_SUCCESS',
  FOUNDER_DELETE_FAILURE: 'FOUNDER_DELETE_FAILURE'
};
